export default function Snackbar(theme) {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          paddding: "30px",
        },
      },
    },
  };
}
