import { useState } from "react";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
import useBrand from "hooks/useBrand";

import "./style.css";
// ----------------------------------------------------------------------

// const ListSubheaderStyle = styled((props) => (
//   <ListSubheader disableSticky disableGutters {...props} />
// ))(({ theme }) => ({
//   ...theme.typography.overline,
//   marginTop: theme.spacing(3),
//   marginBottom: theme.spacing(2),
//   paddingLeft: theme.spacing(5),
//   color: theme.palette.text.primary,
// }));

const ListItemStyleSetup = styled(ListItemButton)(({ theme, title }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: "rgb(255, 255, 255, 0.6)",

  // "&:before": {
  //   top: 0,
  //   right: 0,
  //   width: 3,
  //   bottom: 0,
  //   content: "''",
  //   display: "none",
  //   position: "absolute",
  //   borderTopLeftRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   backgroundColor: theme.palette.primary.main,
  // },
}));

// const ListItemStyle = styled(ListItemButton)(({ theme, title }) => ({
//   ...theme.typography.body2,
//   height: 48,
//   position: "relative",
//   textTransform: "capitalize",
//   paddingLeft: theme.spacing(5),
//   paddingRight: theme.spacing(2.5),
//   color: "rgb(255, 255, 255, 1)",

//   // "&:before": {
//   //   top: 0,
//   //   right: 0,
//   //   width: 3,
//   //   bottom: 0,
//   //   content: "''",
//   //   display: "none",
//   //   position: "absolute",
//   //   borderTopLeftRadius: 4,
//   //   borderBottomLeftRadius: 4,
//   //   backgroundColor: theme.palette.primary.main,
//   // },
// }));

// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 32,
//   height: 32,
//   padding: 7,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// });

// ----------------------------------------------------------------------

function NavItem({ item, isShow, sx }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path
    ? !!matchPath({ path, end: false }, pathname)
    : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const brand = useBrand();

  const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: brand?.colors?.sidebarText,
    // "&:before": {
    //   top: 0,
    //   right: 0,
    //   width: 3,
    //   bottom: 0,
    //   content: "''",
    //   display: "none",
    //   position: "absolute",
    //   borderTopLeftRadius: 4,
    //   borderBottomLeftRadius: 4,
    //   backgroundColor: theme.palette.primary.main,
    // },
  }));

  const ListItemIconStyle = styled(ListItemIcon)({
    width: 32,
    height: 32,
    padding: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });
  const activeRootStyle = {
    // color: "primary.main",
    fontWeight: "fontWeightMedium",
    // opacity: "60%",
    // bgcolor: alpha(
    //   theme.palette.primary.main,
    //   theme.palette.action.selectedOpacity
    // ),
    // "&:before": { display: "block" },
  };

  const activeIconRootStyle = {
    // padding: 1,
    color: theme.palette.background.paper,
    bgcolor: theme.palette.primary.main,
    width: 32,
    height: 32,
    padding: 0.7,
    borderRadius: 50,
    // fontWeight: "fontWeightMedium",
    // bgcolor: alpha(
    //   theme.palette.primary.main
    // theme.palette.action.selectedOpacity
    // ),
    // "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle
            sx={{
              ...(isActiveRoot && activeIconRootStyle),
            }}
          >
            {icon && icon}
          </ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = path
                  ? !!matchPath({ path, end: false }, pathname)
                  : false;
                // const x = "Setup";

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (theme) =>
                            theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return title === "Setup" ? (
    <ListItemStyleSetup
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle
        sx={{
          ...(isActiveRoot && activeIconRootStyle),
        }}
      >
        {icon && icon}
      </ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyleSetup>
  ) : (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle
        sx={{
          ...(isActiveRoot && activeIconRootStyle),
        }}
      >
        {icon && icon}
      </ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const brand = useBrand();

  const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
  ))(({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: brand?.colors?.sidebarText,
  }));

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {/* {" "}
            className="list_style" */}
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                isShow={isShow}
                sx={{ bgColor: "red" }}
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
