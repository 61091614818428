import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/integrations/roof-connect";

const slice = createSlice({
  name: "requests",
  initialState: {
    page: 1,
    per_page: 20,
    total_pages: 0,
    total_records: 0,
    list: [],
    all: [],
    selectedRequest: null,
    stats: null,
    chart: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 1;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.list = [];
      state.all = [];
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    updateState(state, action) {
      state = { ...state, ...action.payload };
    },
    getRequests(state, action) {
      state.isLoading = false;
      state.page = action.payload?.page;
      state.stats = {
        total_conversation_closed: action.payload?.total_conversation_closed,
        total_conversation_pending: action.payload?.total_conversation_pending,
        total_conversation: action.payload?.total_conversation,
      };
      state.total_records = action.payload?.total_records;
      let list = action.payload?.data ?? [];
      state.list = list;
    },
    requestLoaded(state, action) {
      state.isLoading = false;
      state.selectedRequest = action.payload;
    },
    requestClosed(state, action) {
      state.isLoading = false;
      state.selectedRequest = action.payload;
      state.success = { message: "Successfully Closed" };
    },
    requestsStats(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    },
    pageChangeBackward(state, action) {
      if (state.page > 0) state.page = state.page - 1;
    },
    pageChangeForward(state, action) {
      state.page = state.page + 1;
    },
    incrementClosedCount(state, action) {
      state.stats.total_conversation_closed += 1;
      state.stats.total_conversation_pending -= 1;
    },
    decrementClosedCount(state, action) {
      state.stats.total_conversation_closed -= 1;
      state.stats.total_conversation_pending += 1;
    },
  },
});

export const {
  resetAll,
  requestStarted,
  requestedFailed,
  stateLoaded,
  updateState,
  getRequests,
  requestsStats,
  pageChangeBackward,
  pageChangeForward,
  requestLoaded,
  requestClosed,
  incrementClosedCount,
  decrementClosedCount,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const getRequestsList = (status = "all") => {
  return apiCallBegan({
    url: url + `/inspections?status=${status}`,
    onStart: requestStarted.type,
    onSuccess: getRequests.type,
    onError: requestedFailed.type,
  });
};

export const refreshRequestsList = (status = "all") => {
  return apiCallBegan({
    url: url + `/refresh-inspections-cache?status=${status}`,
    onStart: requestStarted.type,
    onSuccess: getRequests.type,
    onError: requestedFailed.type,
  });
};

export const getRequestsStats = (status = "all") =>
  apiCallBegan({
    url: url + `/stats`,
    onStart: requestStarted.type,
    onSuccess: requestsStats.type,
    onError: requestedFailed.type,
  });

export const getRequest = (id) =>
  apiCallBegan({
    url: url + `/Inspections/${id}`,
    onStart: requestStarted.type,
    onSuccess: requestLoaded.type,
    onError: requestedFailed.type,
  });

export const closeRequest = (id) =>
  apiCallBegan({
    url: url + `/Inspections/${id}/close`,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: requestClosed.type,
    onError: requestedFailed.type,
  });

export const replyToRequest = (data) =>
  apiCallBegan({
    url: url + `/Inspections/${data.id}/reply`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: requestLoaded.type,
    onError: requestedFailed.type,
  });
