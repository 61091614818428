import React from "react";
import styled, { css } from "styled-components/macro";
import { Grid } from "@mui/material";
// import { rgba } from "polished";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
//import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import useBrand from "hooks/useBrand";

const useStyles = makeStyles({
  accountButton: {
    WebkitTransform: "perspective(1px) translateZ(0)",
    transform: "perspective(1px) translateZ(0)",
    WebkitTransitionDuration: "0.3s",
    transitionDuration: "0.3s",
    WebkitTransitionProperty: "transform",
    transitionProperty: "transform",
  },
  accountButtonPrimary: {
    marginTop: "10px!important",
    "&:hover": {
      transform: "scale(0.97)",
      WebkitTransform: "scale(0.97)",
      background: "#1BB27C !important",
      boxShadow: "0px 5px 20px #104A3857 !important",
    },
  },
  accountButtonSecondary: {
    "&:hover": {
      transform: "scale(0.97)",
      WebkitTransform: "scale(0.97)",
      background: "#31157B !important",
      boxShadow: "0px 5px 20px #1A104A4A !important",
    },
  },
});

const Card = styled(MuiCard)`
  position: relative;
  color: #fff;
  boxshadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)";
  background: #003896;
  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: #000;
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const IllustrationImage = styled.img`
  height: 120px;
`;

const ContactRoofer = ({
  title,
  tooltipDescription,
  chip,
  timeDuration = "",
  illustration,
  countUpProps,
}) => {
  const classes = useStyles();
  const brand = useBrand();
  let navigate = useNavigate();

  return (
    <Card illustration={illustration}>
      <CardContent sx={{ px: { xs: 0, sm: 1.5 }, py: { xs: 1, sm: 1.9 } }}>
        <Grid
          container
          spacing={2}
          style={{
            padding: "5px 20px",
            overflow: "hidden",
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box display="flex">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: { md: "1.3rem", sm: "1.15rem", xs: "1rem" },
                  mt: 1,
                  lineHeight: "25px",
                }}
                fontWeight="fontWeightMedium"
              >
                {title}
              </Typography>
            </Box>

            <Typography variant="h3" mb={1}>
              <Box fontWeight="fontWeightBold" fontSize={40}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  startIcon={<AddIcon />}
                  sx={{
                    display: { xs: "inline-flex", sm: "none" },

                    boxShadow: "0px 5px 20px #104A3857",
                  }}
                  className={`${classes.accountButton} ${classes.accountButtonPrimary}`}
                  onClick={() => navigate("/new-request")}
                >
                  REQUEST NEW INSPECTION
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<AddIcon />}
                  sx={{
                    display: { xs: "none", sm: "inline-flex" },
                    minWidth: 196,
                    boxShadow: "0px 5px 20px #104A3857",
                  }}
                  className={`${classes.accountButton} ${classes.accountButtonPrimary}`}
                  onClick={() => navigate("/new-request")}
                >
                  REQUEST NEW INSPECTION
                </Button>
              </Box>
            </Typography>

            <Box display="flex" justifyContent={"flex-start"}>
              <Typography variant="body2">{timeDuration}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "none", lg: "block" }, mt: 1 }}
            sm={0}
            md={3}
            lg={3}
            key="Illustration"
          >
            <Box>
              <IllustrationImage
                src={brand?.images?.logo_white_center}
                // src="https://www.weblakeland.com/wp-content/uploads/2022/06/Colored-Center.svg"
                alt="Illustration"
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      {illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  );
};

export default ContactRoofer;
