/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useMatch } from "react-router-dom";
// material
import { Button, Box, Card, CircularProgress, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//
import { CopyToClipboard } from "react-copy-to-clipboard";
// redux
import { useDispatch, useSelector } from "redux/store";
import { resetAllState, validateCompany } from "redux/slices/company";
// hooks
import useAuth from "hooks/useAuth";
import { PATH_DASHBOARD } from "routes/paths";

export default function CodeCopy({ handlePreviousStep, skipForNow }) {
  let match = useMatch("/profile/installation");

  let { currentCompany } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let { success, error, isLoading } = useSelector((state) => state.company);

  let [isSuccess, setIsSuccess] = useState(false);
  let [isError, setIsError] = useState(false);

  useEffect(() => {
    if (success) {
      setIsSuccess(true);
      enqueueSnackbar(success?.message ?? "Successfully Validated", {
        variant: "success",
      });
      dispatch(resetAllState());
      if (!currentCompany.validation_date) {
        setTimeout(() => {
          navigate(PATH_DASHBOARD.profile.billing.paymentmethod);
        }, 3000);
      }
    }
  }, [success]);
  useEffect(() => {
    if (error) {
      setIsError(true);
      dispatch(resetAllState());
    }
  }, [error]);

  let handleCheckValidation = () => {
    setIsError(false);
    dispatch(validateCompany());
  };

  let handleCopy = () => {
    enqueueSnackbar("Code Copied", {
      variant: "success",
    });
  };

  return (
    <React.Fragment>
      <Box textAlign="center" sx={{ mb: 4 }}>
        <Typography variant="h6">Oh, a nerd too!</Typography>
        <Typography variant="h6">
          Use the code below and place it on your website.
        </Typography>
        <Typography variant="caption">
          Place the following code in the header of your website. Above the{" "}
          <code>{"<body>"}</code> tag.
        </Typography>
      </Box>
      <Box textAlign={"start"}>
        <Typography variant="subtitle2">Your Code Snippet</Typography>
        <Card
          sx={{
            bgcolor: "#f7f7f9",
            p: 5,
            borderRadius: 0,
            borderWidth: 8,
          }}
        >
          <Typography sx={{ mb: 5, overflowWrap: "break-word" }}>
            {currentCompany?.script}
          </Typography>

          <CopyToClipboard text={currentCompany?.script}>
            <Button
              sx={{ textTransform: "none" }}
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopy}
            >
              Copy code
            </Button>
          </CopyToClipboard>
        </Card>
      </Box>

      <Button
        fullWidth
        size="large"
        variant="contained"
        color={isError ? "error" : "primary"}
        sx={{ mt: 5 }}
        onClick={handleCheckValidation}
        disabled={isLoading}
        startIcon={
          isLoading ? (
            <CircularProgress size={18} color="primary" />
          ) : isError ? (
            <SentimentVeryDissatisfiedIcon />
          ) : isSuccess ? (
            <CheckCircleIcon />
          ) : (
            <ThumbUpOffAltIcon />
          )
        }
      >
        {isError
          ? "Validate again"
          : isSuccess
          ? "Successful Installation"
          : "Validate Connection"}
      </Button>

      {isError && (
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Typography variant="subtitle2" color="error">
            Oh no! We are unable to validate our code <br /> on your website.
            Please try again.
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <Button
          variant="text"
          sx={{ color: "black" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => handlePreviousStep(0)}
        >
          previous
        </Button>
        {!match && (
          <Button
            variant="text"
            sx={{ color: "black" }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => skipForNow()}
          >
            skip for now
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
