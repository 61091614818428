import { isString } from "lodash";
// material
import { Box, Typography, Link } from "@mui/material";
//
import { MBreadcrumbs } from "./@material-extend";
// import "./style.css";
import { useMediaQuery } from "@mui/material";

// ----------------------------------------------------------------------

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  headingIcon,
  countLabelSettings,
  moreLink = "" || [],
  sx,
  ...other
}) {
  const isActive = useMediaQuery("(max-width: 767px)");
  const isActive2 = useMediaQuery("(min-width: 768px)");
  return (
    <Box sx={{ mb: 3.5, ...sx }}>
      {isActive && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {headingIcon && (
                <div
                  style={{
                    backgroundColor: "#F5FBFF",
                    width: "40px",
                    height: "40px",
                    marginRight: "15px",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  {headingIcon}
                </div>
              )}
              <Typography
                variant="h4"
                sx={{ color: "#000000", mt: 0, textShadow: ".2px 0 #000" }}
              >
                {heading}
              </Typography>
              {countLabelSettings && (
                <div
                  style={{
                    padding: "0px 20px",
                    marginLeft: "10px",
                    backgroundColor: countLabelSettings.background || "#222",
                    color: countLabelSettings.color || "#FFF",
                    fontSize: "1.3rem",
                    textShadow: `1.3px 0 ${countLabelSettings.color}`,
                    fontweight: "bold",
                  }}
                >
                  <p>{countLabelSettings?.text}</p>
                </div>
              )}
            </Box>
            {links && <MBreadcrumbs links={links} {...other} />}
          </Box>

          {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        </Box>
      )}
      {isActive2 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {headingIcon && (
                <div
                  style={{
                    backgroundColor: "#F5FBFF",
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  {headingIcon}
                </div>
              )}
              <Typography
                variant="h4"
                sx={{ color: "#000000", textShadow: ".2px 0 #000" }}
              >
                {heading}
              </Typography>
              {countLabelSettings && (
                <div
                  style={{
                    padding: "0px 20px",
                    marginLeft: "15px",
                    backgroundColor: countLabelSettings.background || "#222",
                    color: countLabelSettings.color || "#FFF",
                    fontSize: "1.35rem",
                    textShadow: `1.4px 0 ${countLabelSettings.color}`,
                    fontweight: "bold",
                  }}
                >
                  <p>{countLabelSettings?.text}</p>
                </div>
              )}
            </Box>
            {links && <MBreadcrumbs links={links} {...other} />}
          </Box>

          {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        </Box>
      )}

      <Box sx={{ marginTop: "-4px" }}>
        {isString(moreLink) ? (
          <Link
            href={"https://" + moreLink}
            target="_blank"
            variant="body2"
            sx={{ color: "#6A74C9" }}
          >
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href, index) => (
            <Link
              noWrap
              key={index}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
