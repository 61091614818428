import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import personOutline from "@iconify/icons-eva/person-outline";
import peopleOutline from "@iconify/icons-eva/people-outline";
import creditCardOutline from "@iconify/icons-eva/credit-card-outline";
import swapFill from "@iconify/icons-eva/swap-fill";
import logoutFill from "@iconify/icons-eva/log-out-fill";
import ArrowDownward from "@iconify/icons-eva/arrow-ios-downward-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, MenuItem, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
import { MIconButton } from "../../components/@material-extend";
import MyAvatar from "../../components/MyAvatar";
import MenuPopover from "../../components/MenuPopover";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "My Account",
    icon: personOutline,
    linkTo: PATH_DASHBOARD.profile.root,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout, companies, readOnly } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  if (!readOnly) {
    if (!MENU_OPTIONS.find((x) => x.label === "Users")) {
      MENU_OPTIONS.push({
        label: "Users",
        icon: peopleOutline,
        linkTo: PATH_DASHBOARD.nestedUsers,
      });
    }
  }

  if (options && !readOnly && !options.bool_billing_enabled) {
    let usersOption = MENU_OPTIONS.findIndex(
      (x) => x.linkTo === PATH_DASHBOARD.nestedUsers
    );
    if (usersOption > -1) {
      MENU_OPTIONS[usersOption] = {
        label: "Users",
        icon: peopleOutline,
        linkTo: PATH_DASHBOARD.users,
      };
    }
    let accountRoute = MENU_OPTIONS.findIndex(
      (x) => x.linkTo === PATH_DASHBOARD.profile.root
    );
    if (usersOption > -1) {
      MENU_OPTIONS[accountRoute] = {
        label: "My Account",
        icon: personOutline,
        linkTo: PATH_DASHBOARD.account,
      };
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate("/");
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  return (
    <>
      <MyAvatar
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          bgcolor: "black",
          color: "white",
          width: 34,
          height: 34,
        }}
      />

      <Typography
        variant="body2"
        fontWeight="bold"
        color="black"
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
      >
        {user?.first_name + " " + user?.last_name}
      </Typography>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 30,
          fontWeight: "bolder",
          height: 30,
          backgroundColor: "#fff",
          transition: "transform 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          "&:hover": {
            color: "#1d104f",
            bgcolor: "#EFEFEF",
          },
          color: "#000",
          ...(open && {
            color: "#1d104f",
            bgcolor: "#EFEFEF",
            transform: "rotate(180deg)",
            WebkitTransform: "rotate(180deg)",
            transition: "transform 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              fontWeight: "bolder",
              color: "#000",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <Icon icon={ArrowDownward} width={24} height={24} />
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 200,
          padding: "20px 0px",
          borderRadius: "10px",
          fontWeight: "bold",
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.first_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ my: 1 }} /> */}

        {companies?.length > 1 ? (
          <MenuItem
            // key={option.label}
            to={PATH_DASHBOARD.switchcompany}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2, fontWeight: "bold" }}
          >
            <Box
              component={Icon}
              icon={swapFill}
              sx={{
                mr: 2,
                width: 18,
                height: 18,
              }}
            />
            Switch Company
          </MenuItem>
        ) : null}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: "subtitle1",
              py: 1,
              px: 2,
              pl: 4,
              fontWeight: "bold",
            }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 18,
                height: 18,
              }}
            />
            {option.label}
          </MenuItem>
        ))}

        {!readOnly && options.bool_billing_enabled && (
          <MenuItem
            key="Billing"
            to={PATH_DASHBOARD.profile.billing.myplan}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: "subtitle1",
              py: 1,
              px: 2,
              pl: 4,
              // fontWeight: "bold",
            }}
          >
            <Box
              component={Icon}
              icon={creditCardOutline}
              sx={{
                mr: 2,
                width: 18,
                height: 18,
              }}
            />
            Billing
          </MenuItem>
        )}

        <MenuItem
          onClick={handleLogout}
          sx={{
            typography: "subtitle1",
            py: 1,
            px: 2,
            pl: 4,
            fontWeight: "bold",
          }}
        >
          <Box
            component={Icon}
            icon={logoutFill}
            sx={{
              mr: 2,
              width: 18,
              height: 18,
            }}
          />
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
