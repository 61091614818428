import React from "../../../redux/store";
import { Card, Box, CardContent, Grid, Typography } from "@mui/material";
import RequestItem from "pages/RoofConnect/components/RequestItem";
import NoDataFound from "../../../components/general/Table/NoData.js";

const TableHead = () => {
  return (
    <Card
      sx={{
        backgroundColor: "#ffffff",
        display: { xs: "none", sm: "none", md: "block" },
      }}
    >
      <CardContent
        style={{
          padding: "10px 20px 10px 35px",
        }}
      >
        <Grid container sx={{ fontWeight: "bold" }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Full Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Phone Number
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Status
            </Typography>
          </Grid>
          <Grid item justify="center">
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Manage
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const RequestsList = ({ list, handleCloseRequest }) => {
  return (
    <div>
      <TableHead />
      <Grid container spacing={0} style={{ padding: "10px" }}>
        {list?.map((listItem, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
            <RequestItem
              item={listItem}
              onView=""
              onClose={handleCloseRequest}
            />
          </Grid>
        ))}
        {list && list.length === 0 && (
          <Box sx={{ margin: "auto auto" }}>
            <NoDataFound width={200} height={150} margin={7} />
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default RequestsList;
