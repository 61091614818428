import React from "react";
import SignInComponent from "components/auth/SignIn";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Stack, Link, Container, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "routes/paths";

// layouts
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
// redux
import { useSelector } from "redux/store";
import { useMediaQuery } from "@mui/material";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
  backgroundColor: "#0A36A3",
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 580,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------

export default function SignIn() {
  const {
    brand: { images },
  } = useSelector((state) => state.brand);
  const mobileview = useMediaQuery("(max-width:425px)");
  const windowview = useMediaQuery("(min-width:426px)");
  return (
    <Box>
      {mobileview && (
        <RootStyle
          title="Login"
          descriptionKey="RCLogin"
          sx={{ backgroundColor: "#F5FBFF" }}
        >
          <Container maxWidth="sm">
            <ContentStyle sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 7,
                  pb: 3,
                }}
              >
                <img src={images.logo_white} alt="logo" height="50" />
              </Box>
              <Box
                sx={{
                  px: 1,
                  boxShadow: 15,
                  pt: 1,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                  <Box sx={{ flexGrow: 1 }} textAlign="center">
                    <Typography variant="h3" gutterBottom>
                      Log in to Your Account
                      {/* <sup>&trade;</sup> */}
                    </Typography>
                  </Box>
                </Stack>
                <SignInComponent />{" "}
                <MHidden width="smUp">
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ mt: 3, mb: 1 }}
                  >
                    Don’t have an account?&nbsp;
                    <Link
                      variant="subtitle2"
                      component={RouterLink}
                      to={PATH_AUTH.signup}
                    >
                      Get started
                    </Link>
                  </Typography>
                </MHidden>
              </Box>
              <Box sx={{ textAlign: "center", pt: 1 }}>
                <Typography variant="caption">
                  This Page is protected by reCAPTCHA and <br></br> the &nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/privacy?hl=en-GB"
                  >
                    Google Privacy Policy
                  </Link>
                  &nbsp; and&nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/terms?hl=en-GB"
                  >
                    Terms of Services
                  </Link>
                  &nbsp; apply.
                </Typography>
              </Box>
            </ContentStyle>
          </Container>
          <MHidden width="mdDown">
            <SectionStyle>
              <img
                src={images.illustration_signup}
                alt="login"
                height="100%"
                style={{ objectFit: "cover", opacity: 0.7 }}
              />
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
      {windowview && (
        <RootStyle
          title="Login"
          descriptionKey="RCLogin"
          sx={{ backgroundColor: "#F5FBFF" }}
        >
          <Container maxWidth="sm">
            <ContentStyle>
              <Box
                sx={{ display: "flex", alignItems: "center", ml: 16, pb: 8 }}
              >
                <img src={images.logo_white} alt="logo" height="70"></img>
              </Box>
              <Box
                sx={{
                  px: 2,
                  boxShadow: 15,
                  pt: 4,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }} textAlign="center">
                    <Typography variant="h3">
                      Log in to Your Account
                      {/* <sup>&trade;</sup> */}
                    </Typography>
                  </Box>
                </Stack>{" "}
                <SignInComponent />
                <Typography variant="body2" align="center" sx={{ m: 4 }}>
                  Don’t have an account?&nbsp;
                  <Link
                    variant="subtitle2"
                    color="#284FAE"
                    component={RouterLink}
                    to={PATH_AUTH.signup}
                  >
                    Get started
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ mt: 4, px: "auto", textAlign: "center" }}>
                <Typography variant="caption">
                  This page is protected by reCAPTCHA and <br></br> the&nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/privacy?hl=en-GB"
                  >
                    Google Privacy Policy
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    variant="caption"
                    target="_blank"
                    color="#284FAE"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/terms?hl=en-GB"
                  >
                    Terms of Services
                  </Link>
                  &nbsp;apply.
                </Typography>
              </Box>
            </ContentStyle>
          </Container>
          <MHidden width="mdDown">
            <SectionStyle>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  px: 5,
                }}
              >
                Speed Up Your Closings — Guaranteed.
              </Typography>
              <Box sx={{ pl: 8, pt: 3, pr: 6 }}>
                <img
                  alt="register"
                  src={images.illustration_signup}
                  height="100%"
                />
              </Box>
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
    </Box>
  );
}
