import React from "react";
import styled, { css } from "styled-components/macro";
import { rgba } from "polished";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Tooltip,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Card,
  CardActionArea,
} from "@mui/material";
import { spacing } from "@mui/system";
import CountUp from "react-countup";

// const styleCard = styled(Card)`
//   position: relative;
//   margin-bottom: ${(props) => props.theme.spacing(6)};
//   @media (max-width: 768px) {
//     margin-bottom: ${(props) => props.theme.spacing(0)};
//   }
//   boxshadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)";
//   ${(props) =>
//     props.illustration &&
//     props.theme.palette.mode !== "dark" &&
//     css`
//       background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
//       color: ${(props) => props.theme.palette.primary.main};
//     `}
// `;

const Typography = styled(MuiTypography)(spacing);

const Percentage = styled(MuiTypography)`
  color: ${(props) => props.percentagecolor};
  font-weight: bolder;
  background: ${(props) =>
    props.percentagecolor === "#17b121" ? "#E1F9E2" : "#FCE0E0"};
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: ${(props) => props.theme.spacing(2)};

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

const Stats = ({
  title,
  tooltipDescription,
  chip,
  percentagetext,
  timeDuration = "",
  illustration,
  size = "4",
  countUpProps,
  fontSize = 40,
  amountColor = "#000",
  onClick = () => {},
}) => {
  const CardContent = styled(MuiCardContent)`
    position: relative;
  `;
  return (
    <Card illustration={illustration} onClick={onClick}>
      <CardContent
        sx={{
          "&:last-child": {
            pb: { xs: 0, md: 4 },
          },
        }}
      >
        <Box display="flex">
          <Typography variant="subtitle1" fontWeight="fontWeightMedium">
            {title}{" "}
          </Typography>
          {tooltipDescription && (
            <Tooltip title={tooltipDescription ?? "info"} placement="top" arrow>
              <InfoOutlinedIcon color="action" sx={{ fontSize: "13px" }} />
            </Tooltip>
          )}
        </Box>

        <Typography variant="h3" mb={1}>
          <Box
            fontWeight="fontWeightBold"
            fontSize={fontSize}
            color={amountColor}
          >
            <CountUp duration={1} {...countUpProps} />
          </Box>
        </Typography>

        <Box display="flex" justifyContent={"flex-start"}>
          {(percentagetext || percentagetext === 0) && (
            <Percentage
              variant="caption"
              percentagecolor={percentagetext >= 0 ? "#17b121" : "#E01E1E"}
              illustration={illustration}
            >
              {percentagetext >= 0 ? (
                <ArrowUpwardIcon
                  size="small"
                  color={"#17b121"}
                  sx={{ fontSize: 12 }}
                />
              ) : (
                <ArrowDownwardIcon
                  size="small"
                  color={"#E01E1E"}
                  sx={{ fontSize: 12 }}
                />
              )}{" "}
              {Number(percentagetext)?.toFixed(2)}%
            </Percentage>
          )}
          <Typography variant="body2">{timeDuration}</Typography>
        </Box>
      </CardContent>

      {!!illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  );
};

export default Stats;
