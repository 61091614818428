import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/companies";

const slice = createSlice({
  name: "user_management",
  initialState: {
    page: 0,
    per_page: 25,
    total_pages: 0,
    total_records: 0,
    list: [],
    all: [],
    invitedUsers: [],
    stats: null,
    chart: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 0;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.list = [];
      state.all = [];
    },
    resetRequestState(state, action) {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    getUsers(state, action) {
      state.isLoading = false;
      state.list = action.payload?.users;
      state.invitedUsers = action.payload?.invited;
    },
    userDeleted(state, action) {
      let list = state.list.filter(
        (item) => item.user !== action?.payload?.user
      );
      state.list = list;
      state.success = { message: "Deleted Successfully" };
    },
    updatedUserAccess(state, action) {
      let list = state.list;
      list.forEach((item) => {
        if (item.email === action?.payload?.email) {
          item = action.payload;
        }
      });
      state.list = list;
      state.success = { message: "Updated Successfully" };
    },
    invitedUser(state, action) {
      let ivu = state.invitedUsers;
      if (!ivu) ivu = [];
      ivu.push(action.payload);
      state.invitedUsers = ivu;
      state.success = { message: "User Invited Successfully" };
    },
    canceledUserInvite(state, action) {
      state.success = action?.payload;
      state.invitedUsers = state.invitedUsers.filter(
        (item) => item.email !== action?.payload?.email
      );
    },
  },
});

export const {
  resetAll,
  resetRequestState,
  requestStarted,
  requestedFailed,
  stateLoaded,
  getUsers,
  userDeleted,
  updatedUserAccess,
  invitedUser,
  canceledUserInvite,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const resetRequest = () => {
  return { type: resetRequestState.type };
};

export const getUsersList = (data) =>
  apiCallBegan({
    url: url + `/users`,
    onStart: requestStarted.type,
    onSuccess: getUsers.type,
    onError: requestedFailed.type,
  });

export const inviteUsers = (data) =>
  apiCallBegan({
    url: url + `/user/invite`,
    method: "post",
    data,
    // onStart: requestStarted.type,
    onSuccess: invitedUser.type,
    onError: requestedFailed.type,
  });

export const updateUserAccess = (data) =>
  apiCallBegan({
    url: url + `/user/role`,
    method: "put",
    data,
    // onStart: requestStarted.type,
    onSuccess: updatedUserAccess.type,
    onError: requestedFailed.type,
  });

export const deleteUser = (data) =>
  apiCallBegan({
    url: url + `/user`,
    method: "delete",
    data,
    // onStart: requestStarted.type,
    onSuccess: userDeleted.type,
    onError: requestedFailed.type,
  });

export const cancelUserInvite = (data) =>
  apiCallBegan({
    url: url + `/user/cancel`,
    method: "post",
    data,
    // onStart: requestStarted.type,
    onSuccess: canceledUserInvite.type,
    onError: requestedFailed.type,
  });
