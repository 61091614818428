import axios from "axios";
import { setSession } from "./jwt";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      setSession(null);
      window.location.href = "/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
