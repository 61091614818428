import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LoadingScreen from "components/LoadingScreen";

// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
//
import { MIconButton } from "../@material-extend";
import { onPasswordBlur, onPasswordKeyDown } from "../../utils/commonMethods";
import { inputLabelClasses } from "@mui/material/InputLabel";
// redux
import { useSelector } from "redux/store";
import { useSearchParams, useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, superLogin } = useAuth();
  const {
    brand: { domain = "" },
  } = useSelector((state) => state.brand);

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [capsWarning, setCapsWarning] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // const setHighlighContext = (email) => {
  //   H.identify(email);
  // };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const companyID = searchParams.get("cid");
    const adminToken = searchParams.get("token");
    console.log("id", companyID, adminToken);
    if (companyID && adminToken) {
      setShowLoading(true);
      superLogin({ companyID, adminToken }).then((resp) => {
        console.log(resp);
        setShowLoading(false);
        if (resp?.success) {
          navigate("/");
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
      source: domain,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        let { success, currentCompany } = await login(values);
        if (success && !currentCompany?.status) {
          enqueueSnackbar("Your don't have an active subscription", {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }
        // setHighlighContext(values.email);
        if (!success) {
          return enqueueSnackbar("Provided email or password is incorrect", {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error("error: ", error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Box>
      {!showLoading ? (
        <Box sx={{ p: { md: 3, xs: 0 } }}>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}

                <TextField
                  fullWidth
                  autoComplete="username"
                  placeholder="Email Address"
                  type="email"
                  label="Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: "black",
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        color: "",
                      },
                    },
                  }}
                />

                <TextField
                  // sx={{
                  //   position: "relative",
                  // }}
                  fullWidth
                  placeholder="Password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  {...getFieldProps("password")}
                  onBlur={(e) => onPasswordBlur(e, setCapsWarning)}
                  onKeyDown={(e) => onPasswordKeyDown(e, setCapsWarning)}
                  error={Boolean(touched.password && errors.password)}
                  helperText={
                    (touched.password && errors.password) ||
                    (capsWarning && "Caps lock is on")
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleShowPassword}
                          position="start"
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: "black",
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        color: "",
                      },
                    },
                  }}
                />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      {...getFieldProps("remember")}
                      checked={values.remember}
                    />
                  }
                  label="Remember Me"
                />

                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  color="#284FAE"
                  to={PATH_AUTH.forgotPassword}
                >
                  Forgot Password?
                </Link>
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}
              >
                Sign In
                <ArrowForwardIcon
                  sx={{
                    fontSize: "18px",
                    ml: 1,
                  }}
                />
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Box>
      ) : (
        <Box sx={{ display: "block", marginTop: "20% !important" }}>
          <LoadingScreen />
        </Box>
      )}
    </Box>
  );
}
