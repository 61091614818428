import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
} from "@mui/material";
import MyAvatar from "../../../components/MyAvatar";
import { MESSAGE_TYPES } from "../../../constants/Realtor.js";
import { useSelector } from "redux/store";

import moment from "moment";

function Conversation({ request }) {
  const { brand } = useSelector((state) => state.brand);
  const { images } = brand ?? {};

  const getMessages = () => {
    if (request?._embedded?.threads && request?._embedded?.threads.length > 0) {
      let msgs = request._embedded.threads.filter(
        (t) => MESSAGE_TYPES.indexOf(t.type) > -1
      );
      let formattedMsgs = msgs.map((msg) => {
        let toReturn = {
          from:
            msg?.source?.via === "customer"
              ? `${msg?.customer?.first} ${msg?.customer?.last}`
              : `${msg?.createdBy?.first} ${msg?.createdBy?.last}`,
          createdAt: msg.createdAt,
          body: msg.body,
          photoUrl: msg?.source?.via === "customer" ? null : images?.icon_svg,
          avatarLetter:
            msg?.source?.via === "customer" ? msg?.customer?.first[0] : null,
        };
        return toReturn;
      });
      return formattedMsgs;
    }
    return [];
  };

  const formattedDate = (d) => {
    return moment(d).format("MMM DD, YYYY");
  };

  return (
    <List sx={{ width: "100%" }}>
      {getMessages() &&
        getMessages()?.map((msg, index) => (
          <ListItem
            sx={{ mt: 1, display: "block", padding: "5px 0px !important" }}
            key={index}
          >
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar sx={{ display: { xs: "none", sm: "flex" } }}>
                <MyAvatar
                  customFirstLetter={msg.avatarLetter}
                  alt="avatar"
                  src={msg.photoUrl}
                  sx={{
                    width: "38px",

                    height: "38px",
                    pr: 0,
                    mr: -1,
                    mt: 1,
                  }}
                >
                  {/* <ImageIcon /> */}
                </MyAvatar>
              </ListItemAvatar>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: {
                        xs: ".85rem !important",
                        sm: ".95rem !important",
                        md: "1rem !important",
                      },
                    }}
                  >
                    {msg.from}
                  </Typography>
                  <Typography
                    variant="h6"
                    ml={1}
                    sx={{
                      color: "grey",
                      fontWeight: "350",
                      fontSize: {
                        xs: ".85rem !important",
                        sm: ".95rem !important",
                        md: ".95rem !important",
                      },
                      opacity: "0.5",
                    }}
                  >
                    on {formattedDate(msg.createdAt)}
                  </Typography>
                </Box>
                <Divider variant="inset" sx={{ ml: 0 }} />
                <Box sx={{ flex: 1 }}>
                  {msg.body ? (
                    <Typography
                      noWrap={false}
                      sx={{
                        ml: { xs: 1, sm: 0 },
                        mt: 1,
                        fontSize: {
                          xs: ".85rem !important",
                          sm: ".9rem !important",
                          md: ".95rem !important",
                        },
                      }}
                      style={{ wordWrap: "break-all" }}
                      dangerouslySetInnerHTML={{
                        __html: msg.body,
                      }}
                    ></Typography>
                  ) : (
                    <Typography sx={{ ml: 6, mt: 1 }}>--</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      {getMessages().length === 0 && (
        <Box sx={{ textAlign: "center", mt: 6, ml: -10 }}>
          <Typography sx={{ ml: 8, mt: 1 }}>No Conversation</Typography>
        </Box>
      )}
    </List>
  );
}

export default Conversation;
