/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../../../routes/paths";

// layouts
import AuthLayout from "../../../../layouts/Auth";
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
import InviteForm from "components/auth/theme/theme1/InviteForm";
import { useDispatch, useSelector } from "redux/store";
import { resetAll } from "redux/slices/company";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Invite() {
  const dispatch = useDispatch();
  let [errorText, setErrorText] = useState("");
  let { error } = useSelector((state) => state.company);
  const {
    brand: { name = "", images },
  } = useSelector((state) => state.brand);

  useEffect(() => {
    if (error && typeof error === "string") {
      setErrorText(error);
    }
    dispatch(resetAll());
  }, [error]);

  return (
    <RootStyle title="Invite">
      <AuthLayout logoHide={true}>
        Don&#39;t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.signup}
        >
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <img
            src={images?.illustration_signup}
            alt="login"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          {errorText ? (
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Typography variant="h4" color="red" gutterBottom>
                  {errorText}
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to={PATH_AUTH.signup}
                  sx={{ mt: 5 }}
                >
                  Sign Up
                </Button>
              </Box>
            </Stack>
          ) : (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" color="red" gutterBottom>
                    {errorText}
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    Sign Up to {name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your details below.
                  </Typography>
                </Box>
              </Stack>

              <InviteForm />

              <MHidden width="smUp">
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 3, textAlign: "center" }}
                >
                  Already have an account?&nbsp;
                  <Link to={PATH_AUTH.login} component={RouterLink}>
                    Sign In
                  </Link>
                </Typography>
              </MHidden>
            </>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
