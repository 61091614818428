/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
// material
import { Box, Card, Grid, Typography } from "@mui/material";

// components

// routes
import { selectCompany } from "redux/slices/company";
import { resetRequestState } from "redux/slices/myprofile";
import { useSelector, useDispatch } from "redux/store";

// hooks
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

export default function SwitchCompanyComponent() {
  let dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let { companies, currentCompany, refetchUserDetails } = useAuth();
  const { success, error } = useSelector((state) => state?.company);

  useEffect(() => {
    if (success) {
      refetchUserDetails();
      dispatch(resetRequestState());
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error ?? "Error Occurred", {
        variant: "error",
      });
      dispatch(resetRequestState());
    }
  }, [error]);

  let handleSelectCompany = (company_id) => {
    dispatch(selectCompany({ company_id }));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {companies.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card
              sx={{
                minHeight: 200,
                bgcolor: "primary.lighter",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                cursor: "pointer",
                ...(currentCompany?.company === item?.company && {
                  bgcolor: "primary.main",
                  color: "white",
                }),
              }}
              onClick={() => handleSelectCompany(item?.company)}
            >
              <Box textAlign="center" alignSelf="center">
                <Typography variant="h4" sx={{ alignSelf: "center" }}>
                  {item.company_name}
                </Typography>

                <Typography variant="subtitle2" sx={{ alignSelf: "center" }}>
                  {item.domain}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
