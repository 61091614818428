import AddRequestLayout from "pages/RoofConnect/AddRequest";
import RoofConnectDashboard from "pages/RoofConnect/Dashboard";
import RequestsListDashboard from "pages/RoofConnect/InspectionsList";
import ViewRequestLayout from "pages/RoofConnect/ViewRequest";

import { Navigate } from "react-router-dom";
// const RoofConnectDashboard = async(() =>
//   import("../../pages/RoofConnect/Dashboard")
// );

const roofConnectRoutes = [
  {
    path: "",
    element: <Navigate to="/home" replace />,
  },
  {
    path: "home",
    element: <RoofConnectDashboard />,
  },

  {
    path: "new-request",
    element: <AddRequestLayout />,
  },
  {
    path: "/details/:requestId",
    element: <ViewRequestLayout />,
  },
  {
    path: "pending",
    element: <RequestsListDashboard />,
  },
  {
    path: "completed",
    element: <RequestsListDashboard />,
  },
];

export default roofConnectRoutes;
