import React from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "routes/paths";
// layouts
// import AuthLayout from "layouts/Auth";
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
import SignUpComponent from "components/auth/theme/theme2/Signup_form";
// import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "@mui/material";
// redux
import { useSelector } from "redux/store";

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
  backgroundColor: "#0A36A3",
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 580,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",

  // padding: theme.spacing(12, 0),
}));

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function SingUpPage({ formik }) {
  // const { errors, touched, getFieldProps } = formik;
  const {
    brand: { images },
  } = useSelector((state) => state.brand);
  const mobileView = useMediaQuery("(max-width:425px)");
  const windowView = useMediaQuery("(min-width:426px)");
  return (
    <Box>
      {mobileView && (
        <RootStyle
          title="Sign Up"
          descriptionKey="RCSignUp"
          sx={{ backgroundColor: "#F5FBFF" }}
        >
          {/* <AuthLayout logoHide={true}>
        Already have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.login}
        >
          Sign In
        </Link>
      </AuthLayout> */}

          <Container maxWidth="sm">
            <ContentStyle sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 7,
                  pb: 2,
                  mt: 2,
                }}
              >
                <img src={images?.logo_white} alt="logo" height="50" />
              </Box>
              <Box
                sx={{
                  px: 3,
                  boxShadow: 15,
                  pt: 1,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }} textAlign="center">
                    <Typography variant="h3" sx={{ color: "#0A36A3" }}>
                      Realtors Only
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt="yellowline"
                        // src={images.illustration_signup}
                        src="/static/img/illustrations/Yellowline.svg"
                        height="10%"
                        // style={{ objectFit: "cover" }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{ fontSize: "1.1rem !important", pt: 1 }}
                    >
                      Let's Get Started
                    </Typography>
                    <Typography
                      // sx={{ color: "text.secondary" }}
                      variant="subtitle1"
                      sx={{
                        px: 0,
                        fontSize: ".86rem",
                        lineHeight: "1rem",
                        color: "#161A20",
                        opacity: 0.7,
                      }}
                    >
                      Avoid delays in closing and have confidence, knowing roof
                      issues will never hold your back.
                    </Typography>
                  </Box>
                </Box>

                <SignUpComponent formik={formik} />

                <Box
                  display="flex"
                  sx={{ mt: 3, px: 0, justifyContent: "center" }}
                >
                  {/* <Checkbox
                color="secondary"
                {...getFieldProps("privacyCheck")}
                defaultChecked
                required
                error={Boolean(touched.privacyCheck && errors.privacyCheck)}
                helperText={touched.privacyCheck && errors.privacyCheck}
              /> */}
                  <Box sx={{ alignSelf: "center" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      Already have an account?&nbsp;
                      <Link to={PATH_AUTH.login} component={RouterLink}>
                        Sign In
                      </Link>
                    </Typography>
                    {/* <Typography
                  variant="body2"
                  align="center"
                  sx={{ color: "text.secondary" }}
                >
                  I agree to {name}
                  <sup>&trade;</sup>&nbsp;
                  <Link underline="always" color="text.primary" href="#">
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link underline="always" color="text.primary" href="#">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
                <Typography color="red" variant="caption">
                  {touched.privacyCheck && errors.privacyCheck}
                </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", pt: 1, px: 1 }}>
                <Typography variant="caption">
                  By signing up, you agree to our
                  <Link
                    variant="caption"
                    target="_blank"
                    color="#284FAE"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/terms?hl=en-GB"
                  >
                    &nbsp;Terms of Services&nbsp;
                  </Link>
                  and &nbsp;
                  <Link
                    variant="caption"
                    target="_blank"
                    color="#284FAE"
                    component={RouterLink}
                    to={PATH_AUTH.signup}
                  >
                    Privacy Notice.
                  </Link>
                  This page is protected by reCAPTCHA and the &nbsp;
                  <Link
                    variant="caption"
                    target="_blank"
                    color="#284FAE"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/privacy?hl=en-GB"
                  >
                    Google Privacy Policy
                  </Link>
                  &nbsp; and &nbsp;
                  <Link
                    variant="caption"
                    target="_blank"
                    color="#284FAE"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://en.wikipedia.org/wiki/Terms_of_service#:~:text=Terms%20of%20service%20(also%20known,to%20use%20the%20offered%20service."
                  >
                    Terms of Services
                  </Link>
                  &nbsp; apply.
                </Typography>
              </Box>
            </ContentStyle>
          </Container>
          <MHidden width="mdDown">
            <SectionStyle>
              <img
                alt="register"
                src={images.illustration_signup}
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
      {windowView && (
        <RootStyle
          title="Sign Up"
          descriptionKey="RCSignUp"
          sx={{ backgroundColor: "#F5FBFF" }}
        >
          {/* <AuthLayout logoHide={true}>
        Already have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.login}
        >
          Sign In
        </Link>
      </AuthLayout> */}

          <Container maxWidth="md">
            <ContentStyle sx={{ px: 0, pt: 5, pb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pb: 4,
                }}
              >
                <img src={images?.logo_white} alt="logo" height="70"></img>
              </Box>
              <Box
                sx={{
                  px: 6,
                  boxShadow: 15,
                  pt: 1,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }} textAlign="center">
                    <Typography variant="h4" sx={{ color: "#0A36A3", mt: 2 }}>
                      Realtors Only
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt="yellow line"
                        // src={images.illustration_signup}
                        src="/static/img/illustrations/Yellowline.svg"
                        height="10%"
                        // style={{ objectFit: "cover" }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{ fontSize: "1.2rem !important", pt: 1 }}
                    >
                      Let's Get Started
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        px: 9,
                        fontSize: ".86rem",
                        lineHeight: "1rem",
                        color: "#161A20",
                        opacity: 0.7,
                      }}
                    >
                      Avoid delays in closing and have confidence, knowing roof
                      issues will never hold your back.
                    </Typography>
                  </Box>
                </Box>

                <SignUpComponent formik={formik} />

                <Box
                  display="flex"
                  sx={{ mt: 3, px: 4, justifyContent: "center" }}
                >
                  {/* <Checkbox
                color="secondary"
                {...getFieldProps("privacyCheck")}
                defaultChecked
                required
                error={Boolean(touched.privacyCheck && errors.privacyCheck)}
                helperText={touched.privacyCheck && errors.privacyCheck}
              /> */}
                  <Box sx={{ alignSelf: "center" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      Already have an account?&nbsp;
                      <Link to={PATH_AUTH.login} component={RouterLink}>
                        Sign In
                      </Link>
                    </Typography>
                    {/* <Typography
                  variant="body2"
                  align="center"
                  sx={{ color: "text.secondary" }}
                >
                  I agree to {name}
                  <sup>&trade;</sup>&nbsp;
                  <Link underline="always" color="text.primary" href="#">
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link underline="always" color="text.primary" href="#">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
                <Typography color="red" variant="caption">
                  {touched.privacyCheck && errors.privacyCheck}
                </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ pt: 2, px: 1, textAlign: "center" }}>
                <Typography variant="caption">
                  By signing up, you agree to our
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/terms?hl=en-GB"
                  >
                    &nbsp;Terms of Services&nbsp;
                  </Link>
                  and &nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/terms?hl=en-GB"
                  >
                    Privacy Notice.
                  </Link>
                  <br></br>
                  This page is protected by reCAPTCHA and the &nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://policies.google.com/privacy?hl=en-GB"
                  >
                    Google Privacy Policy
                  </Link>
                  &nbsp; and &nbsp;
                  <Link
                    variant="caption"
                    color="#284FAE"
                    target="_blank"
                    // component={RouterLink}
                    // to={PATH_AUTH.signup}
                    href="https://en.wikipedia.org/wiki/Terms_of_service#:~:text=Terms%20of%20service%20(also%20known,to%20use%20the%20offered%20service."
                  >
                    Terms of Services
                  </Link>
                  &nbsp; apply.
                </Typography>
              </Box>
            </ContentStyle>
          </Container>
          <MHidden width="mdDown">
            <SectionStyle>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  px: 5,
                }}
              >
                Speed Up Your Closings — Guaranteed.
              </Typography>
              <Box sx={{ pl: 8, pt: 3, pr: 6 }}>
                <img
                  alt="register"
                  src={images.illustration_signup}
                  height="100%"
                />
              </Box>
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
    </Box>
  );
}
