import { motion, AnimatePresence } from "framer-motion";
// material
import { Dialog, Box, Paper } from "@mui/material";
//
import { varFadeInUp } from "./variants";

// ----------------------------------------------------------------------

export default function DialogAnimate({
  open = false,
  animate,
  onClose,
  children,
  dialogWidth,
  ...other
}) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth={dialogWidth || "xs"}
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={motion.div}
              {...(animate || varFadeInUp)}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                onClick={onClose}
                sx={{ width: "100%", height: "100%", position: "fixed" }}
              />
              <Paper {...props}>{props.children}</Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
