import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
// components
import { MHidden } from "../components/@material-extend";
// redux
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

export default function AuthLayout({ children, logoHide }) {
  const {
    brand: { images },
  } = useSelector((state) => state.brand);
  return (
    <HeaderStyle>
      {!logoHide && (
        <RouterLink to="/">
          {/* <Logo /> */}
          <img src={images?.icon_svg} alt="logo" width={40} height={40} />
        </RouterLink>
      )}

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 },
            mr: 0,
            ml: "auto",
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
