import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const UPDATE_STATE = "UPDATE_STATE";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  currentCompany: null,
  companies: null,
  integrations: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: {
          role: action.payload?.currentCompany?.role,
          ...action.payload.user,
        },
        currentCompany: action.payload?.currentCompany,
        readOnly: action.payload?.currentCompany?.role === "user",
        companies: action.payload?.companies,
        integrations: action.payload?.integrations,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          role: action.payload?.currentCompany?.role,
          ...action.payload.user,
        },
        currentCompany: action.payload?.currentCompany,
        readOnly: action.payload?.currentCompany?.role === "user",
        companies: action.payload?.companies,
        integrations: action.payload?.integrations,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        currentCompany: null,
        companies: null,
        integrations: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          role: action.payload?.currentCompany?.role,
          ...action.payload.user,
        },
        currentCompany: action.payload?.currentCompany,
        companies: action.payload?.companies,
        integrations: action.payload?.integrations,
      };

    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.post("/api/auth/token");
          const { user, currentCompany, companies, integrations } =
            response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              currentCompany,
              companies,
              integrations,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              currentCompany: null,
              companies: null,
              integrations: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            currentCompany: null,
            companies: null,
            integrations: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (body) => {
    try {
      let response = await axios.post("/api/auth/login", body);
      const success = response.data?.token ? true : false;
      if (success) {
        // check if it has active subscription
        const {
          token: accessToken,
          user,
          currentCompany,
          companies,
          integrations,
        } = response.data;

        setSession(accessToken);
        dispatch({
          type: SIGN_IN,
          payload: {
            user,
            currentCompany,
            companies,
            integrations,
          },
        });
      }

      return {
        success: success,
        message: success ? "Successful Sign In" : "Sign In Failed",
        currentCompany: response.data.currentCompany,
      };
    } catch (error) {
      return { success: false, message: error?.message, currentCompany: {} };
    }
  };

  const superLogin = async (body) => {
    try {
      let response = await axios.post("/api/auth/super-login", body);
      const success = response.data?.token ? true : false;
      if (success) {
        // check if it has active subscription
        const {
          token: accessToken,
          user,
          currentCompany,
          companies,
          integrations,
        } = response.data;
        setSession(accessToken);
        dispatch({
          type: SIGN_IN,
          payload: {
            user,
            currentCompany,
            companies,
            integrations,
          },
        });
      }
      return {
        success: success,
        message: success ? "Successful Sign In" : "Sign In Failed",
        currentCompany: response.data.currentCompany,
      };
    } catch (error) {
      return { success: false, message: error?.message, currentCompany: {} };
    }
  };

  const logout = async () => {
    try {
      await axios.post("/api/auth/sign-out");
    } catch (error) {
      console.error(error);
    }
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (fields) => {
    try {
      const response = await axios.post("/api/auth/sign-up", fields);
      if (response?.data?.token) {
        const {
          token: accessToken,
          user,
          currentCompany,
          companies,
          integrations,
        } = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        setSession(accessToken);
        dispatch({
          type: SIGN_UP,
          payload: {
            user,
            currentCompany,
            companies,
            integrations,
          },
        });

        return { success: true, message: "Successful Sign Up" };
      }
    } catch (error) {
      return {
        success: false,
        message: error?.message ?? "Error Occured",
      };
    }
  };

  const acceptInvitedUser = async (fields) => {
    try {
      const response = await axios.post("/api/companies/user/accepted", fields);
      if (response?.data?.token) {
        const {
          token: accessToken,
          user,
          currentCompany,
          companies,
          integrations,
        } = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        setSession(accessToken);
        dispatch({
          type: SIGN_UP,
          payload: {
            user,
            currentCompany,
            companies,
            integrations,
          },
        });

        return { success: true, message: "Successful Sign Up" };
      }
    } catch (error) {
      return {
        success: false,
        message: error?.message ?? "Error Occured",
      };
    }
  };

  const forgotPassword = async (data) => {
    try {
      await axios.post("/api/auth/forgot-password", data);

      return {
        success: true,
        message: "Email Sent Successfully",
      };
    } catch (error) {
      return { success: false, message: error?.message };
    }
  };

  const resetPassword = async (values) => {
    let response = await axios.post("/api/auth/reset-password", values);
    return response?.data;
  };

  const refetchUserDetails = async () => {
    try {
      const response = await axios.get("/api/users/me");
      const { user, currentCompany, companies, integrations } = response.data;
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          user,
          currentCompany,
          companies,
          integrations,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserDetails = async (data) => {
    try {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          ...data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const refreshToken = async (data) => {
    try {
      const response = await axios.post("/api/auth/token");
      setSession(response?.data?.token);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        superLogin,
        logout,
        signUp,
        forgotPassword,
        resetPassword,
        refetchUserDetails,
        updateUserDetails,
        acceptInvitedUser,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
