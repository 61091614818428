import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import async from "components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";
// import PresentationLayout from "../layouts/Presentation";

// Guards
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
import { useSelector } from "redux/store";

// Auth components
import Login from "pages/auth/Login";
import SignUp from "pages/auth/SignUp";
import ValidateCompany from "pages/auth/ValidateCompany";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import Invite from "pages/auth/inviteform";
import Page404 from "pages/Page404";
import Page500 from "pages/Page500";
import SwitchCompany from "pages/dashboards/SwitchCompany";
import hasIntegration from "utils/hasIntegration";
import useAuth from "hooks/useAuth";
import leadManagerRoutes from "./integrations/leadManager";
import roofConnectRoutes from "./integrations/roofConnect";
//import roofConnectRoutes from "./integrations/roofConnect";

// Documentation

// Landing
// import Landing from "../pages/presentation/Landing";

const UserManagement = async(() =>
  import("../pages/dashboards/UserManagement")
);
const Invoice = async(() => import("../pages/dashboards/Invoice"));
const ProfileAndBilling = async(() =>
  import("../pages/dashboards/ProfileAndBilling")
);

let routes = [
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "superlogin",
    element: <Login />,
  },
  {
    path: "signup",
    element: (
      <GuestGuard>
        <SignUp />
      </GuestGuard>
    ),
  },
  {
    path: "invite",
    element: (
      <GuestGuard>
        <Invite />
      </GuestGuard>
    ),
  },

  {
    path: "forgot-password",
    element: (
      <GuestGuard>
        <ForgotPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password/:userId/:token",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "invite/:token",
    element: (
      <GuestGuard>
        <Invite />
      </GuestGuard>
    ),
  },
  {
    path: "*",
    element: <LogoOnlyLayout />,
    children: [
      { path: "500", element: <Page500 /> },
      {
        path: "404",
        element: (
          <AuthGuard>
            <Page404 />
          </AuthGuard>
        ),
      },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

const projectWithBillingRoutes = [
  {
    path: "validatecompany",
    element: (
      <AuthGuard>
        <ValidateCompany />
      </AuthGuard>
    ),
  },
  {
    path: "/invoice/:invoiceId",
    element: (
      <AuthGuard>
        <Invoice />
      </AuthGuard>
    ),
  },
];

const defaultAuthRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "profile",
      element: <ProfileAndBilling />,
    },
    {
      path: "my-account",
      element: <ProfileAndBilling />,
    },
    {
      path: "users",
      element: <UserManagement />,
    },
    {
      path: "switchcompany",
      element: <SwitchCompany />,
    },
  ],
};

const defaultBillingRoutes = [
  {
    path: "profile/:pageType",
    element: <ProfileAndBilling />,
  },
  {
    path: "billing/:pageType",
    element: <ProfileAndBilling />,
  },
];

export default function Router() {
  const { currentCompany } = useAuth();
  // const { brand } = useSelector((state) => state.brand);
  // if Lead Manager Integration Found on the current company Load its routes and add them to the default routes
  if (hasIntegration("website-leads", currentCompany)) {
    defaultAuthRoutes.children = [
      ...leadManagerRoutes,
      ...defaultAuthRoutes.children,
    ];
  }

  // if Roof Connect Integration Found on the current company Load its routes and add them to the default routes
  if (hasIntegration("roof-connect", currentCompany)) {
    defaultAuthRoutes.children = [
      ...roofConnectRoutes,
      ...defaultAuthRoutes.children,
    ];
  }

  // attach default billing related "root" routes if the brand supports the billing module.
  //if (brand?.options?.bool_billing_enabled) {
  routes = [...routes, ...projectWithBillingRoutes];
  //}

  // attach billing "children" routes if the brand supports the billing module.
  //if (brand?.options?.bool_billing_enabled) {
  defaultAuthRoutes.children = [
    ...defaultBillingRoutes,
    ...defaultAuthRoutes.children,
  ];
  //}

  // Combine the routes
  routes.push(defaultAuthRoutes);

  return useRoutes(routes);
}
