import React from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "routes/paths";
// layouts
import AuthLayout from "layouts/Auth";
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
import SignUpComponent from "components/auth/SignUp_v2";
// import Checkbox from "@mui/material/Checkbox";
import logoImage from "assets/images/SpamBurner Black.svg";
// redux
import { useSelector } from "redux/store";
import HighlightBox from "./highlightBox";

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 520,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function SingUpPage({ formik }) {
  const { errors, touched } = formik;
  const {
    brand: { images = {} },
  } = useSelector((state) => state.brand);

  return (
    <RootStyle title="Sign Up">
      <AuthLayout logoHide={true}>
        Already have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.login}
        >
          Sign In
        </Link>
      </AuthLayout>

      <Container>
        <ContentStyle>
          <Box
            sx={{
              padding: "40px",
              borderRadius: "7px",
              boxShadow: 3,
            }}
          >
            <Box
              sx={{
                display: { xs: "flex" },
                alignItems: "center",
                mb: 2,
                justifyContent: "center",
              }}
            >
              {/* <img src={images?.SpamBurnerBlack} alt="logo" height="40" /> */}
              <img src={logoImage} alt="logo" height="40" />
            </Box>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }} textAlign="center">
                <Typography variant="h4" gutterBottom>
                  Get started absolutely free.
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Sign up today.
                </Typography>
              </Box>
            </Box>

            <SignUpComponent formik={formik} />

            <Box display="flex" sx={{ mt: 3, justifyContent: "center" }}>
              {/* <Checkbox
              color="default"
              {...getFieldProps("privacyCheck")}
              defaultChecked
              required
              error={Boolean(touched.privacyCheck && errors.privacyCheck)}
              helperText={touched.privacyCheck && errors.privacyCheck}
            /> */}
              <Box
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  By clicking on the "Sign Up" you agree to the
                  {/* <sup>&trade;</sup> */}
                  &nbsp;
                  <Link
                    underline="always"
                    color="text.primary"
                    target="blank"
                    href="https://spamburner.com/end-user-license-agreement/"
                  >
                    Terms and Conditions.
                  </Link>
                  {/* &nbsp;and&nbsp; */}
                  {/* <Link underline="always" color="text.primary" href="#">
                  Privacy Policy
                </Link> */}
                </Typography>
                <Typography color="red" variant="caption">
                  {touched.privacyCheck && errors.privacyCheck}
                </Typography>
              </Box>
            </Box>

            <MHidden width="smUp">
              <Typography
                variant="subtitle2"
                sx={{ mt: 3, textAlign: "center" }}
              >
                Already have an account?&nbsp;
                <Link to={PATH_AUTH.login} component={RouterLink}>
                  Sign In
                </Link>
              </Typography>
            </MHidden>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
