import React from "react";
import { Box, Link, TextareaAutosize, Button } from "@mui/material";

function Reply() {
  return (
    <Box>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={10}
        placeholder="Type Here"
        style={{ width: "95%", borderRadius: 6, p: 3 }}
      />
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          sx={{
            color: "black",
            bgcolor: "white",
            mr: 2,
            "&:hover": {
              bgcolor: "white",
            },
          }}
        >
          Reply
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: "white",
            borderColor: "white",
            "&:hover": {
              borderColor: "white",
            },
          }}
        >
          Upload Files
        </Button>
        <Link
          href="#"
          sx={{
            pr: 12,
            color: "#7689AA",
            pt: 1,
            ml: "auto",
            textDecoration: "underline",
          }}
        >
          Close Request
        </Link>
      </Box>
    </Box>
  );
}

export default Reply;
