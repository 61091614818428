import { Grid } from "@mui/material";
import Stats from "../../../components/Stats";
//import React, { useMemo } from "react";
import ContactRoofer from "./ContactRoofer";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/paths";
// eslint-disable-next-line no-unused-vars
const getStatsData = (stats) => ({
  PENDING: {
    title: "Pending Inspections",
    amount: stats?.totalLeadsLast24H ?? 0,
    tooltipDescription: "Number of inspection in the pending List",
    amountColor: "#ED2B2B",
    countUpProps: {
      start: 0,
      end: stats?.totalLeads ?? 0,
      formattingFn: (value) => value,
    },
  },
  COMPLETED: {
    title: "Completed Inspections",
    amount: stats?.totalLeadsLast24H ?? 0,
    amountColor: "#14B263",
    tooltipDescription: "Number of completed inspection last 24 hours",
    countUpProps: {
      start: 0,
      end: stats?.totalLeadsLast24H ?? 0,
      formattingFn: (value) => value,
    },
  },
});

export const StatsSection = ({ stats, loading }) => {
  const navigate = useNavigate();
  const pendingNbr = {
    start: 1,
    end: stats?.total_conversation_pending ?? 0,
    formattingFn: (value) => value,
  };
  const closedNbr = {
    start: 1,
    end: stats?.total_conversation_closed ?? 0,
    formattingFn: (value) => value,
  };
  const mobileview = useMediaQuery("(max-width:425px)");
  const windowview = useMediaQuery("(min-width:426px)");
  // const STATS_DATA = useMemo(() => getStatsData(stats), [stats]);
  return (
    <>
      {" "}
      {mobileview && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} key="contact">
            <ContactRoofer
              title="Request a Roofing Inspection Now —
Guaranteed in 48 hours."
            />
          </Grid>

          <Grid
            sx={{
              diplay: "flex",
              textAlign: "center",
              justifyContent: "center",
              mb: 1,
            }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <Stats
              size={0}
              title="Pending Inspections"
              // tooltipDescription="Number of inspection in the pending List"
              amount={loading ? 0 : stats?.total_conversation_pending}
              countUpProps={loading ? 0 : pendingNbr}
              fontSize={45}
              amountColor="#FFE000"
              onClick={() => navigate(PATH_DASHBOARD.pending)}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            sx={{
              diplay: "flex",
              textAlign: "center",
              justifyContent: "center",
              mb: 1,
            }}
          >
            <Stats
              size={0}
              title="Completed Inspections"
              // tooltipDescription="Number of inspection in the Closed List"
              amount={loading ? 0 : stats?.total_conversation_closed}
              countUpProps={loading ? 0 : closedNbr}
              fontSize={45}
              amountColor="#14B263"
              onClick={() => navigate(PATH_DASHBOARD.completed)}
            />
          </Grid>
        </Grid>
      )}
      {windowview && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} key="contact">
            <ContactRoofer
              title="Request a Roofing Inspection Now —
Guaranteed in 48 hours."
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3} mb={1}>
            <Stats
              size={0}
              title="Pending Inspections"
              // tooltipDescription="Number of inspection in the pending List"
              amount={loading ? 0 : stats?.total_conversation_pending}
              countUpProps={loading ? 0 : pendingNbr}
              fontSize={65}
              amountColor="#FFE000"
              onClick={() => navigate(PATH_DASHBOARD.pending)}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3}>
            <Stats
              size={0}
              title="Completed Inspections"
              // tooltipDescription="Number of inspection in the Closed List"
              amount={loading ? 0 : stats?.total_conversation_closed}
              countUpProps={loading ? 0 : closedNbr}
              fontSize={65}
              amountColor="#14B263"
              onClick={() => navigate(PATH_DASHBOARD.completed)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
