const getUserData = (fields) => {
  const optionLabels = {
    36211: "name",
    36212: "address",
    36213: "phone",
    36463: "type",
  };
  const options = {};

  fields.map((item) => {
    let keyOpt = optionLabels[item.id];
    options[keyOpt] = item.value;
    return null;
  });

  return options;
};

export default getUserData;
