import React from "react";
// import styled from "styled-components/macro";
import { Typography as MuiTypography, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "white !important",
      borderRadius: "2px",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: ({ loaderColor }) => loaderColor || "#0A36A3",
      borderRadius: "2px",
    },
  },
}));

function FileUpload({
  handleRemove,
  file,
  index,
  loaderColor,
  backgroundColor = "#F7F7F9",
}) {
  const classes = useStyles({ loaderColor });

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = Math.random() * 30;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid container spacing={0} s>
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        sx={{
          px: 2,
          backgroundColor: backgroundColor,
        }}
      >
        <MuiTypography sx={{ color: "#000", fontSize: ".8rem" }} variant="p">
          {file.fileName.substring(0, 18)} ({(file.size / 1024).toFixed(2)} KB)
        </MuiTypography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        sx={{ px: 2, backgroundColor: backgroundColor }}
      >
        <LinearProgress
          className={classes.root}
          sx={{
            top: "8px",
            height: "10px",
            borderRadius: "2px",
            backgroundColor: "transparent",
          }}
          variant="determinate"
          value={progress}
        />
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        sx={{ px: 2, backgroundColor: backgroundColor }}
      >
        <IconButton
          onClick={() => handleRemove(index)}
          disabled={progress < 100}
          sx={{
            backgroundColor: "transparent",
            color: "#000",
            float: "right",

            mt: "2px",
            "&:hover": { backgroundColor: "#fff", color: "#000" },
          }}
          size="small"
        >
          <CloseIcon fontSize="10px" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default FileUpload;
