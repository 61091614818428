import { Helmet } from "react-helmet-async";
// import { useLocation } from "react-router-dom";
import { forwardRef, useEffect, useCallback } from "react";
// material
import { Box } from "@mui/material";
// utils
// import track from "../utils/analytics";

const pageDescriptions = {
  RCLogin:
    "Realtors - Log in to your Realtor Roof Connect account to request a new roof inspection and view pending inspections in your dashboard.",
  RCSignUp:
    "Realtors - Get roof inspections for your clients back within 48 hours and bulletproof your transactions. Platform is free to use.",
  Profile:
    "View your personal account details like email address, phone number, time zone, and company name. Change these details at any time.",
  "Manage Users":
    "Under user management in your dashboard, you can invite users like other realtors or team members that need access to inspection details.",
  "Request Roof Inspection":
    "Easily request a roof inspection from a vetted local roofing company. Fill out property details and get the inspection back within 48 hours, guaranteed.",
  Home: "View all your pending and completed roof inspections and request new inspections from your personalized dashboard.",
  "Pending Roof Inspections":
    "View all pending roof inspections and manage or close out an inspection request at any time from the dashboard.",
  "Completed Roof Inspections": `View all completed roofing inspections in "closed" status from your dashboard. View all details of the closed request at any time.`,
  "Roof Inspection Details":
    "View details about any roof inspection including client's personal info, job info, or attachments. You can also send a message to the roofing company.",
  Support:
    "Need support for the Realtor Roof Connect platform? Put in a support ticket 24/7 to our help desk.",
};

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = "", descriptionKey = "", ...other }, ref) => {
    // const { pathname } = useLocation();

    const sendPageViewEvent = useCallback(() => {
      // track.pageView({
      //   page_path: pathname,
      // });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
          {(pageDescriptions[title] || pageDescriptions[descriptionKey]) && (
            <meta
              name="description"
              content={
                pageDescriptions[title] || pageDescriptions[descriptionKey]
              }
              data-react-helmet="true"
            />
          )}
        </Helmet>
        {children}
      </Box>
    );
  }
);

export default Page;
