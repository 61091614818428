import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/companies";

const slice = createSlice({
  name: "company",
  initialState: {
    page: 1,
    per_page: 25,
    total_pages: 0,
    total_records: 0,
    list: [],
    all: [],
    manager: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    invitedUser: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 1;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.list = [];
      state.all = [];
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    getManagerState(state, action) {
      state.manager = action.payload;
      state.isLoading = false;
    },
    getInvitedUser(state, action) {
      state.invitedUser = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  resetAll,
  requestStarted,
  requestedFailed,
  stateLoaded,
  getManagerState,
  getInvitedUser,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const selectCompany = (data) =>
  apiCallBegan({
    url: url + `/select`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const getManager = () =>
  apiCallBegan({
    url: url + `/manager`,
    onStart: requestStarted.type,
    onSuccess: getManagerState.type,
    onError: requestedFailed.type,
  });

export const changeDomainRequest = (data) =>
  apiCallBegan({
    url: url + "/change-domain",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const openTicketRequest = (data) =>
  apiCallBegan({
    url: url + "/open-ticket",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const validateCompany = () =>
  apiCallBegan({
    url: url + "/validate",
    method: "post",
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const installationWebGuy = (data) =>
  apiCallBegan({
    url: url + "/web-guy",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const verifyInviteToken = (data) =>
  apiCallBegan({
    url: url + `/user/accepted/${data}`,
    onStart: requestStarted.type,
    onSuccess: getInvitedUser.type,
    onError: requestedFailed.type,
  });

export const acceptInvite = (data) =>
  apiCallBegan({
    url: url + `/user/accepted`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });
