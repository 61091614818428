import { useState } from "react";
import { Icon } from "@iconify/react";

import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { onKeyDown, onEmailBlur } from "../../utils/commonMethods";

// ----------------------------------------------------------------------

export default function RegisterForm({ formik }) {
  const [showPassword, setShowPassword] = useState(false);
  const [hasExists, setHasExists] = useState(false);
  const [lockSubmit, setlockSubmit] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isSubmitting,
    setFieldValue,
  } = formik;

  const onEmailKeyDown = (e) => {
    setlockSubmit(true);
  };

  return (
    <Stack spacing={3}>
      {errors.afterSubmit && (
        <Alert severity="error">{errors.afterSubmit}</Alert>
      )}

      <Stack direction={{ xs: "column" }} spacing={2}>
        <TextField
          fullWidth
          label="Name"
          {...getFieldProps("user.first_name")}
          error={Boolean(touched.user?.first_name && errors.user?.first_name)}
          helperText={touched.user?.first_name && errors.user?.first_name}
          onKeyDown={(e) => onKeyDown(e, values.user.first_name)}
          onBlur={(e) =>
            setFieldValue("user.first_name", values.user.first_name.trim())
          }
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            style: { color: "black" },
          }}
        />

        {/* <TextField
          fullWidth
          label="Last Name"
          {...getFieldProps("user.last_name")}
          error={Boolean(touched.user?.last_name && errors.user?.last_name)}
          helperText={touched.user?.last_name && errors.user?.last_name}
          onKeyDown={(e) => onKeyDown(e, values.user.last_name)}
          onBlur={(e) =>
            setFieldValue("user.last_name", values.user.last_name.trim())
          }
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            style: { color: "black" },
          }}
          // value={lasttxt}
          // onChange={onInputChange2}
        /> */}
      </Stack>

      <TextField
        fullWidth
        autoComplete="username"
        type="email"
        label="Email Address"
        {...getFieldProps("user.email")}
        onBlur={(e) =>
          onEmailBlur(values.user?.email, e, setHasExists, setlockSubmit)
        }
        onKeyDown={(e) => onEmailKeyDown(e)}
        error={Boolean(
          (touched.user?.email && errors.user?.email) || hasExists
        )}
        helperText={
          touched.user?.email && errors.user?.email
            ? "Please provide a valid email address"
            : hasExists
            ? "User Already Exists. Please try Signing in."
            : ""
        }
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        InputLabelProps={{
          style: { color: "black" },
        }}
      />
      <TextField
        fullWidth
        required
        variant="outlined"
        label="Web Address"
        placeholder="YourWebsite.com"
        {...getFieldProps("company.domain")}
        error={Boolean(touched.company?.domain && errors.company?.domain)}
        helperText={touched.company?.domain && errors.company?.domain}
        sx={{ mb: 5 }}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
          style: {
            textTransform: "lowercase",
          },
        }}
        InputLabelProps={{
          style: { color: "black" },
        }}
      />
      <TextField
        fullWidth
        autoComplete="current-password"
        type={showPassword ? "text" : "password"}
        label="Password"
        {...getFieldProps("user.password")}
        InputProps={{
          startAdornment: <InputAdornment></InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        error={Boolean(touched.user?.password && errors.user?.password)}
        helperText={touched.user?.password && errors.user?.password}
      />

      {/* {values?.user?.password?.length > 0 ? (
        <TextField
          fullWidth
          autoComplete="current-password"
          type={showConfirmPassword ? "text" : "password"}
          label="Confirm Password"
          {...getFieldProps("user.confirmPassword")}
          InputProps={{
            startAdornment: <InputAdornment></InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: "black" },
          }}
          error={Boolean(
            touched.user?.confirmPassword && errors.user?.confirmPassword
          )}
          helperText={
            touched.user?.confirmPassword && errors.user?.confirmPassword
          }
        />
      ) : null} */}

      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        type="submit"
        disabled={!values?.privacyCheck || hasExists || lockSubmit}
        endIcon={<ArrowForwardIcon />}
        loading={isSubmitting}
        sx={{ backgroundColor: "#6320EE" }}
      >
        Sign Up
      </LoadingButton>
    </Stack>
  );
}
