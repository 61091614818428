import { useState } from "react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
//
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  TextField,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { LoadingButton } from "@mui/lab";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { PATH_AUTH } from "../../routes/paths";
//
import { MIconButton } from "../@material-extend";

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ userId, token }) {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Password must contain a min. of 8 characters, at least one lowercase and capital letter and a number"
      )
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: userId,
      token: token,
      password: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      try {
        let response = await resetPassword(values);
        if (isMountedRef.current) {
          setSubmitting(false);
          enqueueSnackbar(response?.message, {
            variant: "success",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          navigate(PATH_AUTH.login);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
          enqueueSnackbar(error.message, {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            {...getFieldProps("password")}
            label="New Password"
            placeholder="New Password"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: <InputAdornment></InputAdornment>,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
              // startAdornment: (
              //   <InputAdornment position="start"></InputAdornment>
              // ),
            }}
            InputLabelProps={{
              sx: {
                // set the color of the label when not shrinked
                color: "black",
                [`&.${inputLabelClasses.shrink}`]: {
                  // set the color of the label when shrinked (usually when the TextField is focused)
                  color: "",
                },
              },
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
