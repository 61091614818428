/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate, useMatch } from "react-router-dom";
// material
import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
// redux
import { useDispatch, useSelector } from "redux/store";
import { openTicketRequest, resetAllState } from "redux/slices/company";
import { hireUsRequestState } from "redux/slices/billing";
import useAuth from "hooks/useAuth";
import { PATH_DASHBOARD } from "routes/paths";

export default function HireUs({ handlePreviousStep, skipForNow }) {
  let { user, currentCompany } = useAuth();
  let match = useMatch("/profile/installation");
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, success, error } = useSelector((state) => state.company);

  const HireUsFormSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().strict(false).trim().required("Message is required"),
    checked: Yup.boolean().oneOf([true], "Permission is required"),
  });

  const formik = useFormik({
    initialValues: {
      subject: `Setup Help | ${currentCompany?.domain}`,
      message: "Website log in URL: \n\nUsername: \n\nPassword: ",
      checked: false,
    },
    validationSchema: HireUsFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let data = { ...values };
      delete data.checked;
      dispatch(openTicketRequest(data));
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  const handleChange = (event) => {
    setFieldValue("checked", event.target.checked);
  };

  let [isSuccess, setIsSuccess] = useState(false);
  let [isError, setIsError] = useState(false);

  useEffect(() => {
    if (success) {
      setIsSuccess(true);
      enqueueSnackbar(success?.message ?? "Successfully Sent", {
        variant: "success",
      });
      dispatch(resetAllState());
      if (!currentCompany.validation_date) {
        localStorage.setItem("hireUsRequested", true);
        dispatch(hireUsRequestState({ hireUsRequested: true }));
        setTimeout(() => {
          navigate(PATH_DASHBOARD.profile.billing.paymentmethod);
        }, 3000);
      }
    }
  }, [success]);
  useEffect(() => {
    if (error) {
      setIsError(true);
      dispatch(resetAllState());
    }
  }, [error]);

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box textAlign="center" sx={{ mb: 4 }}>
            <Typography variant="h6">
              No problem :) <br /> We are happy to install it for you.
            </Typography>
            <Typography variant="body2">+$25 (one time fee)</Typography>
          </Box>
          <Box textAlign={"start"}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                disabled={true}
                type="text"
                label="Your Email"
                placeholder="yourdev@website.com"
                value={user?.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />

              <TextField
                fullWidth
                disabled={true}
                type="text"
                label="Web Address"
                placeholder="Your website.com"
                value={currentCompany?.domain}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Subject"
                {...getFieldProps("subject")}
                disabled={true}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Website Details"
                placeholder={
                  "What is your website log in link, username and password?"
                }
                multiline
                rows={7}
                {...getFieldProps("message")}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
            </Stack>
          </Box>

          <Button
            fullWidth
            size="large"
            variant="contained"
            color={isError ? "error" : "secondary"}
            sx={{ mt: 5 }}
            type="submit"
            disabled={isLoading || !values.checked}
            startIcon={
              isLoading ? (
                <CircularProgress size={18} color="primary" />
              ) : isError ? (
                <SentimentVeryDissatisfiedIcon />
              ) : isSuccess ? (
                <CheckCircleIcon />
              ) : (
                <ThumbUpOffAltIcon />
              )
            }
          >
            {isError ? "Unable to Submit Request" : "Submit Request"}
          </Button>

          <Box display="flex">
            <Checkbox
              color="default"
              sx={[
                {
                  "&:hover": {
                    backgroundColor: "unset",
                  },
                },
              ]}
              checked={values.checked}
              onChange={handleChange}
            />{" "}
            <Typography
              variant="body2"
              align="left"
              sx={{ color: "text.secondary", mt: 3 }}
            >
              I give permission for you to log in and install the code snippet
              onto my website for a one-time $25 fee.
            </Typography>
          </Box>

          <Typography color="red" variant="caption">
            {touched.checked && errors.checked}
          </Typography>
        </Form>
      </FormikProvider>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <Button
          variant="text"
          sx={{ color: "black" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => handlePreviousStep(0)}
        >
          previous
        </Button>
        {!match && (
          <Button
            variant="text"
            sx={{ color: "black" }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => skipForNow()}
          >
            skip for now
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
