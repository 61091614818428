import { stringify } from "qs";
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/integrations/leads";

const slice = createSlice({
  name: "leads",
  initialState: {
    page: 1,
    per_page: 25,
    total_pages: 0,
    total_records: 0,
    list: [],
    all: [],
    stats: null,
    chart: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 1;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.list = [];
      state.all = [];
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    updateState(state, action) {
      state = { ...state, ...action.payload };
    },
    getLeads(state, action) {
      state.isLoading = false;
      state.page = action.payload?.page;
      state.per_page = action.payload?.per_page;
      state.total_pages = action.payload?.total_pages;
      state.total_records = action.payload?.total_records;
      let list = [...action.payload?.data] ?? [];
      state.list = list;
    },
    leadsStats(state, action) {
      state.isLoading = false;
      state.stats = action.payload?.stats;
      state.chart = action.payload?.chart;
    },
    blockedOrApprovedOrDeletedLead(state, action) {
      state.list = state.list.filter(
        (item) => item._id !== action.payload?._id
      );
      state.success = { message: "Successfully Updated" };
    },
    pageChangeBackward(state, action) {
      if (state.page > 0) state.page = state.page - 1;
    },
    pageChangeForward(state, action) {
      state.page = state.page + 1;
    },
  },
});

export const {
  resetAll,
  requestStarted,
  requestedFailed,
  stateLoaded,
  updateState,
  getLeads,
  blockedOrApprovedOrDeletedLead,
  leadsStats,
  pageChangeBackward,
  pageChangeForward,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const getLeadsList = (data, goTo) => {
  switch (goTo) {
    case "previousPage":
      return { type: pageChangeBackward.type };
    case "forwardPage":
      return { type: pageChangeForward.type };
    default:
      return apiCallBegan({
        url: url + `?${stringify(data)}`,
        onStart: requestStarted.type,
        onSuccess: getLeads.type,
        onError: requestedFailed.type,
      });
  }
};

export const getLeadsStats = (data) =>
  apiCallBegan({
    url: url + `/stats?${stringify(data)}`,
    onStart: requestStarted.type,
    onSuccess: leadsStats.type,
    onError: requestedFailed.type,
  });

export const deleteLead = (data) =>
  apiCallBegan({
    url: url,
    method: "delete",
    data,
    // onStart: requestStarted.type,
    onSuccess: blockedOrApprovedOrDeletedLead.type,
    onError: requestedFailed.type,
  });

export const approveLead = (data) =>
  apiCallBegan({
    url: url + "/approve",
    method: "post",
    data,
    // onStart: requestStarted.type,
    onSuccess: blockedOrApprovedOrDeletedLead.type,
    onError: requestedFailed.type,
  });

export const blockLead = (data) =>
  apiCallBegan({
    url: url + "/blocked",
    method: "post",
    data,
    // onStart: requestStarted.type,
    onSuccess: blockedOrApprovedOrDeletedLead.type,
    onError: requestedFailed.type,
  });

export const forwardLead = (data) =>
  apiCallBegan({
    url: url + "/forward",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });
