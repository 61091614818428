import * as React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  List,
  Box,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { useSelector } from "redux/store";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
  padding: ${(props) => props.theme.spacing(0)}
    ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(0)}
    ${(props) => props.theme.spacing(2)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
  background: transparent;
  &:hover {
    background: transparent;
    color: #000000 !important;
  }
  &,
  &:hover,
  &:active {
    color: #000000 !important;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
  }
  span:hover {
    color: #000000;
  }
`;

function Footer() {
  const {
    brand: { domain = "", content },
  } = useSelector((state) => state.brand);

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={8}
        >
          <List>
            {/* <ListItemButton
              component="a"
              target="_blank"
              href={content.support}
            >
              <ListItemText primary="Support" />
            </ListItemButton> */}
            <ListItemButton
              component="a"
              target="_blank"
              href={content.license_agreement}
            >
              <ListItemText primary="License Agreement" />
            </ListItemButton>
            <ListItemButton
              component="a"
              target="_blank"
              href={content.terms_of_service}
            >
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
            <ListItemButton
              component="a"
              target="_blank"
              href={content.privacy_policy}
            >
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
            <ListItemButton
              component="a"
              target="_blank"
              href={content.cookie_policy}
            >
              <ListItemText primary="Cookie Policy" />
            </ListItemButton>
            {content?.disclaimer && (
              <ListItemButton
                component="a"
                target="_blank"
                href={content.disclaimer}
              >
                <ListItemText primary="Disclaimer" />
              </ListItemButton>
            )}
          </List>
        </Grid>
        <Grid container item xs={12} md={4} justifyContent="center">
          <List>
            {content?.footerText && (
              <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                <Box>
                  <ListItemButton
                    component="a"
                    target="_blank"
                    sx={{ ml: 2 }}
                    href={content.support}
                  >
                    <ListItemText primary="Support" />
                  </ListItemButton>
                  <ListItemButton
                    component="a"
                    target="_blank"
                    href={content.license_agreement}
                  >
                    <ListItemText primary="License Agreement" />
                  </ListItemButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1.5,
                  }}
                >
                  <a
                    target="_blank"
                    href={content.terms_of_service}
                    rel="noreferrer"
                    style={{
                      color: "#000000",
                      textDecoration: "none",
                      fontSize: "12px",
                      fontWeight: 350,
                    }}
                  >
                    Terms of Service
                  </a>
                  <span>&nbsp;|&nbsp;</span>
                  <a
                    target="_blank"
                    href={content.privacy_policy}
                    rel="noreferrer"
                    style={{
                      color: "#000000",
                      textDecoration: "none",
                      fontSize: "12px",
                      fontWeight: 350,
                    }}
                  >
                    Privacy Policy
                  </a>
                  <span>&nbsp;|&nbsp;</span>
                  <a
                    target="_blank"
                    href={content.cookie_policy}
                    rel="noreferrer"
                    style={{
                      color: "#000000",
                      textDecoration: "none",
                      fontSize: "12px",
                      fontWeight: 350,
                    }}
                  >
                    Cookie Policy
                  </a>
                </Box>
              </Box>
            )}
            <ListItemButton sx={{ ml: 2 }}>
              {content?.footerText ? (
                <a
                  target="_blank"
                  href={content?.footerLink || "/"}
                  rel="noreferrer"
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontWeight: 350,
                  }}
                >
                  {`${content?.footerText}`}
                </a>
              ) : (
                <span
                  style={{
                    fontSize: "0.875rem",
                    color: "#9e9e9e",
                  }}
                >{`© 1999 - ${new Date().getFullYear()} - ${domain}`}</span>
              )}
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
