import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const {
    brand: { images },
  } = useSelector((state) => state.brand);

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <img
            src={images?.black_full_png}
            alt="logo"
            // width={40}
            height={40}
          />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
