import { stringify } from "qs";
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/brands";

const slice = createSlice({
  name: "brand",
  initialState: {
    brand: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.isRequestSent = null;
      state.brand = null;
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    updateState(state, action) {
      state.brand = action.payload;
    },
  },
});

export const {
  resetAll,
  requestStarted,
  requestedFailed,
  stateLoaded,
  updateState,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const getBrand = (data) =>
  apiCallBegan({
    url: url + `/info?${stringify(data)}`,
    method: "get",
    onStart: requestStarted.type,
    onSuccess: updateState.type,
    onError: requestedFailed.type,
  });
