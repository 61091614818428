/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import _ from "lodash";
// material

// components
import SignUpPage from "./signup";
import Step1 from "./step1";
// hooks
import useAuth from "hooks/useAuth";
import useIsMountedRef from "hooks/useIsMountedRef";
//
import { MIconButton } from "components/@material-extend";
import { PATH_AUTH, PATH_DASHBOARD } from "routes/paths";
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let location = useLocation();
  const {
    brand: { domain, options },
  } = useSelector((state) => state.brand);

  useEffect(() => {
    const routeStep = searchParams.get("s");
    if (routeStep === "1") {
      handleNextStep();
    } else {
      handlePreviousStep();
    }
  }, [location.search]);

  let [step, setStep] = useState(0);
  let [formSubmitting, setFormSubmitting] = useState(false);

  let handleNextStep = () => {
    setStep(step + 1);
  };

  let handlePreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const { signUp } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function getCookie(name) {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    if (match) return match[2];
  }

  function getVisitor() {
    const utms = [
      "referrer_page",
      "referrer",
      "utm_source",
      "utm_campaign",
      "utm_medium",
      "utm_content",
      "utm_term",
      "_jsuid",
    ];

    let visitor = {};
    utms.forEach((utm) => {
      if (getCookie(utm)) visitor[utm] = getCookie(utm);
    });

    if (Object.keys(visitor).length === 0) return null;

    return visitor;
  }

  useEffect(() => {
    console.log(getVisitor());
  }, []);

  const Step0Schema = Yup.object().shape({
    user: Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .matches(/^(.*)?\S+(.*)?$/, "Please enter valid name")
        .required("First name required"),
      // last_name: Yup.string()
      //   .min(2, "Too Short!")
      //   .max(50, "Too Long!")
      //   .matches(/^(.*)?\S+(.*)?$/, "Please enter valid name")
      //   .required("Last name required"),
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      // phone_number: Yup.string().required("Phone Number is required"),
      password: Yup.string()
        .min(8, "Minimum 8 characters are required")
        // .matches(
        //   "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        //   "Password must contain a min. of 8 characters, at least one lowercase and capital letter and a number"
        // )
        .required("Password is required"),
      // confirmPassword: Yup.string()
      //   .when("password", {
      //     is: (val) => (val && val.length > 0 ? true : false),
      //     then: Yup.string().oneOf(
      //       [Yup.ref("password")],
      //       "Both password need to be the same"
      //     ),
      //   })
      //   .required("Confirm Password is required"),
    }),
  });

  const SignUpSchema = Yup.object().shape({
    user: Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .matches(/^(.*)?\S+(.*)?$/, "Please enter valid name")
        .required("First name required"),
      // last_name: Yup.string()
      //   .min(2, "Too Short!")
      //   .max(50, "Too Long!")
      //   .matches(/^(.*)?\S+(.*)?$/, "Please enter valid name")
      // .required("Last name required"),
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      // phone_number: Yup.string().required("Phone Number is required"),
      password: Yup.string()
        .min(8, "Minimum 8 characters are required")
        // .matches(
        //   "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        //   "Password must contain a min. of 8 characters, at least one lowercase and capital letter and a number"
        // )
        .required("Password is required"),
      // confirmPassword: Yup.string()
      //   .when("password", {
      //     is: (val) => (val && val.length > 0 ? true : false),
      //     then: Yup.string().oneOf(
      //       [Yup.ref("password")],
      //       "Both password need to be the same"
      //     ),
      //   })
      //   .required("Confirm Password is required"),
    }),
    company: Yup.object().shape({
      domain: Yup.string().required("Domain name is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        // confirmPassword: "",
      },
      company: {
        domain: "",
      },
      privacyCheck: true,
      source: domain,
    },
    enableReinitialize: true,
    validationSchema: step === 0 ? Step0Schema : SignUpSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // domain Not Required , Lets update the step
      //if (!options.bool_required_domain) step = 1;
      console.log(values);
      // if (step === 0) {
      //   navigate(PATH_AUTH.signupStep);
      //   return;
      // }

      if (!formSubmitting) {
        try {
          setFormSubmitting(true);
          let payload = JSON.parse(JSON.stringify(values));
          delete payload.privacyCheck;
          delete payload.user.confirmPassword;

          payload.company.domain = _.toLower(payload.company.domain);
          payload.company.domain = payload.company.domain.replace(
            /^https?:\/\//,
            ""
          );
          payload.company.domain = payload.company.domain.replace(
            /^http?:\/\//,
            ""
          );
          payload.company.domain = payload.company.domain.replace("www.", "");
          payload.company.domain = payload.company.domain.replace(/\//g, "");
          console.log(payload);
          if (getVisitor()) payload.visitor = getVisitor();

          let resp = await signUp(payload);

          if (resp?.success) {
            enqueueSnackbar(resp?.message, {
              variant: "success",
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });

            navigate(PATH_AUTH.validateCompany);
          } else {
            enqueueSnackbar(resp?.message, {
              variant: "error",
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });
          }
          setFormSubmitting(false);
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        } catch (error) {
          console.error(error);
          if (isMountedRef.current) {
            setErrors({ afterSubmit: error.message });
            setSubmitting(false);
          }
        }
      }
    },
  });

  const { handleSubmit } = formik;

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ height: "inherit" }}
        >
          {step === 0 ? <SignUpPage formik={formik} /> : null}

          {step === 1 ? <Step1 formik={formik} /> : null}
        </Form>
      </FormikProvider>
    </React.Fragment>
  );
}
