/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Container, Box } from "@mui/material";
import Page from "components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { StatsSection } from "./StatsSection";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "hooks/useConfirm";
import { useEffect, useState } from "react";
import Search from "../components/Search";
import LoadingScreen from "components/LoadingScreen";
import {
  getRequestsList,
  closeRequest,
  refreshRequestsList,
} from "redux/slices/requests";
import RequestsList from "./RequestsList";
import { useSnackbar } from "notistack";
//import { List } from "@mui/icons-material";
import NoDataFound from "../../../components/general/Table/NoData.js";

const RoofConnectDashboard = () => {
  const { list, stats, isLoading } = useSelector((state) => state.requests);

  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [filteredList, setFilteredList] = useState([]);

  const handleFilterUpdate = (result) => {
    setFilteredList(result);
  };

  const handleRefresh = async () => {
    setLoading(true);
    await dispatch(getRequestsList());
    setLoading(false);
  };

  const handleCloseRequest = (item) => {
    const requestId = item.id;
    confirm({
      title: "Are you sure you want to mark this inspection as complete?",
      // description: "(This will close the request on our end)",
      descriptionbelow: "(This will close the request on our end)",
      confirmationText: "Yes, It's done",
      cancellationText: "Cancel",
      ctaRight: true,
    })
      .then(async (res) => {
        setLoading(true);
        await dispatch(closeRequest(requestId));
        enqueueSnackbar("Inspection Request Closed", {
          variant: "success",
        });
        await dispatch(refreshRequestsList());
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    async function loadData() {
      await dispatch(getRequestsList());
      setLoading(false);
    }
    loadData();
    // call api or anything
  }, []);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  return (
    <Page title="Home">
      <Box>
        <Container maxWidth={false}>
          <StatsSection stats={stats} loading={isLoading} />
        </Container>
        <Container maxWidth={false} sx={{ padding: 0, mt: { xs: 2, md: 0 } }}>
          <Card sx={{ p: { xs: 1.5, md: 4 }, minHeight: "500px" }}>
            <HeaderBreadcrumbs sx={{ mb: 4 }} heading="All Inspections" />
            <Search
              component="dashboard"
              onUpdate={handleFilterUpdate}
              onRefresh={handleRefresh}
              list={list}
            ></Search>
            {!loading ? (
              <Box sx={{ mt: { xs: 1, md: 5 } }}>
                {list?.length !== 0 ? (
                  <RequestsList
                    list={filteredList}
                    handleCloseRequest={handleCloseRequest}
                  />
                ) : (
                  <NoDataFound width={200} height={150} margin={3} />
                )}
              </Box>
            ) : (
              <Box sx={{ display: "block", marginTop: "15% !important" }}>
                <LoadingScreen />
              </Box>
            )}
          </Card>
        </Container>
      </Box>
    </Page>
  );
};
export default RoofConnectDashboard;
