import React from "react";
// material
import { Box, Card as MuiCard, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import { useMatch } from "react-router-dom";

const Card = styled(MuiCard)(({ theme }) => ({
  p: 4,
  minHeight: 200,
  minWidth: 150,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  cursor: "pointer",
  "&:hover": {
    border: `4px solid ${theme.palette.primary.main}`,
  },
}));

const useStyles = makeStyles({
  root: {
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%) !important",
  },
  //other styles and classes//
});

function DoItMyself({ installType, handleInstallTypeSelection }) {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      onClick={() => handleInstallTypeSelection("DoItMyself")}
    >
      <img
        alt="Do it myself"
        src="/static/icons/installation/do_it_myself.svg"
        width={64}
        height={64}
        style={{ alignSelf: "center" }}
      />

      <Typography
        variant="h5"
        color="primary"
        sx={{ alignSelf: "center", mt: 2 }}
      >
        Do it myself
      </Typography>
    </Card>
  );
}

function SendToWebsiteGuy({ installType, handleInstallTypeSelection }) {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      onClick={() => handleInstallTypeSelection("SendToWebsiteGuy")}
    >
      <img
        alt="Do it myself"
        src="/static/icons/installation/web_guy.svg"
        width={64}
        height={64}
        style={{ alignSelf: "center" }}
      />
      <Typography
        variant="h5"
        color="primary"
        sx={{ alignSelf: "center", mt: 2 }}
      >
        Send to my
        <br />
        website guy
      </Typography>
    </Card>
  );
}

function HireUs({ installType, handleInstallTypeSelection }) {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      // sx={{
      //   ...(installType === "HireUs" && {
      //     border: (theme) => `4px solid ${theme.palette.secondary.main}`,
      //   }),
      // }}
      onClick={() => handleInstallTypeSelection("HireUs")}
    >
      <img
        alt="Do it myself"
        src="/static/icons/installation/hire_us.svg"
        width={64}
        height={64}
        style={{ alignSelf: "center" }}
      />
      <Typography
        variant="h5"
        color="primary"
        sx={{ alignSelf: "center", mt: 2 }}
      >
        Hire us
        <br />
        +$25
      </Typography>
    </Card>
  );
}

export default function InstallType(props) {
  let match = useMatch("/profile/installation");
  return (
    <React.Fragment>
      <Box textAlign="center" sx={{ mb: 4 }}>
        <Typography variant="h4">Awesome!</Typography>
        <Typography variant="h4">
          How do you want to connect your website?
        </Typography>
        <Typography variant="caption">Install your code</Typography>
      </Box>
      <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} md={6} lg={4} sx={{ justifyContent: "center" }}>
          <DoItMyself {...props} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ justifyContent: "center" }}>
          <SendToWebsiteGuy {...props} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ justifyContent: "center" }}>
          <HireUs {...props} />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
        {/* <Button
          variant="text"
          sx={{ color: "black" }}
          startIcon={<ArrowBackIcon />}
          // onClick={() => handlePreviousStep(0)}
        >
          previous
        </Button> */}
        {!match && (
          <Button
            variant="text"
            sx={{ color: "black" }}
            endIcon={<ArrowForwardIcon />}
            onClick={props.skipForNow}
          >
            skip for now
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
