import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const getPalettes = (
  PRIMARY = {
    lighter: "#f5eeff",
    light: "#8149f2",
    main: "#6320EE",
    dark: "#9b6df4",
    darker: "#4310df",
  },
  SECONDARY = {
    lighter: "#a1fff8",
    light: "#69ffc5",
    main: "#23CC94",
    dark: "#009a66",
    darker: "#006b3b",
  },
  INFO = {
    lighter: "#D0F2FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#0C53B7",
    darker: "#04297A",
  },
  SUCCESS = {
    lighter: "#C8EDCA",
    light: "#AAF27F",
    main: "#59C961",
    dark: "#229A16",
    darker: "#08660D",
  },
  WARNING = {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#FFC107",
    dark: "#B78103",
    darker: "#7A4F01",
  },
  ERROR = {
    lighter: "#FCE0E0",
    light: "#FFA48D",
    main: "#E01E1E",
    dark: "#C44545", //#B72136
    darkRed: "#993131",
    darker: "#7A0C2E",
  }
) => {
  const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
  };

  const COMMON = {
    common: { black: "#000", white: "#fff" },
    primary: { ...PRIMARY, contrastText: "#fff" },
    secondary: { ...SECONDARY, contrastText: "#fff" },
    info: { ...INFO, contrastText: "#fff" },
    success: { ...SUCCESS, contrastText: GREY[800] },
    warning: { ...WARNING, contrastText: GREY[800] },
    error: { ...ERROR, contrastText: "#fff" },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    action: {
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      tableHover: "#fbf8ff", //"#f9f9fc",
    },
    sidebar: {
      color: GREY[900],
      background: GREY[900],
    },
  };

  const palette = {
    light: {
      ...COMMON,
      text: {
        primary: GREY[800],
        secondary: GREY[600],
        disabled: GREY[500],
        white: "#fff",
      },
      background: {
        paper: "#fff",
        default: "#fff",
        neutral: GREY[200],
        input: "#f9f9fc",
      },
      action: { active: GREY[600], ...COMMON.action },
    },
    dark: {
      ...COMMON,
      text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
      background: {
        paper: GREY[800],
        default: GREY[900],
        neutral: GREY[500_16],
        input: "#f9f9fc",
      },
      action: { active: GREY[500], ...COMMON.action },
    },
  };

  return palette;
};

export default getPalettes;
