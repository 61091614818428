import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { rootPersistConfig, rootReducer } from "./rootReducer";
import api from "./middleware/api";

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    api,
  ],
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
