// routes
import { PATH_DASHBOARD } from "routes/paths";
// components
import Label from "components/Label";
import SvgIconStyle from "components/SvgIconStyle";
import { useSelector } from "redux/store";
import useAuth from "hooks/useAuth";
import hasIntegration from "utils/hasIntegration";
import "./style.css";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);
const ICONS = {
  subscription: getIcon("ic_subscription"),
  compass: getIcon("ic_compass"),
  list: getIcon("ic_list"),
  thumbsup: getIcon("ic_thumbs-up"),
  slash: getIcon("ic_slash"),
  sliders: getIcon("ic_sliders"),
  bell: getIcon("ic_bell"),
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  settings: getIcon("ic_settings"),
  add: getIcon("ic_add"),
  home: getIcon("ic_home"),
  completed: getIcon("ic_completed"),
  pending: getIcon("ic_pending"),
  users: getIcon("ic_users"),
};

// const sidebarConfig =

const SidebarConfig = () => {
  const { stats } = useSelector((state) => state.leads);
  const { stats: connect } = useSelector((state) => state.requests);
  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  const { currentCompany } = useAuth();

  const roofConnectMenu = [
    {
      title: "Home",
      path: PATH_DASHBOARD.roofconnect,
      icon: ICONS.home,
    },
    {
      title: "+ New Request",
      path: PATH_DASHBOARD.newRequest,
      icon: ICONS.add,
    },
    {
      title: "Pending",
      path: PATH_DASHBOARD.pending,
      icon: ICONS.pending,
      info: (
        <Label
          sx={{
            color: "#ffffff",
            backgroundColor: "#31314b",
            borderRadius: "4px",
          }}
        >
          {connect?.total_conversation_pending?.toLocaleString() ?? 0}
        </Label>
      ),
    },
    {
      title: "Completed",
      path: PATH_DASHBOARD.completed,
      icon: ICONS.completed,
      info: (
        <Label
          sx={{
            color: "#ffffff",
            backgroundColor: "#31314b",
            borderRadius: "4px",
          }}
        >
          {connect?.total_conversation_closed?.toLocaleString() ?? 0}
        </Label>
      ),
    },
    {
      title: "Add User",
      path: PATH_DASHBOARD.users,
      icon: ICONS.users,
    },
    {
      title: "My Account",
      path: options.bool_billing_enabled
        ? PATH_DASHBOARD.profile.root
        : PATH_DASHBOARD.account,
      icon: ICONS.settings,
    },
  ];

  const leadManagerMenu = [
    {
      title: "Dashboard",
      path: PATH_DASHBOARD.leadmanager,
      icon: ICONS.compass,
    },
    {
      title: "Forms",
      path: PATH_DASHBOARD.forms,
      icon: ICONS.list,
    },
    {
      title: "Good",
      path: PATH_DASHBOARD.approved,
      icon: ICONS.thumbsup,
      info: (
        <Label
          sx={{
            color: "#ffffff",
            backgroundColor: "#31314b",
            borderRadius: "4px",
          }}
        >
          {stats?.goodLead_total?.toLocaleString() ?? 0}
        </Label>
      ),
    },
    {
      title: "Spam",
      path: PATH_DASHBOARD.blocked,
      icon: ICONS.slash,
      info: (
        <Label
          sx={{
            color: "#ffffff",
            backgroundColor: "#31314b",
            borderRadius: "4px",
          }}
        >
          {stats?.spamLead_total?.toLocaleString() ?? 0}
        </Label>
      ),
    },
    {
      title: "Filters",
      path: PATH_DASHBOARD.filters,
      icon: ICONS.sliders,
    },
    {
      title: "Setup",
      path: PATH_DASHBOARD.profile.installation,
      icon: ICONS.settings,
    },
    // {
    //   title: "Notifications",
    //   path: PATH_DASHBOARD.notifications,
    //   icon: ICONS.bell,
    // },
    // {
    //   title: "Settings",
    //   path: PATH_DASHBOARD.settings,
    //   icon: ICONS.settings,
    // },
  ];

  let menu = [];

  if (hasIntegration("roof-connect", currentCompany))
    menu = [...roofConnectMenu];

  if (hasIntegration("website-leads", currentCompany))
    menu = [...leadManagerMenu];

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: "",
      items: [...menu],
    },
  ];
};

export default SidebarConfig;
