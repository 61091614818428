import { DownloadRounded } from "@mui/icons-material";
import { Card, Grid, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { MESSAGE_TYPES } from "../../../constants/Realtor.js";
import NoDataFound from "../../../components/general/Table/NoData.js";

const AttachmentsBox = ({ request }) => {
  const list = request?._embedded?.threads;

  const items = list.filter(
    (item) =>
      MESSAGE_TYPES.indexOf(item.type) > -1 &&
      item?._embedded?.attachments.length > 0
  );
  console.log(items);

  return (
    <Card
      style={{
        padding: "15px 20px",
        minHeight: "275px",
        margin: "5px",
        backgroundColor: "#e1e0e357",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Attachments:
      </Typography>

      {items?.length === 0 && (
        <NoDataFound width={200} height={150} margin={3} />
      )}

      {items.map((item, idx) => (
        <Box key={idx}>
          {item?._embedded?.attachments.map((attach, index) => (
            <Grid
              container
              spacing={0}
              key={index}
              sx={{
                "&:hover": { background: "#ffffff" },
                cursor: "pointer",
                padding: "5px 8px 5px 8px",
                borderRadius: "5px",
                marginBottom: "5px",
              }}
            >
              <Grid item md={11} xs={10}>
                <Link
                  href={attach._links.web.href}
                  target="_blank"
                  variant="body2"
                  sx={{ color: "#0A36A3" }}
                >
                  {attach.filename.length < 30
                    ? `${attach.filename}`
                    : `${attach.filename.substring(0, 30)}...`}
                </Link>
              </Grid>
              <Grid item md={1} xs={2}>
                <Link
                  href={attach._links.web.href}
                  target="_blank"
                  variant="body2"
                  sx={{
                    color: "#0A36A3",
                    borderRadius: "50%",
                    backgroundColor: "#E3EDFF",
                    padding: "9px 5px",
                    "&:hover": { background: "#0A36A3", color: "#ffffff" },
                  }}
                >
                  <DownloadRounded
                    style={{
                      marginBottom: "-7px",
                    }}
                  />
                </Link>
              </Grid>
            </Grid>
          ))}
        </Box>
      ))}
    </Card>
  );
};

export default AttachmentsBox;
