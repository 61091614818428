export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        },
      },
    },
  };
}
