// ----------------------------------------------------------------------

// function path(root, sublink) {
//   return `${root}${sublink}`;
// }

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: "/login",
  signup: "/signup",
  signupStep: "/signup?s=1",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verify: "/verify",
  validateCompany: "/validatecompany",
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  roofconnect: "/home",
  leadmanager: "/lead-manager",
  forms: "/forms",
  approved: "/approved",
  blocked: "/blocked",
  filters: "/filters",
  notifications: "/notifications",
  newRequest: "/new-request",
  completed: "/completed",
  pending: "/pending",
  request: "/details",
  settings: "/settings",
  account: "/my-account",
  profile: {
    root: "/profile",
    editprofile: "/profile/edit-profile",
    installation: "/profile/installation",
    billing: {
      root: "/billing",
      myplan: "/profile/my-plan",
      paymentmethod: "/profile/payment-method",
      invoices: "/profile/invoices",
      cancelplan: "/profile/cancel-plan",
      reActivate: "profile/re-activate",
    },
  },
  users: "/users",
  nestedUsers: "/profile/users",
  switchcompany: "/switchcompany",
  invoice: "/invoice",
};
