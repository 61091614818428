// material
import { Container } from "@mui/material";
// routes
// hook

// components
import Page from "components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import SwitchCompanyComponent from "components/_dashboard/switchcompany";

// ----------------------------------------------------------------------

export default function SwitchCompany() {
  return (
    <Page title="Switch Company">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Switch Company" />

        <SwitchCompanyComponent />
      </Container>
    </Page>
  );
}
