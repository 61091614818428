/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Box,
  Stack,
  TextField,
  Link,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// hooks
import useAuth from "../../../../hooks/useAuth";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
//
import { MIconButton } from "../../../@material-extend";
// redux
import { useDispatch, useSelector } from "redux/store";
import { verifyInviteToken } from "redux/slices/company";
import { inputLabelClasses } from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// ----------------------------------------------------------------------

export default function InvitedUserSignUpForm() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { acceptInvitedUser } = useAuth();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { invitedUser } = useSelector((state) => state.company);
  const {
    brand: { name = "", domain = "" },
  } = useSelector((state) => state.brand);

  const RegisterSchema = Yup.object().shape({
    token: Yup.string().required(),
    user_data: Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("First name required"),
      last_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Last name required"),
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
  });

  useEffect(() => {
    if (invitedUser?.user_already_exists) {
      navigate("/login");
    }
  }, [invitedUser]);
  const Paste = (event) => {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };
  const onKeyDown = (event) => {
    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };
  const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-z]+$/;
  const formik = useFormik({
    initialValues: {
      token: token,
      user_data: {
        first_name: "",
        last_name: "",
        email: invitedUser?.email,
        password: "",
        confirmPassword: "",
      },
      privacycheck: true,
      source: domain,
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let { success, message = "" } = await acceptInvitedUser(values);
        if (success) {
          enqueueSnackbar(message, {
            variant: "success",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        } else {
          enqueueSnackbar(message, {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });
  const [realtor, setRealtor] = useState("Yes");
  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } =
    formik;

  useEffect(() => {
    if (token) {
      dispatch(verifyInviteToken(token));
    }
  }, [token]);
  const handleChange = (event) => {
    setRealtor(event.target.value);
  };
  const realtors = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
          <Stack>
            <TextField
              id="outlined-select"
              select
              label="Are You a Realtor"
              value={realtor}
              placeholder="Yes"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "black",
                  [`&.${inputLabelClasses.shrink}`]: {
                    // set the color of the label when shrinked (usually when the TextField is focused)
                    color: "",
                  },
                },
              }}
            >
              {realtors.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              required
              label="First Name "
              onKeyDown={onKeyDown}
              onPaste={Paste}
              placeholder="First Name"
              {...getFieldProps("user_data.first_name")}
              error={Boolean(
                touched.user_data?.first_name && errors.user_data?.first_name
              )}
              helperText={
                touched.user_data?.first_name && errors.user_data?.first_name
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "black",
                  [`&.${inputLabelClasses.shrink}`]: {
                    // set the color of the label when shrinked (usually when the TextField is focused)
                    color: "",
                  },
                },
              }}
            />

            <TextField
              fullWidth
              required
              label="Last Name "
              onKeyDown={onKeyDown}
              onPaste={Paste}
              placeholder="Last Name"
              {...getFieldProps("user_data.last_name")}
              error={Boolean(
                touched.user_data?.last_name && errors.user_data?.last_name
              )}
              helperText={
                touched.user_data?.last_name && errors.user_data?.last_name
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "black",
                  [`&.${inputLabelClasses.shrink}`]: {
                    // set the color of the label when shrinked (usually when the TextField is focused)
                    color: "",
                  },
                },
              }}
            />
          </Stack>

          <TextField
            fullWidth
            required
            autoComplete="username"
            type="email"
            label="Email Address "
            {...getFieldProps("user_data.email")}
            error={Boolean(touched.user_data?.email && errors.user_data?.email)}
            helperText={touched.user_data?.email && errors.user_data?.email}
            InputLabelProps={{
              shrink: true,
              style: { color: "black" },
            }}
            disabled={true}
          />

          <TextField
            fullWidth
            required
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password "
            placeholder="Password"
            {...getFieldProps("user_data.password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            error={Boolean(
              touched.user_data?.password && errors.user_data?.password
            )}
            helperText={
              touched.user_data?.password && errors.user_data?.password
            }
            InputLabelProps={{
              sx: {
                // set the color of the label when not shrinked
                color: "black",
                [`&.${inputLabelClasses.shrink}`]: {
                  // set the color of the label when shrinked (usually when the TextField is focused)
                  color: "",
                },
              },
            }}
          />

          {values?.user_data?.password?.length > 0 ? (
            <TextField
              fullWidth
              required
              autoComplete="current-password"
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              placeholder="Confirm Password"
              {...getFieldProps("user_data.confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              error={Boolean(
                touched.user_data?.confirmPassword &&
                  errors.user_data?.confirmPassword
              )}
              helperText={
                touched.user_data?.confirmPassword &&
                errors.user_data?.confirmPassword
              }
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "black",
                  [`&.${inputLabelClasses.shrink}`]: {
                    // set the color of the label when shrinked (usually when the TextField is focused)
                    color: "",
                  },
                },
              }}
            />
          ) : null}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isSubmitting}
            endIcon={<ArrowForwardIcon />}
          >
            Sign Up
          </LoadingButton>

          <Box display="flex" sx={{ mt: 3, justifyContent: "center" }}>
            <Checkbox
              sx={{ mb: { xs: 6.5, md: 3.5 } }}
              color="default"
              {...getFieldProps("privacycheck")}
              defaultChecked
              required
              error={Boolean(touched.privacycheck && errors.privacycheck)}
              helperText={touched.privacycheck && errors.privacycheck}
            />
            <Box sx={{ alignSelf: "center" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                I agree to {name}&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Privacy Policy
                </Link>
                .
              </Typography>
              <Typography color="red" variant="caption">
                {touched.privacycheck && errors.privacycheck}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
