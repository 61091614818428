const { Chip } = require("@mui/material");

const RequestStatus = ({ status }) => {
  const bgColor = status === "closed" ? "#14B263" : "#FFEC63";
  const textColor = status === "closed" ? "#FFFFFF" : "#000000";
  const st = status === "closed" ? "Completed" : "Pending";
  const padding = status === "closed" ? "0px 2px" : "0px 7px";
  return (
    <Chip
      size="small"
      mr={1}
      sx={{
        borderRadius: "4px",
        backgroundColor: bgColor,
        padding: padding,
        color: textColor,
        fontWeight: "bold",
      }}
      label={st}
      approved={+true}
    />
  );
};

export default RequestStatus;
