/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { useEffect, useState, useRef } from "react";

// material
import { styled } from "@mui/material/styles";
import { Box, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import FuzzySearch from "fuzzy-search";
import useDebounce from "../../../hooks/useDebounce";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "24rem",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  height: "36px",
  borderRadius: "4px",
  "&.Mui-focused": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  "&:hover": {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const Search = ({
  onUpdate,
  list,
  component,
  onRefresh,
  shouldClear = false,
}) => {
  const [filterName, setFilterName] = useState("");
  const debouncedFilterName = useDebounce(filterName, 600);
  const mounted = useRef(false);

  const searcher = new FuzzySearch(list, ["customFields.text", "status"], {
    caseSensitive: false,
  });

  useEffect(() => {
    // avoid calling at the first render
    if (mounted.current) {
      let result = searcher.search(filterName);
      onUpdate(result);
    } else {
      mounted.current = true;
    }
  }, [debouncedFilterName]);

  useEffect(() => {
    setFilterName("");
  }, [shouldClear]);

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
  };

  const handleRefresh = () => {
    setFilterName("");
    onRefresh();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "center", md: "flex-end" },
        mb: 1,
        mt: component === "dashboard" ? { xs: -2, md: -9 } : -1,
      }}
    >
      <Box>
        <IconButton
          onClick={() => handleRefresh()}
          sx={{
            backgroundColor: "transparent",
            color: "#000",
            padding: "2px",
            mt: "5px",
            mr: ".8rem",
            ml: ".6rem",
            "&:hover": { backgroundColor: "#fff", color: "#0A36A3" },
          }}
          size="small"
        >
          <CachedIcon />
        </IconButton>
      </Box>
      <SearchStyle
        mt={2}
        value={filterName}
        sx={{ marginRight: component === "dashboard" ? "0.1rem" : "1rem" }}
        onChange={(e) => handleFilterByName(e.target.value)}
        placeholder="Search for an inspection..."
        size="small"
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: "#271D33" }} />
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default Search;
