import { forwardRef } from "react";
// import { useTheme } from "@mui/material/styles";
import { Avatar } from "@mui/material";

// ----------------------------------------------------------------------

const MAvatar = forwardRef(
  ({ color = "default", children, sx, ...other }, ref) => {
    // const theme = useTheme();

    if (color === "default") {
      return (
        <Avatar ref={ref} sx={sx} {...other}>
          {children}
        </Avatar>
      );
    }

    return (
      <Avatar
        ref={ref}
        sx={{
          color: "#FFFFFF",
          background: "#000",
          ...sx,
        }}
        {...other}
      >
        {children}
      </Avatar>
    );
  }
);

export default MAvatar;
