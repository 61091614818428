import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { TextField, Alert, Stack, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { inputLabelClasses } from "@mui/material/InputLabel";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// redux
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

export default function ForgotPasswordForm({ onSent, onGetEmail }) {
  const { forgotPassword } = useAuth();
  const isMountedRef = useIsMountedRef();

  const {
    brand: { platform_url = "" },
  } = useSelector((state) => state.brand);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      source: platform_url,
    },
    enableReinitialize: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      let response = await forgotPassword(values);
      if (isMountedRef.current && response?.success) {
        onSent();
        onGetEmail(formik.values.email);
        setSubmitting(false);
      } else {
        setErrors({ afterSubmit: response.message });
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            {...getFieldProps("email")}
            type="email"
            label="Email address"
            placeholder="Email address"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              sx: {
                // set the color of the label when not shrinked
                color: "black",
                [`&.${inputLabelClasses.shrink}`]: {
                  // set the color of the label when shrinked (usually when the TextField is focused)
                  color: "",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Forgot Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
