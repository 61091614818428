import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BlackIconButton from "../../components/closeModalButton";
import { IconButton } from "@mui/material";
import { LEAD_STATUS as LEAD_STATUS_CONSTANTS } from "constants/Leads";

import {
  Close as CloseIcon,
  ThumbUpAltOutlined as ThumbUpAltOutlinedIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import styled from "styled-components/macro";
const ConfirmDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
  onAlternate,
}) => {
  const {
    title,
    description,
    descriptionbelow,
    content,
    alert,
    confirmationText,
    cancellationText,
    alternateOptionText,
    titleIcon,
    status,
  } = options;

  const GreenIconButton = styled(IconButton)`
    background: ${(props) => props.theme.palette.success.main};
    color: ${(props) => props.theme.palette.common.white};
    cursor: default;
    &:hover {
      background: ${(props) => props.theme.palette.success.main};
      color: ${(props) => props.theme.palette.common.white};
    }
  `;

  const RedIconButton = styled(IconButton)`
    background: ${(props) => props.theme.palette.error.main};
    color: ${(props) => props.theme.palette.common.white};
    cursor: default;
    &:hover {
      background: ${(props) => props.theme.palette.error.main};
      color: ${(props) => props.theme.palette.common.white};
    }
  `;

  const getIcon = function (titleIcon) {
    switch (titleIcon) {
      case "goodLead":
        return (
          <GreenIconButton size="small" sx={{ mr: 1, p: 0.7, mt: -1 }}>
            <ThumbUpAltOutlinedIcon fontSize="small" />
          </GreenIconButton>
        );
      case "spamLead":
        return (
          <RedIconButton size="small" sx={{ mr: 1, p: 0.7, mt: -1 }}>
            <BlockIcon fontSize="small" />
          </RedIconButton>
        );
      case "deleteLead":
        return (
          <RedIconButton size="small" sx={{ mr: 1, p: 0.7, mt: -1 }}>
            <DeleteIcon fontSize="small" />
          </RedIconButton>
        );
      default:
        return null;
    }
  };

  // console.log("options", options);

  return (
    <Dialog
      fullWidth
      open={open}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      onClose={onClose}
      sx={{ textAlign: "center" }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ fontSize: "30px" }}
      >
        {title && (
          <DialogContent pl={0} mt={1.5} style={{ paddingBottom: "0px" }}>
            <Typography variant="h4" sx={{ paddingTop: "10px" }}>
              {titleIcon && getIcon(titleIcon)}
              {title} {status}
            </Typography>
          </DialogContent>
        )}
        <BlackIconButton
          sx={{ position: "absolute", right: "20px", top: "14px" }}
          size="small"
          onClick={onCancel}
        >
          <CloseIcon fontSize="small" />
        </BlackIconButton>
      </Box>
      {content ? (
        <DialogContent>{content}</DialogContent>
      ) : (
        (description && (
          <DialogContent>
            <Typography
              variant="p"
              sx={{
                pb: 0,
                pt: 2,
                pl: 3,
                pr: 3,
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              {description}
            </Typography>
          </DialogContent>
        )) ||
        (descriptionbelow && (
          <DialogContent sx={{ pt: 1, mt: 1 }}>
            <Typography variant="h6">{descriptionbelow}</Typography>
          </DialogContent>
        ))
      )}
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingTop: "0px !important",
          // flexDirection: "row-reverse",
        }}
      >
        {options.ctaRight && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={onConfirm}
            sx={{ minWidth: 100, ml: 2 }}
            {...options.confirmationButtonProps}
          >
            {confirmationText}
          </LoadingButton>
        )}
        {!options.ctaRight && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={onConfirm}
            sx={{ minWidth: 100, ml: 2 }}
            {...options.confirmationButtonProps}
          >
            {confirmationText}
          </LoadingButton>
        )}{" "}
        {!alert &&
          (status === LEAD_STATUS_CONSTANTS.SENT && alternateOptionText ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={onAlternate}
              sx={{ minWidth: 100 }}
              {...options.cancellationButtonProps}
            >
              {alternateOptionText}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              sx={{ minWidth: 100, ml: 1 }}
              {...options.cancellationButtonProps}
            >
              {cancellationText}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
