// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
// layouts
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// components
import Page from "components/Page";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//
import { LoadingButton } from "@mui/lab";
import CustomizedSteppers from "components/CustomizedSteppers";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Step1Domain({ formik }) {
  const { errors, touched, isSubmitting, getFieldProps } = formik;
  // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
  return (
    <RootStyle title="Step 2">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: "auto" }} textAlign="center">
          <Box sx={{ pb: 12 }}>
            <CustomizedSteppers
              steps={Array(4).join(".").split(".")}
              activeStep={1}
            />
          </Box>

          <Typography variant="h3" color="primary" paragraph>
            Step 2
          </Typography>
          <Typography variant="h6">Great, what is your website?</Typography>
          <Typography variant="body2" sx={{ color: "secondary", mb: 5 }}>
            Quickly connect your website to your account <br /> with our 100%
            safe & secure platform.
          </Typography>

          <TextField
            fullWidth
            required
            variant="outlined"
            label="Web Address"
            placeholder="YourWebsite.com"
            {...getFieldProps("company.domain")}
            error={Boolean(touched.company?.domain && errors.company?.domain)}
            helperText={touched.company?.domain && errors.company?.domain}
            // onKeyDown={(event) => {
            //   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            sx={{ mb: 5 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
              style: {
                textTransform: "lowercase",
              },
            }}
            InputLabelProps={{
              style: { color: "black" },
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ mt: 1 }}
            endIcon={<ArrowForwardIcon />}
            loading={isSubmitting}
          >
            Next
          </LoadingButton>
        </Box>
      </Container>
    </RootStyle>
  );
}
