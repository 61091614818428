import React from "react";
import {
  Box,
  Card,
  Typography as MuiTypography,
  Grid,
  Button,
  Input,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import FileUpload from "../../../components/fileUpload.js";
import { getBase64, isFileTypeSupported } from "../../../utils/commonMethods";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInput-input": {
      background: "#FFF",
    },
  },
}));

const ReplyComponent = ({ handleClose, request, handleReply }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      text: "",
      files: [],
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (!values?.text?.replace(/ /g, "")) {
        return enqueueSnackbar("Please add a message.", {
          variant: "error",
          action: (key) => null,
        });
      }
      let payload = {
        id: request.id,
        attachments: values.files,
        text: values.text,
        status: request.status,
      };
      if (payload.attachments.length > 0 && !payload.text) {
        payload.text = "Attachment Added";
      }
      handleReply(payload);
      resetForm();
    },
  });

  const handleFileDelete = (index) => {
    if (formik.values?.files.length > 0) {
      if (index > -1) {
        let currentFiles = [...formik.values.files];
        currentFiles.splice(index, 1);
        formik.setFieldValue("files", currentFiles);
      }
    }
  };

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Card
      sx={{
        padding: { xs: "20px 10px 18px 10px", md: "22px 30px 18px 30px" },
        minHeight: "240px",
        backgroundColor: "#0A36A3",
        color: "#FFFFFF",
        cursor: "default",
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MuiTypography
                variant="subtitle2"
                mb={1}
                sx={{ fontSize: "14px" }}
                fontWeight="fontWeightMedium"
              >
                Reply
              </MuiTypography>
              <Grid
                sx={{
                  backgroundColor: "#FFF",
                  pb: 1,
                  pl: { xs: 0.5, md: 1.5 },
                  pr: 1,
                  pt: 1.5,
                  borderRadius: "7px",
                }}
              >
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.root,
                    sx: { padding: "0px" },
                  }}
                  sx={{
                    width: "100%",
                    pl: 1,
                    borderRadius: "7px 7px 0px 0px !important",
                  }}
                  placeholder="Write your message here"
                  multiline
                  {...getFieldProps("text")}
                  rows={5}
                  maxRows={6}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    paddingTop: "0px !important",
                  }}
                >
                  {formik.values?.files.map((file, index) => (
                    <FileUpload
                      key={index}
                      index={index}
                      file={file}
                      handleRemove={handleFileDelete}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={1.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "space-between", xs: "center" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#fff",
                        color: "#0A36A3",
                        minWidth: "120px",
                        "&:hover": { backgroundColor: "#fff !important" },
                      }}
                    >
                      Send Message
                    </Button>
                  </Box>
                  <Box>
                    <label htmlFor="contained-button-file">
                      <Input
                        sx={{ display: "none" }}
                        accept="image/*,video/*,application/pdf"
                        id="contained-button-file"
                        onClick={(event) => (event.target.value = null)}
                        onChange={async (event) => {
                          const files = event.target.files;
                          if (files.length > 0) {
                            try {
                              const currentFile = files[0];
                              console.log("currentFile", currentFile);
                              if (
                                !isFileTypeSupported(
                                  currentFile.type || currentFile.mimeType
                                )
                              ) {
                                return enqueueSnackbar(
                                  "Please choose an image, document/spreadsheet or a text file",
                                  {
                                    variant: "error",
                                    action: (key) => null,
                                  }
                                );
                              }
                              console.log("moving on after");
                              let myFiles = [
                                {
                                  size: currentFile.size,
                                  fileName: currentFile.name,
                                  mimeType: currentFile.type,
                                  data: await getBase64(currentFile),
                                },
                              ];
                              console.log("myFiles", myFiles);
                              let currentFiles = [
                                ...formik.values.files,
                                ...myFiles,
                              ];
                              formik.setFieldValue("files", currentFiles);
                            } catch (err) {
                              console.log("err", err);
                            }
                          }
                        }}
                        multiple
                        type="file"
                      />
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          color: "#fff",
                          borderColor: "#fff",
                          ml: 1,
                          "&:hover": { borderColor: "#fff" },
                        }}
                      >
                        Upload File
                      </Button>
                    </label>
                  </Box>
                </Box>
                {request.status !== "closed" && (
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      {/* <Button
                        variant="text"
                        sx={{
                          color: "#fff",
                          textDecoration: "underline",
                          display: { xs: "none", sm: "none", md: "inline" },
                        }}
                        onClick={handleClose}
                        disabled={request.status === "closed"}
                      >
                        Close request
                      </Button> */}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default ReplyComponent;
