export function capitalizeFirst(str) {
  if (!str) return str;
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function capitalizeFirstLowercaseRest(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalizeFirstLowercaseRestSentence(str) {
  if (!str) return str;
  let string = "";
  str.split(" ").forEach((word, index) => {
    if (index > 0) {
      string = string + " " + capitalizeFirstLowercaseRest(word);
    } else {
      string = string + capitalizeFirstLowercaseRest(word);
    }
  });
  return string;
}

export function capitalizeFirstWholeSentence(str) {
  if (!str) return str;
  let string = "";
  str.split(" ").forEach((word, index) => {
    if (index > 0) {
      string = string + " " + capitalizeFirst(word);
    } else {
      string = string + capitalizeFirst(word);
    }
  });
  return string;
}

export function capitalizeFirstLowercaseRestAddress(str) {
  if (!str) return str;
  let string = "";
  let list = str.split(" ");
  list.forEach((word, index) => {
    if (index > 0) {
      if (index === list.length - 1 && word.length === 2) {
        string = string + " " + word.toUpperCase();
      } else {
        string = string + " " + capitalizeFirstLowercaseRest(word);
      }
    } else {
      string = string + capitalizeFirstLowercaseRest(word);
    }
  });
  return string;
}

export function formatPhone(p) {
  if (!p) return p;
  let cleaned = ("" + p).replace(/\D/g, "");
  if (cleaned && cleaned.length > 10) {
    cleaned = cleaned.substring(0, 10);
  }
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "N/A";
}
