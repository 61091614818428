import MenuRounded from "@mui/icons-material/MenuRounded";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Link,
  Avatar,
} from "@mui/material";

import RoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import useBrand from "hooks/useBrand";
// components
import { MHidden } from "../../components/@material-extend";
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    boxShadow: "none",
  },
}));

const MenuIconStyle = {
  mr: 1,
  border: "1px solid #B3B3B3",
  width: "30px",
  height: "30px",
  backgroundColor: "white",
  color: "#B3B3B3",
  cursor: "pointer",
};

const MenuIconStyleHamburger = {
  "&:hover": {
    backgroundColor: "#EFEFEF",
    color: "black",
    cursor: "pointer",
  },
  mr: 1,
  color: "black",
  borderRadius: "50%",
  backgroundColor: "white",
};

const TransFrom = {
  mr: 1,
  border: "1px solid #B3B3B3",
  width: "30px",
  height: "30px",
  backgroundColor: "white",
  color: "#B3B3B3",
  cursor: "pointer",
  transform: "scale(-1.0) !important",
};

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse, onToggleCollapse } = useCollapseDrawer();
  const brand = useBrand();
  return (
    <RootStyle
      sx={{
        position: {
          md: "fixed",
          lg: "absolute",
        },
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        {brand?.options?.bool_billing_enabled ? (
          <>
            <MHidden width="lgUp">
              <IconButton onClick={onOpenSidebar} sx={MenuIconStyleHamburger}>
                <MenuRounded />
              </IconButton>
            </MHidden>
            <MHidden width="lgDown">
              <IconButton
                onClick={onToggleCollapse}
                sx={MenuIconStyleHamburger}
              >
                <MenuRounded sx={{ transform: "scale(1.4)" }} />
              </IconButton>
            </MHidden>
          </>
        ) : (
          <>
            <MHidden width="lgUp">
              <IconButton
                onClick={onOpenSidebar}
                sx={isCollapse ? TransFrom : MenuIconStyle}
              >
                <MenuRounded />
              </IconButton>
            </MHidden>
            <MHidden width="lgDown">
              <Avatar sx={isCollapse ? TransFrom : MenuIconStyle}>
                <RoundedIcon onClick={onToggleCollapse}>
                  <MenuRounded />
                </RoundedIcon>
              </Avatar>
            </MHidden>
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <MHidden width="mdDown">
          {brand?.content?.roofer_phone_number && (
            <Box sx={{ mr: 2, pt: 1.5, pb: 1, px: 1 }}>
              <Typography sx={{ color: "#000000", opacity: "0.5" }}>
                Need Help?&nbsp;
                <Link
                  href={`tel:${brand?.content?.roofer_phone_number}`}
                  sx={{ color: "black" }}
                >
                  {brand?.content?.roofer_phone_number}
                </Link>
              </Typography>
            </Box>
          )}
        </MHidden>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ pr: 1 }}
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
