import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Container, Typography } from "@mui/material";
// layouts
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// routes
import { PATH_AUTH } from "routes/paths";
// components
import Page from "components/Page";
import ResetPasswordForm from "components/auth/ResetPassword";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const navigate = useNavigate();
  const { userId, token } = useParams();
  if (!userId || !token) navigate(PATH_AUTH.login);

  return (
    <RootStyle title="Reset Password">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: "auto" }}>
          <Typography variant="h3" paragraph>
            Reset Password
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Please enter the new password for your account.
          </Typography>

          <ResetPasswordForm userId={userId} token={token} />

          <Button
            fullWidth
            size="large"
            component={RouterLink}
            to={PATH_AUTH.login}
            sx={{ mt: 1, textTransform: "none" }}
          >
            Back to Login Screen
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
