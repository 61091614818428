import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// slices
import counterReducer from "./slices/counter";
import leadsReducer from "./slices/leads";
import formsReducer from "./slices/forms";
import myprofileReducer from "./slices/myprofile";
import companyReducer from "./slices/company";
import blacklistedFiltersReducer from "./slices/blacklisted_filters";
import userManagementReducer from "./slices/user_management";
import billingReducer from "./slices/billing";
import brandReducer from "./slices/brand";
import requestsReducer from "./slices/requests";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const brandPersistConfig = {
  key: "brand",
  storage,
  keyPrefix: "redux-",
  whitelist: ["brand"],
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  counter: counterReducer,
  leads: leadsReducer,
  forms: formsReducer,
  myprofile: myprofileReducer,
  company: companyReducer,
  blacklisted_filters: blacklistedFiltersReducer,
  user_management: userManagementReducer,
  billing: billingReducer,
  requests: requestsReducer,
  brand: persistReducer(brandPersistConfig, brandReducer),
});

export { rootPersistConfig, rootReducer };
