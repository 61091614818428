import React, { useEffect, useState } from "react";
import { Card, Container, Typography, Box } from "@mui/material";
import Page from "components/Page";
import useAuth from "hooks/useAuth";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import LoadingScreen from "components/LoadingScreen";

const HeyFlow = ({ name, helpScotCustomerId, customerId }) => {
  const url = `https://heyflow.id/_new-request-_michael_?name=${name}&userId=${helpScotCustomerId}&customerId=${customerId}`;
  const myForm = (
    <iframe
      title="add-new-request"
      width="100%"
      height="1000"
      id="new_request"
      frameBorder="0"
      src={url}
    ></iframe>
  );
  return myForm;
};

const AddRequestLayout = () => {
  const [loading, setLoading] = useState(true);
  const { user, currentCompany } = useAuth();
  useEffect(() => {
    document.getElementById("new_request")?.addEventListener("load", () => {
      setLoading(false);
    });
  }, []);

  return (
    <Page title="Request Roof Inspection">
      <Container maxWidth={false} sx={{ padding: 0, mt: { xs: 2, md: 0 } }}>
        <Box sx={{ display: "flex" }}>
          <HeaderBreadcrumbs
            heading={"New Request"}
            headingIcon={
              <AddCircleOutline
                style={{
                  margin: "8px 8px",
                  color: "#0A36A3",
                }}
              />
            }
          />

          <Typography
            variant="h5"
            style={{
              marginBottom: "10px",
              marginTop: "5px",
              textShadow: "1.3px 0 black",
              lineHeight: "32px",
            }}
            ml={1.5}
          ></Typography>
        </Box>
        <Card style={{ padding: "15px" }}>
          {loading && (
            <Box sx={{ display: "block", marginTop: "15% !important" }}>
              <LoadingScreen />
            </Box>
          )}
          <HeyFlow
            name={user.first_name}
            helpScotCustomerId={currentCompany.helpScotCustomerId}
            customerId={user?._id?.toString()}
          />
        </Card>
      </Container>
    </Page>
  );
};
export default AddRequestLayout;
