import { stringify } from "qs";
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/integrations/leads/forms";

const slice = createSlice({
  name: "forms",
  initialState: {
    page: 1,
    per_page: 20,
    total_pages: 0,
    total_records: 0,
    list: [],
    all: [],
    form: null,
    stats: null,
    chart: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 1;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.list = [];
      state.all = [];
      state.form = null;
    },
    resetRequestState(state, action) {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    updateState(state, action) {
      state = { ...state, ...action.payload };
    },
    getForms(state, action) {
      state.isLoading = false;
      state.page = action.payload?.page;
      state.per_page = action.payload?.per_page;
      state.total_pages = action.payload?.total_pages;
      state.total_records = action.payload?.total_records;
      state.list = action.payload?.data;
    },
    getOneForm(state, action) {
      state.isLoading = false;
      state.form = action.payload;
    },
    updateLocalFormState(state, action) {
      let list = [];
      state.list?.forEach((form) => {
        let item = { ...form };
        if (item._id === action.payload.id) {
          item[action.payload.field] = action.payload.value;
          list.push(item);
        } else {
          list.push(item);
        }
      });
      state.list = list;
    },
    updateFormState(state, action) {
      state.isLoading = false;
      let list = [...state.list] ?? [];
      list[
        list
          .map((x, i) => [i, x])
          .filter((x) => x?.[1]?._id === action?.payload?._id)?.[0]?.[0]
      ] = action?.payload;
      console.log("list: ", list);
      state.list = list;
      // state.success = { message: "Successfully Updated" };
    },
    addForm(state, action) {
      let list = state.list ?? [];
      list.push(action.payload);
      state.list = list;
      state.total_records = state.total_records + 1;
      state.isLoading = false;
      state.success = { message: "Form added successfully" };
    },
    RecoverForm(state, action) {
      let list = state.list ?? [];
      list = list.filter((item) => item._id !== action.payload?._id);
      state.list = list;
      state.isLoading = false;
      state.success = { message: "Successfully Updated" };
    },
    archive(state, action) {
      let list = state.list ?? [];
      list = list.filter((item) => item._id !== action.payload?._id);
      state.list = list;
      state.isLoading = false;
      state.success = { message: "Form Archived Successfully" };
    },
    pageChangeBackward(state, action) {
      if (state.page > 0) state.page = state.page - 1;
    },
    pageChangeForward(state, action) {
      state.page = state.page + 1;
    },
  },
});

export const {
  resetAll,
  resetRequestState,
  requestStarted,
  requestedFailed,
  stateLoaded,
  updateState,
  getForms,
  getOneForm,
  addForm,
  updateLocalFormState,
  updateFormState,
  RecoverForm,
  archive,
  pageChangeBackward,
  pageChangeForward,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const getFormsList = (data, goTo) => {
  return apiCallBegan({
    url: url + `/?${stringify(data)}`,
    onStart: requestStarted.type,
    onSuccess: getForms.type,
    onError: requestedFailed.type,
  });
};

export const getForm = (formId) => {
  return apiCallBegan({
    url: url + `/${formId}`,
    onStart: requestStarted.type,
    onSuccess: getOneForm.type,
    onError: requestedFailed.type,
  });
};

export const updateForm = (formId, data) =>
  apiCallBegan({
    url: url + `/${formId}`,
    method: "put",
    data,
    // onStart: requestStarted.type,
    onSuccess: updateFormState.type,
    onError: requestedFailed.type,
  });

export const archiveForm = (formId) =>
  apiCallBegan({
    url: url + `/${formId}/archive`,
    method: "put",
    // onStart: requestStarted.type,
    onSuccess: archive.type,
    onError: requestedFailed.type,
  });

export const recoverForm = (formId) =>
  apiCallBegan({
    url: url + `/${formId}/recover`,
    method: "put",
    // onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const addNewForm = (data) =>
  apiCallBegan({
    url: url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: addForm.type,
    onError: requestedFailed.type,
  });
