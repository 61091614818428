import styled from "styled-components/macro";
import { IconButton } from "@mui/material";

const BlackIconButton = styled(IconButton)`
  background: ${(props) => props.theme.palette.common.white};
  color: ${(props) => props.theme.palette.common.black};
  &:hover {
    background: ${(props) => props.theme.palette.grey[300]};
    color: ${(props) => props.theme.palette.common.black};
  }
  &:active {
    background: ${(props) => props.theme.palette.grey[300]};
    color: ${(props) => props.theme.palette.common.black};
  }
`;

export default BlackIconButton;
