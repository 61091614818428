/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useSelector } from "redux/store";
import DoneIcon from "@mui/icons-material/Done";

import Page from "components/Page";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function HighlightBox() {
  const {
    brand: { images },
  } = useSelector((state) => state.brand);

  const extrasm = useMediaQuery("(max-width: 1450px)");
  const labelStyle = {
    fontSize: extrasm ? ".7rem" : ".8rem",
    mt: 0.6,
    fontWeight: "bold",
  };

  const highlightsx = [
    "Try for Free",
    "Works on Any Website",
    "Blocks 99% of Spam",
    "Lead Manager Dashboard",
    "Set Your Own Filters",
  ];
  const highlightsy = [
    "Control Notifications",
    "Filter out Spam Submissions Automatically",
    "Easy Setup - No Technical Skills Required!",
  ];
  return (
    <RootStyle title="">
      <Box sx={{ padding: "3.8vh 17%", color: "white" }}>
        <RouterLink to="/">
          <img src={images?.white_full_png} alt="logo" height={50} />
        </RouterLink>
        {/* <Typography
          variant="h4"
          sx={{
            fontSize: "2rem !important",
            color: "#fff",
            mt: 4,
            mb: 1,
            lineHeight: "38px",
            textShadow: ".4px 0 #fff",
          }}
        >
          Say Goodbye to <br /> Website Spam Forever
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: ".9rem !important",
            color: "#fff",
          }}
        >
          Spend more time on real customers by cutting out spam for good.
        </Typography> */}
        {/* <Box
          sx={{ mt: 2, ml: 1, display: "flex", justifyContent: "flex-start" }}
        >
          <Box>
            {highlightsx.map((item, index) => (
              <Box
                sx={{ display: "flex", justifyContent: "flex-start" }}
                key={index}
              >
                <DoneIcon sx={{ color: "#23CC94", paddingTop: "5px" }} />
                <Typography variant="body1" sx={labelStyle} component="p">
                  &nbsp; {item}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ ml: 5 }}>
            {highlightsy.map((item, index) => (
              <Box
                sx={{ display: "flex", justifyContent: "flex-start" }}
                key={index}
              >
                <DoneIcon sx={{ color: "#23CC94", paddingTop: "5px" }} />
                <Typography variant="body1" sx={labelStyle} component="p">
                  &nbsp; {item}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box> */}
      </Box>
    </RootStyle>
  );
}
