import React, { useState } from "react";
// material

// components
import Step2 from "./Step2";
import Step3DoMyself from "./Step3DoMyself";
import Step3WebGuy from "./Step3WebGuy";
import Step3HireUs from "./Step3HireUs";
import { useNavigate } from "react-router-dom";
// hooks

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SignUp() {
  let [step, setStep] = useState(0);
  let handleNextStep = () => {
    setStep(step + 1);
  };

  let handlePreviousStep = (value) => {
    if (value || value === 0) {
      setStep(value);
    } else {
      setStep(step - 1);
    }
  };

  const navigate = useNavigate();
  let skipForNow = () => {
    navigate("/profile/payment-method", { replace: true });
    setStep(0);
  };

  let handleInstallType = (type) => {
    if (type === "DoItMyself") {
      setStep(1);
    } else if (type === "SendToWebsiteGuy") {
      setStep(2);
    } else if (type === "HireUs") {
      setStep(3);
    }
  };

  return (
    <React.Fragment>
      {step === 0 ? (
        <Step2
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          handleInstallType={handleInstallType}
          skipForNow={skipForNow}
        />
      ) : null}

      {step === 1 ? (
        <Step3DoMyself
          handleNextStep={handleNextStep}
          skipForNow={skipForNow}
          handlePreviousStep={handlePreviousStep}
        />
      ) : null}

      {step === 2 ? (
        <Step3WebGuy
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          skipForNow={skipForNow}
        />
      ) : null}

      {step === 3 ? (
        <Step3HireUs
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          skipForNow={skipForNow}
        />
      ) : null}
    </React.Fragment>
  );
}
