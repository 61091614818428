import "react-app-polyfill/stable";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// scroll bar
import "simplebar/src/simplebar.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

// material ui
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import { StyledEngineProvider } from "@mui/styled-engine-sc";

// contexts
import { AuthProvider } from "contexts/JWTContext";
import { CollapseDrawerProvider } from "contexts/CollapseDrawerContext";
// redux
import { store, persistor } from "redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { create } from "jss";

import { ThemeProvider } from "contexts/ThemeContext";
import LoadingScreen from "components/LoadingScreen";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    attachStacktrace: true,
    dsn: "https://a57a61730b5a46feb7bb93e5920873d2@o1156580.ingest.sentry.io/6241507",
    beforeSend(event) {
      return event;
    },
    integrations: [
      new BrowserTracing({
        beforeNavigate: (context) => ({
          ...context,
          name: window.location.pathname,
        }),
      }),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <HelmetProvider>
            <StylesProvider jss={jss}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledEngineProvider injectFirst>
                  <CollapseDrawerProvider>
                    <AuthProvider>
                      <App />
                    </AuthProvider>
                  </CollapseDrawerProvider>
                </StyledEngineProvider>
              </LocalizationProvider>
            </StylesProvider>
          </HelmetProvider>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
