/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { Paper } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import Footer from "components/Footer";
// redix
import { getLeadsStats } from "redux/slices/leads";
import { useDispatch } from "redux/store";
import { getRequestsList } from "redux/slices/requests";
import useAuth from "hooks/useAuth";
import hasIntegration from "utils/hasIntegration";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const AppContent = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.paper};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { currentCompany } = useAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    if (hasIntegration("roof-connect", currentCompany)) {
      // dispatch(getRequestsStats());
      dispatch(getRequestsList());
    }
    if (hasIntegration("website-leads", currentCompany)) {
      dispatch(getLeadsStats());
    }
  }, [dispatch]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <AppContent
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: "102px",
          }),
        }}
      >
        <MainContent>
          <Outlet />
        </MainContent>

        <Footer />
      </AppContent>
    </RootStyle>
  );
}
