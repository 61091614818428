import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import {
  Box,
  IconButton,
  Button,
  Card,
  Container,
  Grid,
  CardContent,
  Typography,
  Chip,
} from "@mui/material";
import BlackIconButton from "../../../components/closeModalButton";
import CloseIcon from "@mui/icons-material/Close";

import { capitalizeFirstWholeSentence } from "../../../utils/formatStrings";
import Page from "components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "utils/getUserData";
import {
  getRequest,
  closeRequest,
  replyToRequest,
  refreshRequestsList,
} from "redux/slices/requests";
import LoadingScreen from "components/LoadingScreen";
import RequestDetails from "./RequestDetails";
import ReplyComponent from "./replyComponent";
import { useConfirm } from "hooks/useConfirm";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import moment from "moment";
import { useSnackbar } from "notistack";
import Conversation from "./Conversation.js";
import AttachmentsBox from "./AttachmentsBox";
import { useNavigate } from "react-router-dom";
const getJobType = (customFields) => {
  const jobType = customFields.find((x) => x.name === "Propriety");
  return jobType ? jobType.value : "-";
};

const RequestStatus = ({ status }) => {
  const st = status === "closed" ? "Completed" : "Pending";

  const bgColor = status === "closed" ? "#14B263" : "#FFEC63";
  const textColor = status === "closed" ? "#FFFFFF" : "#000000";
  const padding = status === "closed" ? "0px 2px" : "0px 7px";

  return (
    <Chip
      size="small"
      sx={{
        borderRadius: "4px",
        backgroundColor: bgColor,
        color: textColor,
        fontWeight: "bold",
        padding: padding,
      }}
      mr={1}
      label={st}
      approved={+true}
    />
  );
};

function JobInfo({ user, item }) {
  return (
    <Card style={{ backgroundColor: "#e1e0e357", height: "90%" }}>
      <CardContent>
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Job Info:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} md={4}>
              <Typography variant="h6" sx={{ fontSize: "0.95rem !important" }}>
                Job
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography vairant="body2" sx={{ alignSelf: "end" }}>
                {getJobType(item?.customFields)}
              </Typography>
            </Grid>

            <Grid item xs={6} md={4}>
              <Typography variant="h6" sx={{ fontSize: "0.95rem !important" }}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Box
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1rem !important",
                }}
              >
                <RequestStatus status={item?.status} />
              </Box>
            </Grid>

            <Grid item xs={6} md={4}>
              <Typography variant="h6" sx={{ fontSize: "0.95rem !important" }}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography vairant="body2" sx={{ alignSelf: "end" }}>
                {moment(item?.createdAt).format("MM.DD.YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

function Conversations({ request, convoRef }) {
  return (
    <Box mt={4} ref={convoRef} style={{ maxWidth: "960px" }}>
      <Typography variant="h6" mb={0.5} pl={1} fontSize="medium">
        Conversation History:
      </Typography>
      <Card
        sx={{
          border: "1px solid #0A36A3",
          padding: { xs: "7px 4px 15px 10px", md: "7px 20px 15px 30px" },
          minHeight: "300px",
          backgroundColor: "#e1e0e357",
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Box>
            <Conversation request={request} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

function Request({
  request,
  handleClose,
  handleReply,
  convoRef,
  toNextRequest,
  toPreviousRequest,
  Navigation,
}) {
  const user = getUserData(request?.customFields);
  return (
    <Page title="Roof Inspection Details">
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <HeaderBreadcrumbs
          style={{ marginBottom: "10px !important" }}
          heading={`Inspection Details`}
          headingIcon={
            <RemoveRedEyeIcon
              fontSize="medium"
              style={{ margin: "8px 8px" }}
              sx={{
                color: "#0A36A3",
                background: "#F5FBFF",
              }}
            />
          }
          action={
            request?.status !== "closed" ? (
              <Button
                variant="contained"
                style={{ backgroundColor: "#14B263", textTransform: "none" }}
                onClick={handleClose}
              >
                Tap to Mark as Complete
              </Button>
            ) : null
          }
        />

        <Card
          sx={{
            padding: "15px 20px 30px 20px",
            "@media (max-width: 428px)": {
              padding: "20px 7px 30px 7px",
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 0.5,
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      fontWeight: "bold",
                      textShadow: ".2px 0 #000",
                    }}
                  >
                    {capitalizeFirstWholeSentence(user?.name)}, ID &nbsp;#
                    {request?.number}
                  </Typography>
                </Box>
                <Box>
                  <Navigation
                    toPreviousRequest={toPreviousRequest}
                    toNextRequest={toNextRequest}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <RequestDetails item={request} />{" "}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <JobInfo item={request} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <ReplyComponent
                request={request}
                handleClose={handleClose}
                handleReply={handleReply}
              />
              <Conversations request={request} convoRef={convoRef} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <AttachmentsBox request={request} />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const ViewRequestLayout = () => {
  const dispatch = useDispatch();
  const { selectedRequest, list } = useSelector((state) => state.requests);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setloading] = useState(false);
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const convoRef = useRef(null);

  // Get ID from URL
  const { requestId } = useParams();
  const handleCloseModal = () => {
    confirm({
      title: "Are you sure you want to mark this inspection as complete?",
      // description: "(This will close the request on our end)",
      descriptionbelow: "(This will close the request on our end)",
      confirmationText: "Yes, It's done",
      cancellationText: "Cancel",
      ctaRight: true,
    })
      .then(async (res) => {
        await dispatch(closeRequest(requestId));
        enqueueSnackbar("Inspection Request Closed", {
          variant: "success",
        });
        await dispatch(refreshRequestsList());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollToConversation = () => {
    if (convoRef?.current) {
      convoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleReply = async (data) => {
    try {
      await dispatch(replyToRequest(data));
      enqueueSnackbar("Message Sent Successfully!", {
        variant: "success",
        autoHideDuration: 30000,
        action: (key) => (
          <BlackIconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </BlackIconButton>
        ),
      });
      scrollToConversation();
      if (data?.status?.toLowerCase() === "closed")
        dispatch(refreshRequestsList());
    } catch (err) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const toNextRequest = (data) => {
    try {
      if (list?.length === 1) {
        return null;
      }
      let foundIndex = list.findIndex(
        (request) => request.id === selectedRequest.id
      );
      if (foundIndex > -1) {
        setloading(true);
        if (foundIndex + 1 < list.length) {
          navigate("/details/" + list[foundIndex + 1].id);
        } else {
          navigate("/details/" + list[0].id);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const StyledIconButton = styled(IconButton)`
    background: ${(props) => props.theme.palette.background.paper};
    color: #b3b3b3;
    padding: 0px;
  `;

  const Navigation = ({ toNextRequest, toPreviousRequest }) => {
    return list.length > 1 ? (
      <Box>
        <StyledIconButton sx={{ mr: 1.5 }} onClick={toPreviousRequest}>
          <ArrowCircleLeftOutlinedIcon style={{ fontSize: 42 }} />
        </StyledIconButton>
        <StyledIconButton onClick={toNextRequest}>
          <ArrowCircleRightOutlinedIcon style={{ fontSize: 42 }} />
        </StyledIconButton>
      </Box>
    ) : null;
  };

  const toPreviousRequest = (data) => {
    try {
      if (list?.length === 1) {
        return null;
      }
      let foundIndex = list.findIndex(
        (request) => request.id === selectedRequest.id
      );
      if (foundIndex > -1) {
        setloading(true);
        if (foundIndex === 0) {
          navigate("/details/" + list[list.length - 1].id);
        } else {
          navigate("/details/" + list[foundIndex - 1].id);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (selectedRequest?.id === requestId) {
        await setSelectedItem(selectedRequest);
      } else {
        await dispatch(getRequest(requestId));
        if (!selectedRequest) {
          enqueueSnackbar(`No Inspection found with ID: ${requestId}`, {
            variant: "error",
          });
          navigate("/home");
        }
      }
      setloading(false);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, requestId]);

  return selectedRequest && !loading ? (
    <Request
      request={selectedRequest}
      handleClose={handleCloseModal}
      handleReply={handleReply}
      convoRef={convoRef}
      toPreviousRequest={toPreviousRequest}
      toNextRequest={toNextRequest}
      Navigation={Navigation}
    />
  ) : (
    <LoadingScreen />
  );
};

export default ViewRequestLayout;
