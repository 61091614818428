// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import createAvatar from "../utils/createAvatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ customFirstLetter, ...other }) {
  const { user } = useAuth();
  return (
    <MAvatar
      color="custom"
      src={user?.photoURL}
      alt={user?.first_name}
      {...other}
    >
      {customFirstLetter || other.customFirstLetter
        ? customFirstLetter || other.customFirstLetter
        : createAvatar(user?.first_name).name}
      {/* {createAvatar(user?.first_name).name} */}
    </MAvatar>
  );
}
