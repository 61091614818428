/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import styled from "styled-components/macro";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Button,
  Box,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
// redux
import { useDispatch, useSelector } from "redux/store";
import { installationWebGuy, resetAllState } from "redux/slices/company";
// hooks
import useAuth from "hooks/useAuth";
import { useNavigate, useMatch } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/paths";

export default function WebGuy({ handlePreviousStep, skipForNow }) {
  let { currentCompany } = useAuth();
  let match = useMatch("/profile/installation");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, success, error } = useSelector((state) => state.company);
  const {
    brand: { name = "" },
  } = useSelector((state) => state.brand);

  const WebGuyFormSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    subject: Yup.string().strict(false).trim().required("Subject is required"),
    message: Yup.string().strict(false).trim().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      subject: `Please install code snippet / Header / ${currentCompany?.domain} / ${name}`,
      message: `Hello,\n\nWe recently signed up with a new service to manage our website spam. They require us to install the following code snippet to link our website with their platform. Can you please handle this.? \n\nDomain: ${currentCompany?.domain}`,
    },
    validationSchema: WebGuyFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsError(false);
      dispatch(installationWebGuy(values));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  let [isSuccess, setIsSuccess] = useState(false);
  let [isError, setIsError] = useState(false);

  useEffect(() => {
    if (success) {
      setIsSuccess(true);
      enqueueSnackbar(success?.message ?? "Successfully Sent", {
        variant: "success",
      });
      dispatch(resetAllState());
      if (!currentCompany.validation_date) {
        setTimeout(() => {
          navigate(PATH_DASHBOARD.profile.billing.paymentmethod);
        }, 3000);
      }
    }
  }, [success]);
  useEffect(() => {
    if (error) {
      setIsError(true);
      dispatch(resetAllState());
    }
  }, [error]);

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box textAlign="center" sx={{ mb: 4 }}>
            <Typography variant="h4">You are almost done!</Typography>
            <Typography variant="body2">
              Send instructions to your website guy.
            </Typography>
          </Box>
          <Box textAlign={"start"}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Send to (email1)"
                placeholder="yourdeveloper@email.com"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={
                  touched.email && errors.email
                    ? "Please provide a valid email address"
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />

              <TextField
                fullWidth
                type={"text"}
                label="Subject"
                placeholder={"subject"}
                {...getFieldProps("subject")}
                error={Boolean(touched.subject && errors.subject)}
                helperText={touched.subject && errors.subject}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Message"
                placeholder={"message"}
                multiline
                rows={7}
                {...getFieldProps("message")}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />

              <Box>
                <Typography variant="subtitle2">Your Code Snippet</Typography>
                <Card
                  sx={{
                    bgcolor: "#f7f7f9",
                    p: 3,
                    borderRadius: 0,
                    borderWidth: 8,
                  }}
                >
                  <Typography variant="caption">
                    {currentCompany?.script}
                  </Typography>
                </Card>
              </Box>
            </Stack>
          </Box>

          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 5, backgroundColor: "#5D1FE0" }}
            type="submit"
            disabled={isLoading}
            endIcon={
              isLoading ? (
                <CircularProgress size={18} color="primary" />
              ) : isError ? (
                <SentimentVeryDissatisfiedIcon />
              ) : isSuccess ? (
                <CheckCircleIcon />
              ) : (
                <ArrowForwardIcon />
              )
            }
          >
            {isError
              ? "Unable to Send Email"
              : isSuccess
              ? "Email Sent"
              : "Send Email"}
          </Button>
        </Form>
      </FormikProvider>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <Button
          variant="text"
          sx={{ color: "black" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => handlePreviousStep(0)}
        >
          previous
        </Button>
        {!match && (
          <Button
            variant="text"
            sx={{ color: "black" }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => skipForNow()}
          >
            skip for now
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
