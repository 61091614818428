/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Box,
  Stack,
  TextField,
  Link,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// hooks
import useAuth from "../../../../hooks/useAuth";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
//
import { MIconButton } from "../../../@material-extend";
// redux
import { useDispatch, useSelector } from "redux/store";
import { verifyInviteToken } from "redux/slices/company";
// ----------------------------------------------------------------------

export default function InvitedUserSignUpForm() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { acceptInvitedUser } = useAuth();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { invitedUser } = useSelector((state) => state.company);
  const {
    brand: { name = "", domain = "" },
  } = useSelector((state) => state.brand);

  const RegisterSchema = Yup.object().shape({
    token: Yup.string().required(),
    user_data: Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("First name required"),
      last_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Last name required"),
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
  });

  useEffect(() => {
    if (invitedUser?.user_already_exists) {
      navigate("/login");
    }
  }, [invitedUser]);

  const formik = useFormik({
    initialValues: {
      token: token,
      user_data: {
        first_name: "",
        last_name: "",
        email: invitedUser?.email,
        password: "",
        confirmPassword: "",
      },
      privacyCheck: true,
      source: domain,
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let { success, message = "" } = await acceptInvitedUser(values);
        if (success) {
          enqueueSnackbar(message, {
            variant: "success",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        } else {
          enqueueSnackbar(message, {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } =
    formik;

  useEffect(() => {
    if (token) {
      dispatch(verifyInviteToken(token));
    }
  }, [token]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("user_data.first_name")}
              error={Boolean(
                touched.user_data?.first_name && errors.user_data?.first_name
              )}
              helperText={
                touched.user_data?.first_name && errors.user_data?.first_name
              }
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("user_data.last_name")}
              error={Boolean(
                touched.user_data?.last_name && errors.user_data?.last_name
              )}
              helperText={
                touched.user_data?.last_name && errors.user_data?.last_name
              }
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("user_data.email")}
            error={Boolean(touched.user_data?.email && errors.user_data?.email)}
            helperText={touched.user_data?.email && errors.user_data?.email}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("user_data.password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.user_data?.password && errors.user_data?.password
            )}
            helperText={
              touched.user_data?.password && errors.user_data?.password
            }
          />

          {values?.user_data?.password?.length > 0 ? (
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              {...getFieldProps("user_data.confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(
                touched.user_data?.confirmPassword &&
                  errors.user_data?.confirmPassword
              )}
              helperText={
                touched.user_data?.confirmPassword &&
                errors.user_data?.confirmPassword
              }
            />
          ) : null}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isSubmitting}
            endIcon={<ArrowForwardIcon />}
          >
            Sign Up
          </LoadingButton>

          <Box display="flex" sx={{ mt: 3, justifyContent: "center" }}>
            <Checkbox
              color="default"
              {...getFieldProps("privacyCheck")}
              defaultChecked
              required
              error={Boolean(touched.privacyCheck && errors.privacyCheck)}
              helperText={touched.privacyCheck && errors.privacyCheck}
            />
            <Box sx={{ alignSelf: "center" }}>
              <Typography
                variant="body2"
                align="center"
                sx={{ color: "text.secondary" }}
              >
                I agree to {name}&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Privacy Policy
                </Link>
                .
              </Typography>
              <Typography color="red" variant="caption">
                {touched.privacyCheck && errors.privacyCheck}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
