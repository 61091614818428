import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/users/";

const slice = createSlice({
  name: "myprofile",
  initialState: {
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetRequest(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
  },
});

export const { resetRequest, requestStarted, requestedFailed, stateLoaded } =
  slice.actions;
export default slice.reducer;

export const resetRequestState = () => {
  return { type: resetRequest.type };
};

export const changeEmail = (data) =>
  apiCallBegan({
    url: url + "email",
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const changePassword = (data) =>
  apiCallBegan({
    url: url + "password",
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const updateProfile = (data) =>
  apiCallBegan({
    url: url + "profile",
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });
