export default function TextField(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: theme.palette.background.input,
          "&:hover fieldset": {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          "&:hover": {
            borderColor: `${theme.palette.primary.main} !important`,
          },
        },
      },
    },
  };
}
