import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Stack, Drawer, Typography } from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
import useBrand from "hooks/useBrand";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";

// components
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import Footer from "./SidebarFooter";
// redux
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
  transition: "width 0.4s",
}));

// ----------------------------------------------------------------------

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { currentCompany } = useAuth();
  const brand = useBrand();

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        backgroundColor: brand?.colors?.sidebarBackground,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={2}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          textAlign={"center"}
          sx={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isCollapse ? (
            <img
              src={brand?.images?.icon_svg}
              alt="logo"
              width={44}
              sx={{
                transition: "all 0.4s ease",
              }}
              height={44}
            />
          ) : (
            <img
              src={brand?.images?.logo_white}
              alt="logo"
              sx={{
                transition: "all 0.4s ease",
              }}
              height={44}
            />
          )}
        </Box>

        {!isCollapse && brand?.options?.sidenavHeading === "true" && (
          <Box textAlign="center" sx={{ color: brand?.colors?.sidebarText }}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 2, transition: "all 0.4s ease-in-out" }}
            >
              {currentCompany?.company_name}
            </Typography>
          </Box>
        )}
      </Stack>
      <NavSection
        sx={{ mt: brand?.options?.sidenavHeading === "false" ? 7 : 0 }}
        navConfig={sidebarConfig()}
        isShow={!isCollapse}
      />
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && brand?.options?.show_sidebar_footer && <Footer />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "sidebar.background",
              transition: "width 0.4s",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "sidebar.background",
              transition: "width 0.4s",
              borderRight: 0,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: "sidebar.background",
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
