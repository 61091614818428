/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Container, Button, Box } from "@mui/material";
import Page from "components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import RequestsList from "../Dashboard/RequestsList";
//import Pagination from "@mui/material/Pagination";
//import Stack from "@mui/material/Stack";

import LoadingScreen from "components/LoadingScreen";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "hooks/useConfirm";
import Search from "../components/Search";
import {
  getRequestsList,
  closeRequest,
  refreshRequestsList,
} from "redux/slices/requests";
import { useSnackbar } from "notistack";
import { HourglassBottomRounded } from "@mui/icons-material";
import { CheckCircle } from "@mui/icons-material";

const RequestsListDashboard = () => {
  const [title, setTitle] = useState("Requests");
  const [pageTitle, setPageTitle] = useState("Requests");
  const { list } = useSelector((state) => state.requests);
  const [requests, setRequests] = useState(null);
  const currentURL = window.location.href;
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [shouldClear, setClear] = useState(true);

  let [Icon, setIcon] = useState(CheckCircle);
  let navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      setRequests(null);
      await dispatch(getRequestsList());
      setLoading(false);
    }
    loadData();
    setClear(!shouldClear);
  }, [currentURL]);

  const handleRefresh = async () => {
    setLoading(true);
    await dispatch(getRequestsList());
    // await dispatch(getRequestsStats());
    setLoading(false);
  };

  useEffect(() => {
    setData();
  }, [list]);

  const setData = () => {
    // detect page
    if (currentURL.includes("pending")) {
      setTitle("Pending Inspections");
      setPageTitle("Pending Roof Inspections");
      let tempList = list.filter((item) => item.status !== "closed");
      setRequests(tempList);
      setIcon(HourglassBottomRounded);
    } else if (currentURL.includes("completed")) {
      setTitle("Completed Inspections");
      setPageTitle("Completed Roof Inspections");
      let tempList = list.filter((item) => item.status === "closed");
      setRequests(tempList);
      setIcon(CheckCircle);
    }
  };

  const handleCloseRequest = (item) => {
    const requestId = item.id;
    confirm({
      title: "Are you sure you want to mark this inspection as complete?",
      // description: "(This will close the request on our end)",
      descriptionbelow: "(This will close the request on our end)",
      confirmationText: "Yes, It's done",
      cancellationText: "Cancel",
      ctaRight: true,
    })
      .then(async (res) => {
        setRequests(null);
        await dispatch(closeRequest(requestId));
        enqueueSnackbar("Inspection Request Closed", {
          variant: "success",
        });
        await dispatch(refreshRequestsList());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.info("here");
    setfilteredList(requests);
  }, [requests]);

  const [filteredList, setfilteredList] = useState([]);

  const handleFilterUpdate = (result) => {
    setfilteredList(result);
  };

  return (
    <Page title={pageTitle}>
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <HeaderBreadcrumbs
          heading={title}
          countLabelSettings={{
            text: requests ? requests.length : 0,
            background: title?.includes("Pending") ? "#FFF6CF" : "#D3F4E3",
            color: title?.includes("Pending") ? "#EFC618" : "#14B263",
          }}
          headingIcon={
            <Icon
              fontSize="medium"
              style={{ margin: "8px 9px" }}
              sx={{
                color: "#0A36A3",
                background: "#F5FBFF",
              }}
            />
          }
          action={
            <Button
              sx={{
                backgroundColor: "#ffffff",
                display: { xs: "none", sm: "none", md: "inline" },
              }}
              variant="contained"
              style={{ backgroundColor: "#14B263" }}
              startIcon={
                <AddCircleOutlineIcon style={{ marginBottom: "-5px" }} />
              }
              onClick={() => navigate("/new-request")}
            >
              REQUEST NEW INSPECTION
            </Button>
          }
        />
        <Card
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            minHeight: "700px",
          }}
        >
          <HeaderBreadcrumbs sx={{ ml: 4, mt: 0 }} heading={title} />
          <Search
            shouldClear={shouldClear}
            onUpdate={handleFilterUpdate}
            list={requests}
            onRefresh={handleRefresh}
          ></Search>
          {requests && !loading ? (
            <RequestsList
              list={filteredList}
              handleCloseRequest={handleCloseRequest}
            />
          ) : (
            <Box sx={{ display: "block", marginTop: "20% !important" }}>
              <LoadingScreen />
            </Box>
          )}
        </Card>
        {/* <Card>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 3,
            }}
          >
            <Pagination
              count={5}
              defaultPage={5}
              hidePrevButton
              list={requests}
              onChange={(e) => setfilteredList(e.target.requests)}
            />
          </Stack>
        </Card> */}
      </Container>
    </Page>
  );
};
export default RequestsListDashboard;
