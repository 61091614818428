import { Box, Paper } from "@mui/material";
import NoDataFoundImage from "assets/illustrations/no-data-found.png";

export default function NotDataFound({ ...other }) {
  return (
    <Paper {...other}>
      <Box
        justifyContent="center"
        sx={{ minWidth: "260px" }}
        m={other?.margin || 5}
      >
        <img
          src={NoDataFoundImage}
          alt="No Data"
          width={other?.width || 300}
          height={other?.height || 200}
          style={{ display: "block", margin: "auto" }}
        />
      </Box>
    </Paper>
  );
}
