import React from "react";
import { Typography, List, ListItem, ListItemAvatar } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function list(props) {
  return (
    <List sx={{ width: "100%", maxwidth: 360, p: 1.3 }}>
      <ListItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 0,
          "&:hover": {
            bgcolor: "#fff",
          },
        }}
      >
        <Typography variant="h6" sx={{ pl: 1, color: "#0A36A3" }}>
          {props.name}
        </Typography>
        <ListItemAvatar>
          <FileDownloadIcon
            sx={{
              fill: "#0A36A3",
              bgcolor: "#E3EDFF",
              borderRadius: 5,
              p: 1,
              width: 40,
              height: 40,

              "&:hover": {
                fill: "#E3EDFF",
                bgcolor: "#0A36A3",
              },
            }}
          />
        </ListItemAvatar>
      </ListItem>
    </List>
  );
}

export default list;
