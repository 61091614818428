import React from "react";
import styled, { css } from "styled-components/macro";
import { rgba } from "polished";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLowercaseRestAddress,
  formatPhone,
  capitalizeFirstWholeSentence,
} from "../../../utils/formatStrings";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Box,
  Button,
} from "@mui/material";
import moment from "moment";
import { requestLoaded } from "redux/slices/requests";
import { useDispatch } from "react-redux";
import getUserData from "utils/getUserData";
import RequestStatus from "./RequestStatus";
import { useMediaQuery } from "@mui/material";

const Card = styled(MuiCard)`
  position: relative;
  cursor: pointer;
  border-style: solid;
  border-color: #f9f9f9;
  border-width: 1px;
  margin-bottom: 8px;
  boxshadow: "0 0 2px 0 rgb(145 158 171 / 12%), 0 16px 32px -4px rgb(145 158 171 / 12%)";
  border-width: 1px;
  background-color: #f9f9f9;

  &:hover {
    border-style: solid;
    border-color: #365ab3;
    border-width: 1px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    border-color: rgba(54, 90, 179, 0.3);
    background-color: #fff;
    box-shadow: rgba(54, 90, 179, 0.3) 0px 1px 2px 0px,
      rgba(54, 90, 179, 0.15) 0px 2px 6px 2px !important;
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: "#e1e0e357";

  &:last-child {
    padding-bottom: 6px;
    padding-top: 12px;
  }
`;

const RequestItem = ({ item, onClose }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserData(item?.customFields);
  const mobileView = useMediaQuery("(max-width: 960px)");
  const handleSelected = (item) => {
    dispatch(requestLoaded(item));
    navigate("/details/" + item.id);
  };

  return (
    <Card sx={{ mt: { xs: 1.5, md: 0 } }}>
      <CardContent
        sx={{
          ".address-text": {
            color: "#0A36A3",
          },
          "&:hover": {
            xs: { background: "#0A36A3", color: "#FFF" },
            md: { background: "inherit", color: "inherit" },
          },
          "&:hover .address-text": {
            xs: { color: "#FFF" },
            md: { color: "#0A36A3" },
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid
            onClick={() => handleSelected(item)}
            item
            sx={{
              display: { md: "none", xs: "block" },
            }}
            xs={12}
            sm={12}
            md={1}
            lg={1}
          >
            <Box display="flex" mt={1.5} justifyContent={"space-between"}>
              <div>
                {/* #{item.number} */}
                {moment(item.createdAt).format("MM.DD.YYYY")}
              </div>
              <ArrowRightAltOutlinedIcon
                fontSize="large"
                sx={{ color: "#B3B3B3", transform: "scale(1.3)" }}
              />
            </Box>
          </Grid>
          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
          >
            <Box
              sx={{
                fontWeight: { xs: "bold", md: "normal" },
              }}
            >
              {capitalizeFirstWholeSentence(user.name)}
            </Box>
          </Grid>

          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            mt={mobileView ? 0.5 : 0}
            className="address-text"
          >
            {capitalizeFirstLowercaseRestAddress(user?.address)}
          </Grid>

          <Grid
            item
            onClick={() => handleSelected(item)}
            sx={{
              display: { sm: "none", xs: "none", md: "block" },
            }}
            md={2}
            lg={2}
          >
            {formatPhone(user?.phone)}
          </Grid>

          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={2}
            mb={mobileView ? 1.5 : 0}
            mt={mobileView ? 1 : 0}
            lg={2}
          >
            <Box
              display="flex"
              justifyContent={mobileView ? "flex-end" : "flex-start"}
            >
              <RequestStatus status={item.status} />
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              display: { sm: "none", xs: "none", md: "block" },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                marginRight: "5px",
                width: item.status !== "closed" ? "auto" : "140px",
                height: "30px",
                "&:hover": { backgroundColor: "#0A36A3", color: "white" },
              }}
              onClick={() => handleSelected(item)}
            >
              View
            </Button>
            {item.status !== "closed" && (
              <Button
                variant="outlined"
                disabled={item.status === "closed"}
                color="error"
                style={{ height: "30px" }}
                onClick={() => onClose(item)}
                sx={{
                  "&:hover": { backgroundColor: "#ED2B2B", color: "white" },
                }}
              >
                Close
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestItem;
