export default function Menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.lighter,
            "&:hover": {
              backgroundColor: `${theme.palette.primary.lighter} !important`,
            },
          },
        },
      },
    },
  };
}
