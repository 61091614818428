import { useState } from "react";
import { Icon } from "@iconify/react";
import MenuItem from "@mui/material/MenuItem";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { onKeyDown } from "../../../../utils/commonMethods";
// ----------------------------------------------------------------------

export default function RegisterForm({ formik }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [realtor, setRealtor] = useState("Yes");

  const {
    errors,
    touched,
    values,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const Paste = (event) => {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  const handleChange = (event) => {
    setRealtor(event.target.value);
  };
  const realtors = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  //const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-z]+$/;
  return (
    <Stack spacing={3}>
      {errors.afterSubmit && (
        <Alert severity="error">{errors.afterSubmit}</Alert>
      )}
      <Stack>
        <TextField
          id="outlined-select"
          select
          label="Are You a Realtor"
          value={realtor}
          placeholder="Yes"
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "black",
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                color: "",
              },
            },
          }}
        >
          {realtors.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          onKeyDown={(e) => onKeyDown(e, values.user.first_name)}
          onPaste={Paste}
          onBlur={(e) =>
            setFieldValue("user.first_name", values.user.first_name.trim())
          }
          fullWidth
          size="medium"
          placeholder="First Name"
          label="First Name *"
          {...getFieldProps("user.first_name")}
          error={Boolean(touched.user?.first_name && errors.user?.first_name)}
          helperText={touched.user?.first_name && errors.user?.first_name}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "black",
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                color: "",
              },
            },
          }}
        />

        <TextField
          fullWidth
          label="Last Name *"
          size="medium"
          placeholder="Last Name"
          onPaste={Paste}
          onKeyDown={(e) => onKeyDown(e, values.user.last_name)}
          onBlur={(e) =>
            setFieldValue("user.last_name", values.user.last_name.trim())
          }
          {...getFieldProps("user.last_name")}
          error={Boolean(touched.user?.last_name && errors.user?.last_name)}
          helperText={touched.user?.last_name && errors.user?.last_name}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "black",
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                color: "",
              },
            },
          }}
        />
      </Stack>

      <TextField
        fullWidth
        autoComplete="username"
        type="email"
        size="medium"
        label="Email Address *"
        placeholder="Email Address"
        {...getFieldProps("user.email")}
        error={Boolean(touched.user?.email && errors.user?.email)}
        helperText={touched.user?.email && errors.user?.email}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        InputLabelProps={{
          sx: {
            // set the color of the label when not shrinked
            color: "black",
            [`&.${inputLabelClasses.shrink}`]: {
              // set the color of the label when shrinked (usually when the TextField is focused)
              color: "",
            },
          },
        }}
      />

      <TextField
        fullWidth
        autoComplete="current-password"
        type={showPassword ? "text" : "password"}
        label="Password *"
        placeholder="Password"
        size="medium"
        {...getFieldProps("user.password")}
        InputProps={{
          startAdornment: <InputAdornment></InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          sx: {
            // set the color of the label when not shrinked
            color: "black",
            [`&.${inputLabelClasses.shrink}`]: {
              // set the color of the label when shrinked (usually when the TextField is focused)
              color: "",
            },
          },
        }}
        error={Boolean(touched.user?.password && errors.user?.password)}
        helperText={touched.user?.password && errors.user?.password}
      />

      {values?.user?.password?.length > 0 ? (
        <TextField
          fullWidth
          autoComplete="current-password"
          type={showConfirmPassword ? "text" : "password"}
          label="Confirm Password"
          size="medium"
          placeholder="Confirm Password"
          {...getFieldProps("user.confirmPassword")}
          InputProps={{
            startAdornment: <InputAdornment></InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: "black",
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                color: "",
              },
            },
          }}
          error={Boolean(
            touched.user?.confirmPassword && errors.user?.confirmPassword
          )}
          helperText={
            touched.user?.confirmPassword && errors.user?.confirmPassword
          }
        />
      ) : null}

      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        color="secondary"
        type="submit"
        loading={isSubmitting}
        disabled={!values?.privacycheck}
        endIcon={<ArrowForwardIcon />}
        // onClick={handleNextStep}
      >
        Sign Up
      </LoadingButton>
    </Stack>
  );
}
