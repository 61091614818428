import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// path
import { PATH_AUTH, PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

const AllowedRoutesWithoutValidate = [
  PATH_AUTH.validateCompany,
  PATH_DASHBOARD.profile.installation,
  PATH_DASHBOARD.profile.root,
  PATH_DASHBOARD.profile.editprofile,
];

export default function AuthGuard({ children }) {
  const { isAuthenticated, currentCompany } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  // useEffect(() => {
  //   console.log(currentCompany, "status");
  // }, [currentCompany, pathname]);

  // if the user is not Authenticated Redirect him to the login page
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="/login" />;
  }
  //if the user is Authenticated and the billing is disabled in the brand allow the components
  // if (
  //   isAuthenticated &&
  //   !options.bool_billing_enabled &&
  //   currentCompany &&
  //   currentCompany?.status
  // )
  //   return <>{children}</>;

  //if the user is Authenticated but his account not activated only allow the routes on the list
  if (
    isAuthenticated &&
    (!currentCompany?.validation_date || !currentCompany?.status) &&
    AllowedRoutesWithoutValidate.includes(pathname)
  )
    return <>{children}</>;

  //if the user is Authenticated but his account not activated and trying to access other pages - redirect to payment page
  if (
    isAuthenticated &&
    (!currentCompany?.validation_date || !currentCompany?.status) &&
    pathname !== PATH_DASHBOARD.profile.billing.paymentmethod
  ) {
    // days left till subscription end
    let hasDaysLeft = false;
    if (currentCompany.subscriptionValidUntil) {
      const today = new Date().toISOString();
      hasDaysLeft = currentCompany.subscriptionValidUntil > today;
    }
    return hasDaysLeft ? (
      <>{children}</>
    ) : (
      <Navigate to={PATH_DASHBOARD.profile.billing.paymentmethod} />
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
