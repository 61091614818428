import React from "react";
import styled from "styled-components/macro";

import { Box, Grid, Typography, Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

import useBrand from "hooks/useBrand";

const Footer = styled.div`
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
`;

// const FooterText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
// `;

// const FooterSubText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
//   font-size: 0.7rem;
//   display: block;
//   padding: 1px;
// `;

// const FooterBadge = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)};
//   span {
//     background-color: ${(props) =>
//       props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

const SidebarFooter = ({ ...rest }) => {
  const brand = useBrand();

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box textAlign="center">
            <Typography variant="h6" color="#666666">
              Contact
            </Typography>
            <Link href={`tel:${brand?.content?.roofer_phone_number}`}>
              <img alt="rrd" src={brand?.images?.rrd_logo_colored_full} />
            </Link>
            <Link href={`tel:${brand?.content?.roofer_phone_number}`}>
              <Typography
                variant="h6"
                color="#666666"
                sx={{ display: "flex", justifyContent: "center", mt: 1 }}
              >
                <PhoneIcon sx={{ alignSelf: "center" }} />
                {brand?.content?.roofer_phone_number}
              </Typography>
            </Link>
          </Box>
          {/* <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
            
            {!user && (
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            )}
          </FooterBadge> */}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
