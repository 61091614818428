const ConversationsData = [
  {
    name: "xyz",
    message: "My name is Xyz ",
  },
  {
    name: "xyz",
    message: "My name is Xyz ",
  },
  {
    name: "xyz",
    message: "My name is Xyz ",
  },
];
export default ConversationsData;
