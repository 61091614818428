import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";

const url = "/api/billing";

const slice = createSlice({
  name: "billing",
  initialState: {
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    hireUsRequested: false,
    billingPlans: [],
    invoices: [],
    listOffset: [],
    invoiceDetails: null,
  },
  reducers: {
    resetRequest(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    hireUsRequest(state, action) {
      console.log("action.payload: ", action.payload);
      state.hireUsRequested = action.payload.hireUsRequested;
    },
    stateLoaded(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isRequestSent = null;
    },
    billingPlans(state, action) {
      state.isLoading = false;
      state.billingPlans = action.payload;
    },
    allInvoices(state, action) {
      state.isLoading = false;
      state.invoices = action.payload.list;
      state.listOffset = action.payload.listOffset;
    },
    invoiceDetails(state, action) {
      state.isLoading = false;
      state.invoiceDetails = action.payload;
    },
    planPayed(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isRequestSent = null;
      state.planPayed = true;
    },
  },
});

export const {
  resetRequest,
  requestStarted,
  requestedFailed,
  hireUsRequest,
  stateLoaded,
  billingPlans,
  allInvoices,
  invoiceDetails,
  planPayed,
} = slice.actions;
export default slice.reducer;

export const resetRequestState = () => {
  return { type: resetRequest.type };
};

export const hireUsRequestState = (payload) => {
  return { type: hireUsRequest.type, payload };
};

export const getBillingPlans = () =>
  apiCallBegan({
    url: url + "/plans",
    method: "get",
    onStart: requestStarted.type,
    onSuccess: billingPlans.type,
    onError: requestedFailed.type,
  });

export const payForPlans = (data) =>
  apiCallBegan({
    url: url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: planPayed.type,
    onError: requestedFailed.type,
  });

export const updatePaymentMethod = (data) =>
  apiCallBegan({
    url: url + "/payment-method",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const updateSubscription = (data) =>
  apiCallBegan({
    url: url + "/subscription",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const updateSubscriptionCharge = (data) =>
  apiCallBegan({
    url: url + "/subscription/charge",
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const getInvoices = () =>
  apiCallBegan({
    url: url + "/invoices",
    onStart: requestStarted.type,
    onSuccess: allInvoices.type,
    onError: requestedFailed.type,
  });

export const getInvoice = (invoiceId) =>
  apiCallBegan({
    url: url + `/invoices/${invoiceId}/pdf`,
    // headers: {
    //   responseType: "blob",
    // },
    // onStart: requestStarted.type,
    onSuccess: invoiceDetails.type,
    onError: requestedFailed.type,
  });

export const cancelSubscription = (data) =>
  apiCallBegan({
    url: url + `/subscription/cancel`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const activateCompanySubscription = (data) =>
  apiCallBegan({
    url: url + `/subscription/reactivate-status`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });

export const applyCouponCode = (data) =>
  apiCallBegan({
    url: url + `/apply-coupon`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: stateLoaded.type,
    onError: requestedFailed.type,
  });
