import React from "react";
import Theme1InviteForm from "components/auth/theme/theme1/Invite";
import Theme2InviteForm from "components/auth/theme/theme2/InviteRealtor";

// redux
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function InviteForm({ formik }) {
  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  switch (options?.login_theme) {
    case "1":
      return <Theme1InviteForm formik={formik} />;
    case "2":
      return <Theme2InviteForm formik={formik} />;
    default:
      return <Theme1InviteForm formik={formik} />;
  }
}
