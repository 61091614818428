import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreRoundedIcon,
      },
      styleOverrides: {
        root: {
          "&:hover fieldset": {
            borderColor: "#6321ee !important",
          },
        },
      },
    },
  };
}
