import async from "components/Async";
import { Navigate } from "react-router-dom";
import NewRequest from "../../pages/dashboards/NewRequest";
// Guards
const LeadManager = async(() => import("../../pages/dashboards/LeadManager"));
const ApprovedLeads = async(() =>
  import("../../pages/dashboards/ApprovedLeads")
);
const BlockedLeads = async(() => import("../../pages/dashboards/BlockedLeads"));
const Forms = async(() => import("../../pages/dashboards/Forms"));
const Filters = async(() => import("../../pages/dashboards/Filters"));

const leadManagerRoutes = [
  {
    path: "",
    element: <Navigate to="/lead-manager" replace />,
  },
  {
    path: "/lead-manager",
    element: <LeadManager />,
  },
  {
    path: "forms",
    element: <Forms />,
  },
  {
    path: "approved",
    element: <ApprovedLeads />,
  },
  {
    path: "newRequest",
    element: <NewRequest />,
  },
  {
    path: "blocked",
    element: <BlockedLeads />,
  },
  {
    path: "filters",
    element: <Filters />,
  },
];

export default leadManagerRoutes;
