const AttachmentsData = [
  {
    name: "file",
  },
  {
    name: "file2",
  },
  {
    name: "xyz",
  },
];
export default AttachmentsData;
