// material
import { styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
// layouts
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// components
import Page from "components/Page";
import HireUs from "components/_dashboard/user/installation/HireUs";
import CustomizedSteppers from "components/CustomizedSteppers";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function step3WeSetup({ handlePreviousStep, skipForNow }) {
  return (
    <RootStyle title="Step 3">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 580, mx: "auto" }} textAlign="center">
          <Box sx={{ pb: { xs: 12, md: 16 }, mt: { md: 12, xs: 0 } }}>
            <CustomizedSteppers
              steps={Array(4).join(".").split(".")}
              activeStep={3}
            />
          </Box>
          <Typography variant="h3" color="primary" paragraph>
            Step 4
          </Typography>

          <HireUs
            handlePreviousStep={handlePreviousStep}
            skipForNow={skipForNow}
          />
        </Box>
      </Container>
    </RootStyle>
  );
}
