import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import hasIntegration from "utils/hasIntegration";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_DASHBOARD, PATH_AUTH } from "../routes/paths";

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const { isAuthenticated, currentCompany } = useAuth();
  const location = useLocation();
  const {
    brand: { options },
  } = useSelector((state) => state.brand);
  if (
    isAuthenticated &&
    location.pathname.includes("signup") &&
    !currentCompany?.validation_date
  )
    return <Navigate to={PATH_AUTH.validateCompany} />;

  if (
    isAuthenticated &&
    (!currentCompany?.validation_date || !currentCompany?.status) &&
    options.bool_billing_enabled
  )
    return <Navigate to={PATH_DASHBOARD.profile.billing.paymentmethod} />;

  if (isAuthenticated && currentCompany) {
    if (hasIntegration("website-leads", currentCompany))
      return <Navigate to={PATH_DASHBOARD.leadmanager} />;

    if (hasIntegration("roof-connect", currentCompany))
      return <Navigate to={PATH_DASHBOARD.roofconnect} />;

    return <Navigate to="/login" />;
  }
  if (isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
