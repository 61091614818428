/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../../../routes/paths";

// layouts
// import AuthLayout from "../../../../layouts/Auth";
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
import InviteForm from "components/auth/theme/theme2/InviteForm";
import { useDispatch, useSelector } from "redux/store";
import { resetAll } from "redux/slices/company";
import { useMediaQuery } from "@mui/material";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
  backgroundColor: "#0A36A3",
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 580,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",

  // padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function Invite() {
  const dispatch = useDispatch();
  let [errorText, setErrorText] = useState("");
  let { error } = useSelector((state) => state.company);
  const {
    brand: { images },
  } = useSelector((state) => state.brand);

  useEffect(() => {
    if (error && typeof error === "string") {
      setErrorText(error);
    }
    dispatch(resetAll());
  }, [error]);
  const mobileView = useMediaQuery("(max-width:425px)");
  const windowView = useMediaQuery("(min-width:426px)");
  return (
    <>
      {mobileView && (
        <RootStyle title="Invite" sx={{ backgroundColor: "#F5FBFF" }}>
          {/* <AuthLayout logoHide={true}>
     Don&#39;t have an account? &nbsp;
     <Link
       underline="none"
       variant="subtitle2"
       component={RouterLink}
       to={PATH_AUTH.signup}
     >
       Get started
     </Link>
   </AuthLayout> */}
          {/* */}
          <Container maxWidth="md">
            <ContentStyle sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 5,
                  pb: 2,
                  mt: 2,
                }}
              >
                <img src={images?.logo_white} alt="logo" height="50"></img>
              </Box>
              <Box
                sx={{
                  px: 3,
                  boxShadow: 15,
                  pt: 1,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                {errorText ? (
                  <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Typography variant="h4" color="red" gutterBottom>
                        {errorText}
                      </Typography>
                      <Button
                        size="large"
                        variant="contained"
                        component={RouterLink}
                        to={PATH_AUTH.signup}
                        sx={{ mt: 5 }}
                      >
                        Sign Up
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <>
                    <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                      <Box sx={{ flexGrow: 1 }} textAlign="center">
                        <Typography
                          variant="h4"
                          sx={{ color: "#0A36A3", mt: 2 }}
                        >
                          Realtors Only
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <img
                            alt="yellowline"
                            // src={images.illustration_signup}
                            src="/static/img/illustrations/Yellowline.svg"
                            height="10%"
                            // style={{ objectFit: "cover" }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ fontSize: "1.2rem !important", pt: 1 }}
                        >
                          Let's Get Started
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: ".86rem",
                            lineHeight: "1rem",
                            color: "#161A20",
                            opacity: 0.7,
                          }}
                        >
                          Avoid delays in closing and have confidence, knowing
                          roof issues will never hold your back.
                        </Typography>
                      </Box>
                    </Box>

                    <InviteForm />

                    <MHidden width="smUp">
                      <Typography
                        variant="subtitle2"
                        sx={{ mt: 2, textAlign: "center", pb: 1 }}
                      >
                        Already have an account?&nbsp;
                        <Link to={PATH_AUTH.login} component={RouterLink}>
                          Sign In
                        </Link>
                      </Typography>
                    </MHidden>
                  </>
                )}
              </Box>{" "}
            </ContentStyle>
          </Container>{" "}
          <MHidden width="mdDown">
            <SectionStyle>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  px: 5,
                }}
              >
                Speed Up Your Closings — Guaranteed.
              </Typography>
              <Box sx={{ pl: 8, pt: 3, pr: 6 }}>
                <img
                  alt="register"
                  src={images.illustration_signup}
                  height="100%"
                />
              </Box>
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
      {windowView && (
        <RootStyle title="Invite" sx={{ backgroundColor: "#F5FBFF" }}>
          {/* <AuthLayout logoHide={true}>
        Don&#39;t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.signup}
        >
          Get started
        </Link>
      </AuthLayout> */}
          {/* */}
          <Container maxWidth="md">
            <ContentStyle sx={{ px: 0, pt: 5, pb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                }}
              >
                <img src={images?.logo_white} alt="logo" height="70"></img>
              </Box>
              <Box
                sx={{
                  px: 6,
                  boxShadow: 15,
                  py: 6,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                {errorText ? (
                  <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Typography variant="h4" color="red" gutterBottom>
                        {errorText}
                      </Typography>
                      <Button
                        size="large"
                        variant="contained"
                        component={RouterLink}
                        to={PATH_AUTH.signup}
                        sx={{ mt: 5 }}
                      >
                        Sign Up
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <>
                    <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                      <Box sx={{ flexGrow: 1 }} textAlign="center">
                        <Typography
                          variant="h4"
                          sx={{ color: "#0A36A3", mt: -4 }}
                        >
                          Realtors Only
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <img
                            alt="yellowline"
                            // src={images.illustration_signup}
                            src="/static/img/illustrations/Yellowline.svg"
                            height="10%"
                            // style={{ objectFit: "cover" }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ fontSize: "1.2rem !important", pt: 1 }}
                        >
                          Let's Get Started
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            px: 9,
                            fontSize: ".86rem",
                            lineHeight: "1rem",
                            color: "#161A20",
                            opacity: 0.7,
                          }}
                        >
                          Avoid delays in closing and have confidence, knowing
                          roof issues will never hold your back.
                        </Typography>
                      </Box>
                    </Box>

                    <InviteForm />

                    <MHidden width="smUp">
                      <Typography
                        variant="subtitle2"
                        sx={{ mt: 3, textAlign: "center" }}
                      >
                        Already have an account?&nbsp;
                        <Link to={PATH_AUTH.login} component={RouterLink}>
                          Sign In
                        </Link>
                      </Typography>
                    </MHidden>
                  </>
                )}
              </Box>{" "}
            </ContentStyle>
          </Container>{" "}
          <MHidden width="mdDown">
            <SectionStyle>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  px: 5,
                }}
              >
                Speed Up Your Closings — Guaranteed.
              </Typography>
              <Box sx={{ pl: 8, pt: 3, pr: 6 }}>
                <img
                  alt="register"
                  src={images.illustration_signup}
                  height="100%"
                />
              </Box>
            </SectionStyle>
          </MHidden>
        </RootStyle>
      )}
    </>
  );
}
