import React from "react";
import Theme1Login from "components/auth/theme/theme1/Login";
import Theme2Login from "components/auth/theme/theme2/Login";

// redux
import { useSelector } from "redux/store";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Login() {
  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  switch (options?.login_theme) {
    case "1":
      return <Theme1Login />;
    case "2":
      return <Theme2Login />;
    default:
      return <Theme1Login />;
  }
}
