import React from "react";
import SignInComponent from "components/auth/SignIn";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Stack, Link, Container, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "routes/paths";

// layouts
import AuthLayout from "layouts/Auth";
// components
import Page from "components/Page";
import logoimage from "assets/images/SpamBurner Black.svg";
import { MHidden } from "components/@material-extend";
// redux
import { useSelector } from "redux/store";
import HighlightBox from "./highlightBox";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
  backgroundColor: "#6320EE",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 520,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const {
    brand: { name = "", images = {} },
  } = useSelector((state) => state.brand);
  return (
    <RootStyle title="Login">
      <AuthLayout logoHide={true}>
        Don&apos;t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.signup}
        >
          Get started
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Box
            sx={{
              padding: "40px",
              borderRadius: "7px",
              boxShadow: 3,
            }}
          >
            <Box
              sx={{
                display: { xs: "flex" },
                alignItems: "center",
                mb: 2,
                justifyContent: "center",
              }}
            >
              {/* <img src={images?.logo_white} alt="logo" height="40" /> */}
              <img src={logoimage} alt="logo" height="40" />
            </Box>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }} textAlign="center">
                <Typography variant="h4" gutterBottom>
                  Sign in to {name}
                  <sup>&trade;</sup>
                </Typography>
                <Typography sx={{ color: "secondary" }}>
                  Enter your login details.
                </Typography>
              </Box>
            </Stack>
            <SignInComponent />
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.signup}
                >
                  Get started
                </Link>
              </Typography>
            </MHidden>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
