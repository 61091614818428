import React from "react";
import styled, { css } from "styled-components/macro";
import { rgba } from "polished";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Grid,
  Link,
} from "@mui/material";

import { spacing } from "@mui/system";
import getUserData from "utils/getUserData";
import {
  capitalizeFirstLowercaseRestAddress,
  formatPhone,
  capitalizeFirstWholeSentence,
} from "../../../utils/formatStrings";
const Card = styled(MuiCard)`
  position: relative;
  border-style: solid;
  border-color: #e1e0e357;
  border-width: 1px;
  margin-bottom: 22px;
  boxshadow: "0 0 2px 0 rgb(145 158 171 / 12%), 0 16px 32px -4px rgb(145 158 171 / 12%)";
  border-width: 1px;
  &:hover {
    background-color: "#000";
    border-style: solid;
    border-width: 1px;
  }
  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
  h6.small {
    font-size: 0.95rem !important;
    font-weight: 900;
    text-shadow: 0.25px 0 #000;
    @media (max-width: 768px) {
      font-size: 0.9rem !important;
    }
  }
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: "#e1e0e357";

  &:last-child {
    padding-bottom: 6px;
    padding-top: 12px;
  }
`;

const RequestDetails = ({ item }) => {
  const user = getUserData(item?.customFields);

  return (
    <Card style={{ backgroundColor: "#e1e0e357" }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Contact Info:
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <Typography variant="h6" className="small">
              Contact <span className="hide-xs">Person</span>
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="body2">
              {capitalizeFirstWholeSentence(user.name)}
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography variant="h6" className="small">
              Name
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="body2" sx={{ alignSelf: "end" }}>
              {capitalizeFirstWholeSentence(user.name)}
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography variant="h6" className="small">
              Phone Number
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="body2" sx={{ alignSelf: "end" }}>
              <Link
                target="_blank"
                href={`tel:${user.phone}`}
                sx={{ color: "#0A36A3" }}
              >
                {formatPhone(user.phone)}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="h6" className="small">
              Address
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="body2" sx={{ alignSelf: "end" }}>
              <Link
                target="_blank"
                href={`http://maps.google.com/?q=${user.address}`}
                sx={{ color: "#0A36A3" }}
              >
                {capitalizeFirstLowercaseRestAddress(user.address)}
              </Link>
            </Typography>
          </Grid>

          {/*  */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestDetails;
