export const MESSAGE_TYPES = ["customer", "message"];
export const ALLOWED_FILE_TYPES = [
  "image",
  "pdf",
  "spreadsheet",
  "wordprocessing",
  "doc",
  "txt",
  "text/plain",
  "msword",
];
