/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import RealtorFavicon from "assets/realtors_roof_log.svg";
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";

import "./i18n";
import createTheme from "./theme";
// routes
import Router from "./routes";

// hooks
import useAuth from "./hooks/useAuth";
import useTheme from "./hooks/useTheme";
import { ConfirmProvider } from "hooks/useConfirm";

// import Settings from "./components/Settings";
import GlobalStyles from "./theme/globalStyles";
// redux
import { useSelector, useDispatch } from "redux/store";
import { getBrand } from "redux/slices/brand";

// function getFaviconEl() {
//   return document.getElementById("favicon");
// }

function App() {
  const { isInitialized } = useAuth();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.brand);
  useEffect(() => {
    let source = window.location.href.replace(window.location.pathname, "");
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "192.168.1.70"
    ) {
      // source = "https://my.polkrealtors.com/";
      source = "https://my.spamburner.com/";
    }
    dispatch(getBrand({ source }));
  }, [dispatch]);

  // useEffect(() => {
  //   handleFavicon(brand);
  //   // attach bugherd script
  //   if (brand?.content?.bugherdSrc) {
  //     const bugherdScript = document.createElement("script");
  //     bugherdScript.src = brand.content?.bugherdSrc;
  //     bugherdScript.async = true;
  //     document.body.appendChild(bugherdScript);
  //     return () => {
  //       document.body.removeChild(bugherdScript);
  //     };
  //   }
  // }, [brand]);

  // const handleFavicon = () => {
  //   const favicon = getFaviconEl();
  //   favicon.href = brand?.images?.favicon || RealtorFavicon;
  // };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={createTheme(theme, brand?.options?.brand_theme)}>
        <ThemeProvider theme={createTheme(theme, brand?.options?.brand_theme)}>
          <Helmet
            titleTemplate={`%s | ${brand?.name}`}
            defaultTitle={brand?.name}
            meta={[<meta name="description" content={brand?.description} />]}
          />
          <NotistackProvider>
            <CssBaseline />
            <GlobalStyles />
            <ScrollToTop />
            <ProgressBarStyle />
            <ConfirmProvider>
              {/* <Settings /> */}

              {isInitialized && brand?.name ? <Router /> : <LoadingScreen />}
            </ConfirmProvider>
          </NotistackProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;
