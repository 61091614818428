import React from "react";
import Theme1Signup from "components/auth/theme/theme1/Signup";
import Theme2Signup from "components/auth/theme/theme2/Signup";

// redux
import { useSelector } from "redux/store";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Signup({ formik }) {
  const {
    brand: { options },
  } = useSelector((state) => state.brand);

  switch (options?.login_theme) {
    case "1":
      return <Theme1Signup formik={formik} />;
    case "2":
      return <Theme2Signup formik={formik} />;
    default:
      return <Theme1Signup formik={formik} />;
  }
}
