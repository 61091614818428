import React from "react";
import { Box, Typography } from "@mui/material";
import Data from "./ConversationsData";
import Data1 from "./AttachmentsData";
import List from "components/_dashboard/attachmentlist/List.js";
import Reply from "components/_dashboard/reply/Reply.js";

function NewRequest() {
  const dataComp = Data.map((data) => {
    // return <Conversation name={data.name} message={data.message} />;
    return null;
  });
  const dataComp1 = Data1.map((data) => {
    return <List name={data.name} />;
  });
  return (
    <Box>
      <Box>
        <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>
          Conversation History :
        </Typography>
        <Box sx={{ bgcolor: "#F7F7F9", borderRadius: 1 }}>{dataComp}</Box>
      </Box>

      {/* Attachment List */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>
          Attachment :
        </Typography>
        <Box sx={{ bgcolor: "#F7F7F9", borderRadius: 1 }}>{dataComp1}</Box>
      </Box>

      {/* Reply Box */}
      <Box sx={{ mt: 3, bgcolor: "#0B36A3" }}>
        <Typography variant="h6" sx={{ ml: 7, mb: 2, pt: 3, color: "white" }}>
          Reply :
        </Typography>
        <Box sx={{ ml: 7, pb: 4 }}>
          <Reply />
        </Box>
      </Box>
    </Box>
  );
}

export default NewRequest;
