import axios from "../utils/axios";
import { ALLOWED_FILE_TYPES } from "../constants/Realtor.js";

export function onPasswordBlur(event, setcapsWarning) {
  return setcapsWarning(false);
}

export function onEmailBlur(email, e, setHasExists, setlockSubmit) {
  return new Promise(async (resolve) => {
    if (e?.target?.value || email) {
      const result = await axios.post("/api/users/user-by-email", {
        email: email || e?.target?.value,
      });
      setlockSubmit(false);
      return resolve(setHasExists(result?.data?._id ? true : false));
    }
    setlockSubmit(false);
    return resolve(setHasExists(false));
  });
}

export function onPasswordKeyDown(keyEvent, setcapsWarning) {
  if (keyEvent.getModifierState("CapsLock")) {
    setcapsWarning(true);
  } else {
    setcapsWarning(false);
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64 = reader.result;
      base64 = base64.replace(/^data:image\/[a-z]+;base64,/, "");
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function isFileTypeSupported(type) {
  if (!type) return false;
  return ALLOWED_FILE_TYPES.findIndex((each) => type.includes(each)) > -1;
}

const ALPHA_NUMERIC_DASH_REGEX = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;

export function onKeyDown(event, currentValue) {
  if (
    (currentValue &&
      event.key === " " &&
      currentValue[currentValue.length - 1] === " ") ||
    (!currentValue && event.key === " ")
  ) {
    return event.preventDefault();
  }
  if (event.key !== " " && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
    event.preventDefault();
  }
}
