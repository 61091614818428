import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
import { dispatch } from "redux/store";
import _ from "lodash";

const url = "/api/integrations/leads/black-listed";

const slice = createSlice({
  name: "blacklisted_filters",
  initialState: {
    page: 1,
    per_page: 25,
    total_pages: 0,
    total_records: 0,
    word: [],
    email: [],
    url: [],
    name: [],
    phone: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.page = 1;
      state.per_page = 20;
      state.total_pages = 0;
      state.total_records = 0;
      state.word = [];
      state.email = [];
      state.url = [];
      state.name = [];
      state.phone = [];
    },
    requestStarted(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    stateLoaded(state, action) {
      state.success = action.payload;
      state.isLoading = false;
    },
    getBlacklisted(state, action) {
      state.isLoading = false;
      // state.page = action.payload?.page;
      // state.per_page = action.payload?.per_page;
      // state.total_pages = action.payload?.total_pages;
      // state.total_records = action.payload?.total_records;
      state.word = action.payload?.word;
      state.email = action.payload?.email;
      state.url = action.payload?.url;
      state.name = action.payload?.name;
      state.phone = action.payload?.phone;
    },
    addedFilter(state, action) {
      let data = state[action?.payload?.label] ?? [];
      data = _.union(data, action?.payload?.keywords);
      state[action?.payload?.label] = data;
    },
    deletedFilter(state, action) {
      let data = state[action?.payload?.label] ?? [];
      state[action?.payload?.label] = data.filter(
        (item) => item !== action.payload.keyword
      );
    },
  },
});

export const {
  resetAll,
  requestStarted,
  requestedFailed,
  stateLoaded,
  getBlacklisted,
  addedFilter,
  deletedFilter,
} = slice.actions;
export default slice.reducer;

export const resetAllState = () => {
  return { type: resetAll.type };
};

export const getBlacklistedFilters = (data) =>
  apiCallBegan({
    url: url,
    onStart: requestStarted.type,
    onSuccess: getBlacklisted.type,
    onError: requestedFailed.type,
  });

export function addBlacklistedFilters(data) {
  return async () =>
    dispatch(
      apiCallBegan({
        url: url,
        method: "post",
        data,
        onStart: requestStarted.type,
        onSuccess: stateLoaded.type,
        onError: requestedFailed.type,
      })
    );
}

export function deleteBlacklistedFilters(data) {
  return async () =>
    dispatch(
      apiCallBegan({
        url: url,
        method: "delete",
        data,
        onStart: requestStarted.type,
        onSuccess: stateLoaded.type,
        onError: requestedFailed.type,
      })
    );
}
